import { MONTH_ABBR } from "../constants/constants";

export default function getNewsDate(date) {
    if (!date) {
        return '';
    }
    const dt = new Date(date);
    const month = dt.getUTCMonth();
    const day = dt.getUTCDate();
    const year = dt.getUTCFullYear();
    const monthAbbr = MONTH_ABBR[month];
    const dayN = (day < 10 ? '0' : '') + day;
    return [dayN, ` ${monthAbbr} ${year}`];
}
