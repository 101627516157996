export class CacheBase {
    data = undefined;
    promiseFunc = undefined;

    constructor(promiseFunc = undefined) {
        this.promiseFunc = promiseFunc;
    }

    cache() {
        return new Promise((resolve, reject) => {
            if (this.data !== undefined) {
                resolve(this.data);
                return;
            }

            this.promiseFunc && this.promiseFunc().then(response => {
                this.data = response.data;
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    }

    compoundCache(promiseFunc, key, maxKeysLimit = undefined) {
        return new Promise((resolve, reject) => {
            if (this.data === undefined) {
                this.data = {};
            }
            
            if (maxKeysLimit !== undefined && Object.keys(this.data).length > maxKeysLimit) {
                const firstKey = Object.keys(this.data)[0];
                delete this.data[firstKey];
            }

            if (key !== undefined && this.data[key] !== undefined) {
                resolve(this.data[key]);
                return;
            }

            promiseFunc && promiseFunc().then(response => {
                key !== undefined && (this.data[key] = response.data);
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    }
}