import { useState } from 'react';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Paginator from '../../../../components/Paginator/Paginator';
import './Rankings1Table.css';
import useWindowSize from '../../../../hooks/useWindowSize';
import Icon from '../../../../components/Icon/Icon';
import { DashboardCacheI } from '../../../../caches/DashboardCache';
import thousands from "../../../../hooks/thousands";
import { MED_COLLAPSE_WIDTH, MIN_COLLAPSE_WIDTH } from '../../../../constants/constants';
import thousandsShort from '../../../../hooks/thousandsShort';

export default function Rankings1Table() {
    const [data, setData] = useState(null);
    const [curType, setCurType] = useState(null);
    const [curPage, setCurPage] = useState(0);
    const [curSort, setCurSort] = useState('desc');
    const [width] = useWindowSize();

    const onLoad = (type, page, sort) => {
        DashboardCacheI.getRankings(type, page, sort).then(data => {
            if (data) {
                setData(data);
            }
        });
    };

    const onTypeChange = e => {
        setData(null);
        setCurPage(0);
        onLoad(e.value, 0, curSort);
        setCurType(e);
    };

    const pageChange = p => {
        if (p > -1 && p < data.total && p !== curPage) {
            onLoad(curType.value, p, curSort);
            setCurPage(p);
        }
    }

    const getLastColKey = data => {
        switch (curType.value) {
            case 'pricing':
                return data['accountPrice'] ? '$' + thousands(String(data['accountPrice'])) : 'N/A';
            case 'accountSize':
                const accountSize = data['accountSize'];
                return getAccountSize(accountSize);
            case 'maxScaling':
                const value = data['maxAllocationWithScaling'];
                let sfa = 'N/A';
                if (value === null) {
                    sfa = 'N/A';
                }
                else if (value === 1000000000) {
                    sfa = 'Unlimited';
                }
                else {
                    sfa = '$' + thousands(String(value))
                }
                return sfa;
            case 'maxProfitShare':
                return data['maxProfitSplit'] ? data['maxProfitSplit'] + '%' : 'N/A';
            case 'fastestPayout':
                return data['minTimeToPayout'] === null || data['minTimeToPayout'] === undefined ? 'N/A' : data['minTimeToPayout'] + (data['minTimeToPayout'] === 1 ? ' Day' : ' Days');
            default:
                return 'N/A'
        }
    }

    const getAccountSize = accountSize => {
        return accountSize
            ? ('$' + thousandsShort(accountSize))
            : 'N/A';
    }

    return (
        <div className='ranking-table'>
            <div className='h-font' style={{paddingLeft: width < MED_COLLAPSE_WIDTH && '5%'}}>Ranking</div>
            <table>
                <thead className='h-font'>
                    <tr>
                        <th>#</th>
                        <th>Prop Firm</th>
                        <th>Program</th>
                        <th className='dr-th'>
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <div style={{ width: '100%' }}>
                                    <Dropdown
                                        isSearchable={false}
                                        promise={[
                                            { label: 'Pricing', value: 'pricing' },
                                            { label: 'Account Size', value: 'accountSize' },
                                            { label: 'Max Scaling', value: 'maxScaling' },
                                            { label: 'Max Profit Share', value: 'maxProfitShare' },
                                            { label: 'Fastest Payout', value: 'fastestPayout' },
                                        ]}
                                        onChange={onTypeChange} />
                                </div>
                                {
                                    width > MIN_COLLAPSE_WIDTH && 
                                    <div style={{ 'cursor': 'pointer' }} onClick={() => {
                                        setCurSort(curSort === 'asc' ? 'desc' : 'asc');
                                        onLoad(curType?.value ?? 'pricing', curPage, curSort === 'asc' ? 'desc' : 'asc');
                                    }}>
                                        {
                                            curSort === 'desc' &&
                                            <Icon name={'desc'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: 'white' }} />
                                        }
                                        {
                                            curSort === 'asc' &&
                                            <Icon name={'asc'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: 'white' }} />
                                        }
                                    </div>
                                }

                            </div>

                        </th>
                        {
                            width > MIN_COLLAPSE_WIDTH &&
                            <th>Buy</th>
                        }
                        {
                            width <= MIN_COLLAPSE_WIDTH &&
                            <th>
                                <div style={{ 'cursor': 'pointer' }} onClick={() => {
                                        setCurSort(curSort === 'asc' ? 'desc' : 'asc');
                                        onLoad(curType?.value ?? 'pricing', curPage, curSort === 'asc' ? 'desc' : 'asc');
                                    }}>
                                        {
                                            curSort === 'desc' &&
                                            <Icon name={'desc'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: 'white' }} />
                                        }
                                        {
                                            curSort === 'asc' &&
                                            <Icon name={'asc'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: 'white' }} />
                                        }
                                    </div>
                            </th>
                        }

                    </tr>
                </thead>
                {
                    data?.items &&
                    <tbody>
                        {
                            data.items.map((it, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{borderRadius: data.items.length - 1 === i && '0 0 0 10px'}}>
                                            {
                                                i >= 0 && i < 3 && curPage === 0 &&
                                                <Icon name={'cup'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: i === 0 ? 'gold' : i === 1 ? 'silver' : 'brown' }} />
                                            }
                                            {
                                                i >= 3 && i <= 5 && curPage === 0 &&
                                                <Icon name={'medal'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: i === 3 ? 'gold' : i === 4 ? 'silver' : 'brown' }} />
                                            }
                                            {
                                                curPage > 0 && ((curPage + 1) * 6 - 5 + i)
                                            }

                                        </td>
                                        <td><a href={it.affiliateLink} target="_blank" rel="noreferrer">{it.companyName}</a></td>
                                        <td>{it.programName}
                                            {curType.value === 'pricing' && <br />}
                                            <span className='s-font'>
                                                {(curType.value === 'pricing' ? getAccountSize(it.accountSize) : '')}
                                            </span>
                                        </td>
                                        <td style={{maxWidth:'50px'}}><a href={it.affiliateLink} target="_blank" rel="noreferrer">{getLastColKey(it)}</a></td>
                                        {
                                            <td style={{borderRadius: data.items.length - 1 === i && '0 0 10px 0'}}>
                                                <button className='pfj-btn dark' style={{ padding: '3px 7px', width: 'auto' }}
                                                    onClick={() => it.affiliateLink && window.open(it.affiliateLink)}>
                                                    <Icon name={'cart'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '32px' : '20px', fill: 'white' }} />
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                }
                {
                    !data?.items &&
                    <tbody>
                        {
                            [...Array(6).keys()].map((it) => {
                                return (
                                    <tr key={it}>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                }
            </table>
            <div style={{ 'alignSelf': width >= 900 ? 'flex-end' : 'center' }}>
                {
                    data &&
                    <Paginator
                        totalPages={data.total}
                        pageSetNum={width >= MIN_COLLAPSE_WIDTH ? 5 : 3}
                        pageChange={pageChange} />
                }
            </div>
        </div>
    )
}