const { getCompanies, getPrograms, getExplore, getRankigns, getRankingsScraped, getOurPrograms, getSizes, getProgramsBySize, getPrices, postMatch, getTypes, getDailyLosses, getMaxLosses, getCompetitions, getPhaseTargets, getPlatforms } = require("../requests/dashboard.requests");
const { CacheBase } = require("./CacheBase");

class DashboardCache {
    companies = new CacheBase(() => getCompanies());
    programs = new CacheBase();
    explore = new CacheBase();
    rankings = new CacheBase();
    rankingsScraped = new CacheBase();
    ourPrograms = new CacheBase()
    ourProgramsDetails = new CacheBase();
    sizes = new CacheBase(() => getSizes());
    prices = new CacheBase(() => getPrices());
    types = new CacheBase(() => getTypes());
    dailyLosses = new CacheBase(() => getDailyLosses());
    maxLosses = new CacheBase(() => getMaxLosses());
    phaseTargets = new CacheBase();
    programsBySize = new CacheBase();
    match = new CacheBase();
    competitions = new CacheBase(() => getCompetitions());
    platforms = new CacheBase(() => getPlatforms());

    getCompanies = () => this.companies.cache();
    getPrograms = companyId => this.programs.compoundCache(() => getPrograms(companyId), companyId, 1000);
    getExplore = programId => this.explore.compoundCache(() => getExplore(programId), programId, 20);
    getRankings = (type, page, sort) => this.rankings.compoundCache(() => getRankigns(type, page, sort), `${type}-${page}-${sort}`, 40);
    getRankingsScraped = (page, sort, dir) => this.rankingsScraped.compoundCache(() => getRankingsScraped(page, sort, dir), `${page}-${sort}-${dir}`, 40);
    getOurPrograms = () => this.ourPrograms.compoundCache(() => getOurPrograms(), 'all-1', 5);
    getOurProgramsDetails = (id) => this.ourPrograms.compoundCache(() => getOurPrograms(id), 'details-' + id, 5);
    getSizes = () => this.sizes.cache();
    getPrices = () => this.prices.cache();
    getTypes = () => this.types.cache();
    getDailyLosses = () => this.dailyLosses.cache();
    getMaxLosses = () => this.maxLosses.cache();
    getPhaseTargets = (num) => this.phaseTargets.compoundCache(() => getPhaseTargets(num), num);
    getProgramsBySize = (page, size, sortBy, sortDir, forChart) => this.programsBySize.compoundCache(() => getProgramsBySize(page, size, sortBy, sortDir, forChart), `${page}-${size}-${sortBy ?? 'none'}-${sortDir ?? 'none'}-${forChart ?? false}`, 40);
    postMatch = (body) => this.match.compoundCache(() => postMatch(body), JSON.stringify(body ?? { page: 0 }), 20);
    getCompetitions = () => this.competitions.cache();
    getPlatforms = () => this.platforms.cache();
}

export const DashboardCacheI = new DashboardCache();