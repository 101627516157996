import { byId, getCategories, getMedia, getRandomNews } from "../requests/news.requests";
import { CacheBase } from "./CacheBase";

class NewsCache {
    randomNews = new CacheBase(() => getRandomNews());
    newsById = new CacheBase();
    catrgories = new CacheBase(() => getCategories());
    media = new CacheBase();

    getRandomNews = () => this.randomNews.cache();
    getCategories = () => this.catrgories.cache();
    getMedia = (category, lastDate) => this.media.compoundCache(() => getMedia(lastDate, category), `${lastDate}-${category}`, 50);
    getNewsById = (id) => this.newsById.compoundCache(() => byId(id), id, 10);
}

export const NewsCacheI = new NewsCache();