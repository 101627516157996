import './Dropdown.css';
import Select from 'react-select'
import { useState } from 'react';
import { useEffect } from 'react';

const formatGroupLabel = (data) => (
    <div>
        <div style={{ 'textTransform': 'capitalize', 'color': 'var(--main-dark)', 'fontFamily': 'Articulat Medium', 'fontSize': '14px' }}>{data.label}</div>
        <hr style={{ 'border': '1px solid #bfbfbf', 'marginTop': '5px' }} />
    </div>
);

export default function Dropdown({ promise, placeholder, transform, onChange, onLoaded, isClearable, isSearchable, defaultValue, dontFireChangeOnInit }) {
    const [data, setData] = useState(null);
    const [init, setInit] = useState(false);
    const [defaultIndex, setDefaultIndex] = useState(0);
    const [defaultGroupItem, setDefaultGroupItem] = useState();
    const [isGroupState, setIsGroup] = useState(false);

    useEffect(() => {
        if (promise && !init) {
            if (typeof promise.then === 'function') {
                promise.then(it => handleData(it));
            } else {
                handleData(promise);
            }
        }
    }, []);

    const handleData = it => {
        if (it) {
            let data = it;
            let isGroup = false;
            if (transform && Array.isArray(data)) {
                isGroup = transform[0].group !== undefined;
                setIsGroup(isGroup);
                if (isGroup) {
                    const groupBy = transform[0].group;
                    const map = transform[0].map;
                    const modifData = [
                        { label: 'Instant Funding', options: null },
                        { label: '1 Phase', options: null },
                        { label: '2 Phases', options: null },
                        { label: '3 Phases', options: null }
                    ];

                    data.forEach(it => {
                        const ind = modifData.findIndex(m => m.label === it[groupBy]);
                        if (ind !== -1) {
                            const obj = {
                                value: it[map[0]],
                                label: it[map[1]]
                            };

                            if (modifData[ind].options === null) {
                                modifData[ind].options = [obj];
                            } else {
                                modifData[ind].options.push(obj);
                            }
                        }
                    });

                    data = modifData.filter(it => it.options !== null);

                } else {
                    data = data.map(it => ({
                        value: it[transform[0]],
                        label: it[transform[1]]
                    }));
                }

            }
            setData(Array.isArray(data) ? data : []);
            onLoaded && onLoaded();

            if (defaultValue === -1) {
                setInit(true);
                setDefaultIndex(-1);
                return;
            }

            let defaultIndex = 0;
            if (!isGroup)
                defaultValue && (defaultIndex = data.findIndex(d => d.value === defaultValue));

            let defaultGroupValue;
            if (isGroup) {
                if (defaultValue) {
                    for (let i = 0; i < data.length; i++) {
                        const _value = data[i]?.options?.find(it => it.value === defaultValue)
                        if (_value) {
                            defaultGroupValue = _value;
                            setDefaultGroupItem(_value);
                            break;
                        }
                    }
                }
            }

            setDefaultIndex(defaultIndex);
            // data[0].options[0] stub for first element of first grouping options, cause more complex logic is no needed for now
            if (data[0] === undefined) {
                setData([{ options: [{}] }])
                return;
            }
            onChange && defaultIndex !== -1 && dontFireChangeOnInit !== true && onChange(isGroup ? (defaultGroupValue ?? data[0].options[0]) : data[defaultIndex]);
            setInit(true);
        } else {
            setData([]);
        }
    }

    const menuOpen = () => {
        const selected = document.getElementsByClassName("pfj-dropdown__option--is-selected")[0];
        selected && selected.scrollIntoView({ block: 'center', inline: 'center' });
    };

    return (
        data && <Select
            defaultValue={defaultIndex === -1 || (isGroupState ? (defaultGroupItem ?? data[0].options[0]) : data[defaultIndex])}
            options={data}
            isDisabled={!data}
            onMenuOpen={menuOpen}
            onChange={onChange}
            isClearable={isClearable}
            isSearchable={isSearchable}
            className={"pfj-dropdown"}
            classNamePrefix={"pfj-dropdown"}
            placeholder={placeholder ?? 'Select...'}
            formatGroupLabel={formatGroupLabel} />
    )
}