import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import Icon from '../Icon/Icon'
import './Router.css'

export default function Router({ isMobile }) {
    const location = useLocation();
    const [dashboardActive, setDashboardActive] = useState(false);

    useEffect(() => {
        setDashboardActive(location.pathname.includes('/dashboard'));
    }, [location])

    return (
        <nav className={`menu ${isMobile ? 'mobile' : ''}`}>
            <ul>
                <li>
                    <NavLink exact="true" to="/" activeclassname='active'>Home</NavLink>
                </li>
                <li>
                    <NavLink to="/programs" activeclassname='active'>Programs</NavLink>
                </li>
                <li>
                    <NavLink to="/news" activeclassname='active'>News</NavLink>
                </li>
                <li>
                    <NavLink to="/media" activeclassname='active'>Media</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" activeclassname='active'>Contact</NavLink>
                </li>
                <li className={dashboardActive ? 'dashboard-active' : ''}>
                    <NavLink to="/dashboard/explore">Dashboard</NavLink>
                </li>
                {
                    isMobile && location.pathname?.includes('/dashboard/') &&
                    <ul className='submenu'>
                        <li>
                            <NavLink to="/dashboard/explore" activeclassname='active'>
                                <Icon name={'explore'} style={{ width: '21px', fill: 'rgb(232, 232, 232)' }} />
                                <div>Explore</div>
                            </NavLink>
                        </li>
                        <li>
                            <div className='mobile-menu-section'>
                                <div className='mobile-menu-section-title'>
                                    <Icon name={'compare'} style={{ width: '21px', fill: 'rgb(232, 232, 232)' }} />
                                    <div>Compare</div>
                                </div>
                                <div className='mobile-menu-section-subgroup'>
                                    <NavLink to="/dashboard/compare" activeclassname='active'>
                                        <div>Challenge</div>
                                    </NavLink>
                                    <NavLink to="/dashboard/compare-by-price" activeclassname='active'>
                                        <div>By Price</div>
                                    </NavLink>
                                </div>
                            </div>
                        </li>
                        <li>
                            <NavLink to="/dashboard/rankings" activeclassname='active'>
                                <Icon name={'rankings'} style={{ width: '21px', fill: 'rgb(232, 232, 232)' }} />
                                <div>Rankings</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard/match" activeclassname='active'>
                                <Icon name={'match'} style={{ width: '22px', fill: 'rgb(232, 232, 232)' }} />
                                <div>Match</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard/competitions" activeclassname='active'>
                                <Icon name={'cup'} style={{ width: '22px', fill: 'rgb(232, 232, 232)' }} />
                                <div>Competitions</div>
                            </NavLink>
                        </li>
                    </ul>
                }
            </ul>
        </nav>
    )
}