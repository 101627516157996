import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { NewsCacheI } from '../../caches/NewsCache';
import LoaderMask from '../../components/LoaderMask/LoaderMask';
import { BASE_URL, MIN_COLLAPSE_WIDTH } from '../../constants/constants';
import getNewsDate from '../../hooks/useNewsDate';
import useWindowSize from '../../hooks/useWindowSize';
import './NewsDetailsPage.css';

export default function NewsDetailsPage() {
    const { newsId } = useParams();
    const { state } = useLocation();
    const [random, setRandom] = useState(null);
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState(null);
    const [width] = useWindowSize();

    useEffect(() => {
        state?.title && (document.title = state.title + ' - Prop Firm Journal');
        setTimeout(() => window.scrollTo(0, 0));

        setLoading(true);
        Promise.all([
            NewsCacheI.getRandomNews(),
            NewsCacheI.getNewsById(newsId)
        ]).then(responses => {
            responses[0] && setRandom(responses[0]);
            
            if (responses[1][0]) {
                state?.title === undefined && (document.title = responses[1][0].title + ' - Prop Firm Journal');
                setNews(responses[1][0]);
                setLoading(false);
            }
        }).catch(() => setLoading(false));
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {loading && <LoaderMask />}
            <main className='news-page'>
                <div className='news-page-container'>
                    <div className='left'>
                        {
                            news &&
                            <div className='news-block'>
                                <div className='h-font'>{news.title}</div>
                                <div className="text-label" style={{ 'marginRight': 'auto' }}>{news.category.name}</div>
                                <div className='img-container' style={{ 'backgroundImage': (news.img && `url("${BASE_URL}image?url=${news.img}")`) }}></div>
                                <div className="s-font" style={{ 'textTransform': 'uppercase' }}>{getNewsDate(news.date)}</div>
                                <div className='news-content' dangerouslySetInnerHTML={{__html: news.detailsHtml}}></div>
                                <NavLink to={'/news'}>
                                    <div className='read-more-btn pfj-btn dark' style={{ 'width': '210px', 'textAlign': 'center' }}>Back to News Home page</div>
                                </NavLink>

                            </div>
                        }
                    </div>
                    {
                        width >= MIN_COLLAPSE_WIDTH &&
                        <div className='right'>
                            <div className='right-earn'>
                                <div className="text-label dark" style={{ width: '260px' }}>EARN AS A PROFESSIONAL TRADER</div>
                                <br />
                                <div className='h-font'>Our Challenge Programs</div>
                                <br />
                                <div className='s-font' style={{ textAlign: 'justify' }}>
                                    Our Prop Firm has multiple programs to start your journey as a professionalTrader. Choose one of our funding programs and start trading, earn bonuses and profit-splits.
                                </div>
                                <br />
                                <NavLink to='/programs'>
                                    <div className='news-start-challenge-btn pfj-btn dark'>Start Challenge</div>
                                </NavLink>
                            </div>
                            <hr style={{ 'border': 'var(--main-light-darker) 2px solid' }} />
                            <div className='random-news-container'>
                                <div className='h-font'>You Maybe Interested In</div>
                                <br />
                                <div className='random-news-set'>
                                    {
                                        random && random.map((it, i) => {
                                            return (
                                                <div key={i} className='random-new-block'>
                                                    <a href={'/news/' + it._id}>
                                                        <div className='random-img-container' style={{ 'backgroundImage': (it.img && `url("${BASE_URL}image?url=${it.img}")`) }}></div>
                                                        <div className='random-text-block'>
                                                            <div className='random-text-title h-font'>{it.title}</div>
                                                            <div className='random-text-date s-font'>{getNewsDate(it.date)}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr style={{ 'border': 'var(--main-light-darker) 2px solid' }} />
                        </div>
                    }
                </div>
            </main>
        </>
    )
}
