import { useEffect } from 'react';
import { useState } from 'react'
import { DashboardCacheI } from '../../../caches/DashboardCache';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import usePageInit from '../../../hooks/usePageInit';
import './MatchPage.css'
import thousands from '../../../hooks/thousands';
import thousandsShort from '../../../hooks/thousandsShort';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { DataGrid } from '../../../components/DataGrid/DataGrid';
import Paginator from '../../../components/Paginator/Paginator';
import useWindowSize from '../../../hooks/useWindowSize';
import { MED_COLLAPSE_WIDTH } from '../../../constants/constants';
import LoaderMask from '../../../components/LoaderMask/LoaderMask';
import { useRef } from 'react';

export default function MatchPage() {

    usePageInit('Match - Prop Firm Journal');

    const [prices, setPrices] = useState();
    const [sizes, setSizes] = useState();
    const [types, setTypes] = useState();
    const [dailyLosses, setDailyLosses] = useState();
    const [maxLosses, setMaxLosses] = useState();
    const [filter, setFilter] = useState({
        page: 0,
        perPage: 25,
        firstProfitSplit: 'any',
        maxProfitSplit: 'any',
        firstWithdrawal: 'any',
        leverage: 'any',
        commissionFX: 'any',
        commissionIndices: 'any',
        commissionMetals: 'any',
        commissionCrypto: 'any',
        trustPilotStars: 3
    });
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [pages, setPages] = useState();
    const [windowSize] = useWindowSize();
    const [loaded, setLoaded] = useState(false);
    const [results, setResults] = useState();
    const [defaultResult, setDefaultResult] = useState(25);
    const [phaseTargets, setPhaseTargets] = useState();

    const resultGrid = useRef();

    useEffect(() => {
        Promise.all([
            DashboardCacheI.getPrices(),
            DashboardCacheI.getSizes(),
            DashboardCacheI.getTypes(),
            DashboardCacheI.getDailyLosses(),
            DashboardCacheI.getMaxLosses(),
            DashboardCacheI.postMatch(filter),
            DashboardCacheI.getPhaseTargets(0),
            DashboardCacheI.getPhaseTargets(1),
            DashboardCacheI.getPhaseTargets(2),
        ]).then(results => {
            if (results) {
                setPrices(results[0]);

                const forSizeSelect = results[1]?.map(it => ({ value: it, label: '$' + thousands(it.toString()) }));
                setSizes(forSizeSelect);

                const _types = [
                    { label: 'Instant', value: results[2]?.find(it => it.type === 'Instant Funding')?._id },
                    { label: '1 Step', value: results[2]?.find(it => it.type === '1 Phase')?._id },
                    { label: '2 Step', value: results[2]?.find(it => it.type === '2 Phases')?._id },
                    { label: '3 Step', value: results[2]?.find(it => it.type === '3 Phases')?._id }
                ];

                setTypes(_types);

                const forDailyLossSelect = results[3]?.map(it => ({ value: it, label: it === null ? 'N/A' : it + '%' }));
                setDailyLosses(forDailyLossSelect);

                const forMaxLossSelect = results[4]?.map(it => ({ value: it, label: it === null ? 'N/A' : it + '%' }));
                setMaxLosses(forMaxLossSelect);

                handleTableData(results[5]);

                setPhaseTargets({
                    0: results[6]?.map(it => ({ value: it, label: it + '%' })),
                    1: results[7]?.map(it => ({ value: it, label: it + '%' })),
                    2: results[8]?.map(it => ({ value: it, label: it + '%' })),
                });
            }
        }).catch(err => console.error(err));
    }, []);

    const apply = (page, notScroll, perPage) => {
        filter.minPrice !== undefined && filter.minPrice < prices.min && (filter.minPrice = prices.min);
        filter.maxPrice !== undefined && filter.maxPrice > prices.max && (filter.maxPrice = prices.max);

        const newFilter = { ...filter };
        ['commissionFX', 'commissionIndices', 'commissionMetals', 'commissionCrypto'].forEach(it => {
            filter[it]?.p && (newFilter[it] = filter[it].n)
        });

        if (page !== undefined && page !== null) {
            newFilter.page = page;
        }

        if (perPage !== undefined && perPage !== null) {
            newFilter.perPage = perPage;
            setDefaultResult(null);
            setTimeout(() => {
                setDefaultResult(perPage);
            })
            if (filter.perPage > perPage) {
                setTimeout(() => {
                    resultGrid.current.scrollIntoView({ block: "end" })
                });
            }
        }

        setFilter(newFilter);
        DashboardCacheI.postMatch(newFilter).then(data => {
            handleTableData(data);
            !notScroll && window.scrollTo({ top: 0, behavior: 'smooth' });
        }).catch(err => console.error(err));
    }

    const reset = (notScroll) => {
        const _filter = {
            page: 0,
            perPage: filter.perPage,
            firstProfitSplit: 'any',
            maxProfitSplit: 'any',
            firstWithdrawal: 'any',
            leverage: 'any',
            commissionFX: 'any',
            commissionIndices: 'any',
            commissionMetals: 'any',
            commissionCrypto: 'any',
            trustPilotStars: 3
        };

        setFilter(_filter);
        DashboardCacheI.postMatch(_filter).then(data => {
            handleTableData(data);
            !notScroll && window.scrollTo({ top: 0, behavior: 'smooth' });
        }).catch(err => {
            console.error(err);
            setLoaded(true);
        });
    }

    const handleTableData = (data) => {
        data?.total === undefined || data?.total === null || (setTotal(data.total));
        data?.pages === undefined || data?.pages === null || (setPages(data.pages));

        if (data?.items) {
            const rows = [];
            data.items.forEach(row => {
                let cells = [];
                if (window.innerWidth >= MED_COLLAPSE_WIDTH) {
                    cells = [
                        { value: undefined, type: 'picture', picture: row.companyLogoUrl, background: row.color2 },
                        { value: row.programName, value2: '$' + thousandsShort(row.accountSize?.toString()) },
                        { value: row.accountPrice === undefined || row.accountPrice === null ? 'N/A' : '$' + thousands(row.accountPrice?.toString()) },
                        { value: 'See Program\'s details', stateLink: { link: '../../dashboard/explore', state: { companyId: row.companyId, programId: row.programId } } },
                        { value: 'Buy Challenge', btnLink: row.affiliateLink, icon: 'cart', size: '32px' }
                    ]
                } else {
                    cells = [
                        { value: row.companyName },
                        { value: row.programName, value2: '$' + thousandsShort(row.accountSize?.toString()) },
                        { value: row.accountPrice === undefined || row.accountPrice === null ? 'N/A' : '$' + thousands(row.accountPrice?.toString()) },
                        { stateLink: { link: '../../dashboard/explore', state: { companyId: row.companyId, programId: row.programId }, info: true } },
                        { value: 'Buy Challenge', btnLink: row.affiliateLink, icon: 'cart', size: '20px' }
                    ]
                }

                rows.push({ cells });
            });

            const tData = [];
            tData.push({
                columnHeaders: [
                    { name: 'Prop Firm' },
                    { name: 'Program' },
                    { name: 'Price' },
                    { name: 'Explore' },
                    { name: 'Buy' },
                ],
                horScrolling: true,
                rows
            });

            setData(tData);
            setLoaded(true);
            setResults(null);

            setTimeout(() => {
                if (data?.total <= 25) {
                    setResults([
                        { label: '25 Results', value: 25 }
                    ]);
                } else if (data?.total <= 50 && data?.total > 25) {
                    setResults([
                        { label: '25 Results', value: 25 },
                        { label: '50 Results', value: 50 }
                    ]);
                } else {
                    setResults([
                        { label: '25 Results', value: 25 },
                        { label: '50 Results', value: 50 },
                        { label: '100 Results', value: 100 },
                    ]);
                }
            })

        }
    };


    return (
        <>
            {
                !loaded &&
                <div className='explore-loader'><LoaderMask /></div>
            }
            <div className='compare-container fade-in' style={{ 'overflow': 'hidden', paddingBottom: '130px' }}>
                <div className='h-font'>Match</div>
                {
                    sizes && prices && data &&
                    <div className={'match-section' + (windowSize < 1220 ? ' mobile' : '')}>
                        <div className='match-filter-panel'>
                            <div className='dg-header-fill' style={{ height: '30px' }}>
                                <div className='h-font' style={{ fontSize: '24px' }}>Filters</div>
                            </div>
                            <div className='match-filter-content'>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>Price Minimum / Maximum</div>
                                    <div className='match-slider'>
                                        <Slider min={prices.min} max={prices.max} allowCross={false} range={true} defaultValue={[prices.min, prices.max]}
                                            value={[
                                                filter.minPrice === undefined ? prices.min : filter.minPrice,
                                                filter.maxPrice === undefined ? prices.max : filter.maxPrice
                                            ]}
                                            onChange={e => setFilter({ ...filter, minPrice: e[0], maxPrice: e[1] })}
                                            marks={{ [prices.min]: '$' + prices.min, [prices.max]: '$' + thousands(prices.max.toString()) }}
                                        />
                                    </div>
                                    <div className='match-slider-values'>
                                        <input type={'number'} placeholder={'From'}
                                            onChange={e => setFilter({ ...filter, minPrice: parseInt(e.target.value) })}
                                            value={filter.minPrice === undefined || filter.minPrice === null ? prices.min : filter.minPrice} />
                                        &nbsp;&nbsp;—&nbsp;&nbsp;
                                        <input type={'number'} placeholder={'To'}
                                            onChange={e => setFilter({ ...filter, maxPrice: parseInt(e.target.value) })}
                                            value={filter.maxPrice === undefined || filter.maxPrice === null ? prices.max : filter.maxPrice} />
                                    </div>
                                </div>
                                <div className='match-filter-block hor'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Account Size
                                    </div>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <button
                                            className={'pfj-btn' + (filter.accountSize === undefined || filter.accountSize === 'any' ? ' dark' : '')}
                                            onClick={() => setFilter({ ...filter, accountSize: 'any' })}
                                        >Any</button>
                                        <div style={{ width: '130px' }}>
                                            <Dropdown
                                                promise={sizes}
                                                placeholder={'Select'}
                                                defaultValue={{ label: '', value: 0 }}
                                                onChange={e => setFilter({ ...filter, accountSize: e.value })} />
                                        </div>

                                    </div>
                                </div>

                                <div className='match-filter-block hor'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Challenge Type
                                    </div>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <button
                                            className={'pfj-btn' + (filter.programTypeId === undefined || filter.programTypeId === 'any' ? ' dark' : '')}
                                            onClick={() => setFilter({ ...filter, programTypeId: 'any' })}
                                        >Any</button>
                                        <div style={{ width: '130px' }}>
                                            <Dropdown
                                                promise={types}
                                                placeholder={'Select'}
                                                defaultValue={{ label: '', value: 0 }}
                                                onChange={e => setFilter({ ...filter, programTypeId: e.value })} />
                                        </div>

                                    </div>
                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Time
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <label className='checkbox-container'>
                                                <input type='checkbox' checked={filter.noMinDays === true}
                                                    onChange={() => setFilter({ ...filter, noMinDays: filter.noMinDays === undefined ? true : !filter.noMinDays })} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='s-font'>
                                            Show no minimum trading days only
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <label className='checkbox-container'>
                                                <input type='checkbox' checked={filter.onlyMaxDays === true}
                                                    onChange={() => setFilter({ ...filter, onlyMaxDays: filter.onlyMaxDays === undefined ? true : !filter.onlyMaxDays })} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='s-font'>
                                            Show unlimited max days only
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Targets
                                    </div>
                                    {
                                        [0, 1, 2].map(it => {
                                            return (
                                                <div key={it} className='match-filter-block hor'>
                                                    <div className='s-font'>
                                                        Phase {it + 1}
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                        <button
                                                            className={'pfj-btn' + (filter['phase' + it] === undefined || filter['phase' + it] === 'any' ? ' dark' : '')}
                                                            onClick={() => setFilter({ ...filter, ['phase' + it]: 'any' })}
                                                        >Any</button>
                                                        <div style={{ width: '130px' }}>
                                                            <Dropdown
                                                                promise={phaseTargets[it]}
                                                                placeholder={'Select'}
                                                                defaultValue={{ label: '', value: 0 }}
                                                                onChange={e => setFilter({ ...filter, ['phase' + it]: e.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Drawdown
                                    </div>
                                    <div className='match-filter-block hor'>
                                        <div className='s-font'>
                                            Daily Drawdown Type
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button
                                                className={'pfj-btn' + (filter.dailyLossType === undefined || filter.dailyLossType === 'any' ? ' dark' : '')}
                                                onClick={() => setFilter({ ...filter, dailyLossType: 'any' })}
                                            >Any</button>
                                            <div style={{ width: '130px' }}>
                                                <Dropdown
                                                    promise={[
                                                        { value: 'Equity', label: 'Equity' },
                                                        { value: 'Balance', label: 'Balance' },
                                                    ]}
                                                    placeholder={'Select'}
                                                    defaultValue={{ label: '', value: 0 }}
                                                    onChange={e => setFilter({ ...filter, dailyLossType: e.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='match-filter-block hor'>
                                        <div className='s-font'>
                                            Daily Drawdown Amount
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button
                                                className={'pfj-btn' + (filter.dailyLossPer === undefined || filter.dailyLossPer === 'any' ? ' dark' : '')}
                                                onClick={() => setFilter({ ...filter, dailyLossPer: 'any' })}
                                            >Any</button>
                                            <div style={{ width: '130px' }}>
                                                <Dropdown
                                                    promise={dailyLosses}
                                                    placeholder={'Select'}
                                                    defaultValue={{ label: '', value: 0 }}
                                                    onChange={e => setFilter({ ...filter, dailyLossPer: e.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='match-filter-block hor'>
                                        <div className='s-font'>
                                            Max Drawdown Type
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button
                                                className={'pfj-btn' + (filter.maxLossType === undefined || filter.maxLossType === 'any' ? ' dark' : '')}
                                                onClick={() => setFilter({ ...filter, maxLossType: 'any' })}
                                            >Any</button>
                                            <div style={{ width: '130px' }}>
                                                <Dropdown
                                                    promise={[
                                                        { value: 'Fixed', label: 'Fixed' },
                                                        { value: 'Trailing', label: 'Trailing' },
                                                    ]}
                                                    placeholder={'Select'}
                                                    defaultValue={{ label: '', value: 0 }}
                                                    onChange={e => setFilter({ ...filter, maxLossType: e.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='match-filter-block hor'>
                                        <div className='s-font'>
                                            Max Drawdown Amount
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button
                                                className={'pfj-btn' + (filter.maxLossPer === undefined || filter.maxLossPer === 'any' ? ' dark' : '')}
                                                onClick={() => setFilter({ ...filter, maxLossPer: 'any' })}
                                            >Any</button>
                                            <div style={{ width: '130px' }}>
                                                <Dropdown
                                                    promise={maxLosses}
                                                    placeholder={'Select'}
                                                    defaultValue={{ label: '', value: 0 }}
                                                    onChange={e => setFilter({ ...filter, maxLossPer: e.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Profit
                                    </div>
                                    <div className='match-filter-block'>
                                        <div className='s-font'>
                                            Minimum Starting Profit Split
                                        </div>
                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                            {
                                                ['any', 50, 60, 70, 80, 90].map((it, i) => {

                                                    return (
                                                        <button
                                                            key={i}
                                                            style={{ width: '60px' }}
                                                            className={'pfj-btn' + (filter.firstProfitSplit === it ? ' dark' : '')}
                                                            onClick={() => setFilter({ ...filter, firstProfitSplit: it })}>
                                                            {it === 'any' ? 'Any' : it + '%+'}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='match-filter-block'>
                                        <div className='s-font'>
                                            Max Profit Split With Scaling
                                        </div>
                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                            {
                                                ['any', 90, 95, 100].map((it, i) => {

                                                    return (
                                                        <button
                                                            key={i}
                                                            style={{ width: '60px' }}
                                                            className={'pfj-btn' + (filter.maxProfitSplit === it ? ' dark' : '')}
                                                            onClick={() => setFilter({ ...filter, maxProfitSplit: it })}>
                                                            {it === 'any' ? 'Any' : it + '%' + (it === 100 ? '' : '+')}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='match-filter-block'>
                                        <div className='s-font'>
                                            First Withdrawal Date
                                        </div>
                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                            {
                                                ['any', 'instant', 5, 10, 15, 30].map((it, i) => {

                                                    return (
                                                        <button
                                                            key={i}
                                                            style={{ width: '70px' }}
                                                            className={'pfj-btn' + (filter.firstWithdrawal === it ? ' dark' : '')}
                                                            onClick={() => setFilter({ ...filter, firstWithdrawal: it })}>
                                                            {it === 'any' ? 'Any' : (it === 'instant' ? 'Instant' : it + ' Days')}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Trading Conditions
                                    </div>
                                    <div className='match-filter-block'>
                                        <div className='s-font'>
                                            Account Leverage
                                        </div>
                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                            {
                                                ['any', 'equals', 'greater'].map((it, i) => {
                                                    return (
                                                        <button
                                                            key={i}
                                                            style={{ width: '60px' }}
                                                            className={'pfj-btn' + (filter.leverage === it ? ' dark' : '')}
                                                            onClick={() => setFilter({ ...filter, leverage: it })}>
                                                            {it === 'any' ? 'Any' : (it === 'equals' ? '1:100' : '+ 1:100')}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        [
                                            ['commissionFX', 'Commissions on FX'],
                                            ['commissionIndices', 'Commissions on Indices'],
                                            ['commissionMetals', 'Commissions on Metals'],
                                            ['commissionCrypto', 'Commissions on Crypto']].map((k, i) => {

                                                return (
                                                    <div key={i} className='match-filter-block'>
                                                        <div className='s-font'>
                                                            {k[1]}
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                                            {
                                                                ['any', 0, 3, 5, 7, { n: 7, p: true }].map((it, i) => {
                                                                    return (
                                                                        <button
                                                                            key={i}
                                                                            style={{ width: '60px' }}
                                                                            className={'pfj-btn' + (filter[k[0]] === it || (filter[k[0] + 'Plus'] && it.p) ? ' dark' : '')}
                                                                            onClick={() => setFilter({ ...filter, [k[0]]: it, [k[0] + 'Plus']: it.p })}>
                                                                            {it === 'any' ? 'Any' : (it.p ? '$' + it.n + '+' : '$' + it)}
                                                                        </button>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                    <div className='match-filter-block hor'>
                                        <div className='s-font'>
                                            Platform
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <button
                                                className={'pfj-btn' + (filter.platform === undefined || filter.platform === 'any' ? ' dark' : '')}
                                                onClick={() => setFilter({ ...filter, platform: 'any' })}
                                            >Any</button>
                                            <div style={{ width: '130px' }}>
                                                <Dropdown
                                                    promise={[
                                                        { value: 'mt4', label: 'MT4' },
                                                        { value: 'mt5', label: 'MT5' },
                                                        { value: 'cTrader', label: 'cTrader' },
                                                    ]}
                                                    placeholder={'Select'}
                                                    defaultValue={{ label: '', value: 0 }}
                                                    onChange={e => setFilter({ ...filter, platform: e.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Restrictions
                                    </div>
                                    {
                                        [
                                            ['easAllowed', 'EA\'s Allowed'],
                                            ['copyTrading', 'Trade Copiers Allowed'],
                                            ['slRequired', 'Mandatory Stop Loss'],
                                            ['weekendHolding', 'Weekend Holding'],
                                            ['cryptoTradingOnWeekends', 'Crypto Trading on Weekend'],
                                            ['newsTrading', 'News Trading']].map((k, i) => {
                                                return (
                                                    <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <label className='checkbox-container'>
                                                                <input type='checkbox' checked={filter[k[0]] === true}
                                                                    onChange={() => setFilter({ ...filter, [k[0]]: filter[k[0]] === undefined ? true : !filter[k[0]] })} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div className='s-font'>
                                                            {k[1]}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                                <hr style={{ border: 'none', borderTop: 'var(--main-light-darker) 2px solid' }}></hr>
                                <div className='match-filter-block'>
                                    <div className='h-font' style={{ fontSize: '20px' }}>
                                        Reviews
                                    </div>
                                    <div className='s-font'>
                                        Trust Pilot Rating — {filter.trustPilotStars}
                                    </div>
                                    <div className='match-slider single'>
                                        <Slider min={0} max={5} step={0.1} defaultValue={3}
                                            value={filter.trustPilotStars}
                                            onChange={e => setFilter({ ...filter, trustPilotStars: e })}
                                            marks={{ 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                                        />
                                    </div>
                                </div>
                                <div className='match-filter-bottom'>
                                    <button className='pfj-btn dark' style={{ width: '140px' }}
                                        onClick={() => reset(windowSize < 1220)}>
                                        Reset All Filters
                                    </button>
                                    <button className='pfj-btn' onClick={() => apply(0, windowSize < 1220)}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='match-result-container'>
                            {
                                data?.length > 0 &&
                                <>
                                    <div className='match-result-table'>
                                        <DataGrid
                                            titleSettings={{ text: total + ' Results' }}
                                            tableSettings={{ columnSeparator: true }}
                                            dataSets={data} />
                                    </div>
                                    <div ref={resultGrid} className='match-paginator-container'>
                                        {
                                            results?.length > 0 && total > 0 && defaultResult ?
                                                <div className='s-font' style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    View:
                                                    <Dropdown
                                                        promise={results}
                                                        defaultValue={defaultResult}
                                                        dontFireChangeOnInit={true}
                                                        onChange={e => apply(0, true, e.value)} />
                                                </div> :
                                                <div className='s-font' style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                    <Dropdown promise={[{ label: '25 Results', value: 25 }]} defaultValue={25} />
                                                    {/* Stub to remove UI lags */}
                                                </div>
                                        }

                                        <div className='match-result-paginator'>
                                            {
                                                pages > 1 &&
                                                <Paginator
                                                    totalPages={pages}
                                                    pageSetNum={5}
                                                    pageChange={p => apply(p, true)} />
                                            }
                                        </div>
                                    </div>

                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}