import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function ProgramTable({ selectedProgram, exploreData, platforms }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        
        const platform = exploreData.propFirm.platforms?.length > 0
            ? platforms.filter(p => exploreData.propFirm.platforms.findIndex(pl => pl.value === p._id) !== -1).map(it => it.name).join('-')
            : 'Other Platform';
        
        const currenciesCell = exploreData.propFirm.accountInOtherCurrencies !== true
            ? [{ value: 'Account in other currencies?' }, { value: false, type: 'boolean' }]
            : [{ value: 'Account in other currencies?' }, { type: 'tooltip', tooltip: exploreData.propFirm.otherCurrenciesSymbol }];


        let otherCommission = exploreData.propFirm.commissionOtherInstruments;
        if (exploreData.propFirm.hasOwnProperty('commissionIndices') ||
            exploreData.propFirm.hasOwnProperty('commissionMetals') ||
            exploreData.propFirm.hasOwnProperty('commissionCrypto')) {
            const cm = [];
            exploreData.propFirm.commissionIndices === null ||
                exploreData.propFirm.commissionIndices === undefined ||
                cm.push({ v: exploreData.propFirm.commissionIndices, d: 'Indices' });

            exploreData.propFirm.commissionMetals === null ||
                exploreData.propFirm.commissionMetals === undefined ||
                cm.push({ v: exploreData.propFirm.commissionMetals, d: 'Metals' });

            exploreData.propFirm.commissionCrypto === null ||
                exploreData.propFirm.commissionCrypto === undefined ||
                cm.push({ v: exploreData.propFirm.commissionCrypto, d: 'Crypto' });

            if (cm.length === 1) {
                otherCommission = `$${cm[0].v} on ${cm[0].d}`;
            } else if (cm.length === 2) {
                cm[0].v === cm[1].v && (otherCommission = `$${cm[0].v} on ${cm[0].d} and ${cm[1].d}`);
                cm[0].v === cm[1].v || (otherCommission = `$${cm[0].v} on ${cm[0].d}, ${cm[1].v} on ${cm[1].d}`);
            } else if (cm.length === 3) {
                if (cm[0].v === cm[1].v && cm[1].v === cm[2].v) otherCommission = `$${cm[0].v} on ${cm[0].d}, ${cm[1].d} and ${cm[2].d}`;
                else if (cm[0].v === cm[1].v && cm[1].v !== cm[2].v) otherCommission = `$${cm[0].v} on ${cm[0].d} and ${cm[1].d}, $${cm[2].v} on ${cm[2].d}`;
                else if (cm[0].v !== cm[1].v && cm[1].v === cm[2].v) otherCommission = `$${cm[0].v} on ${cm[0].d}, $${cm[1].v} on ${cm[1].d} and ${cm[2].d}`;
                else if (cm[0].v === cm[2].v && cm[1].v !== cm[2].v) otherCommission = `$${cm[0].v} on ${cm[0].d} and ${cm[2].d}, $${cm[1].v} on ${cm[1].d}`;
                else if (cm[0].v === cm[2].v && cm[0].v !== cm[1].v) otherCommission = `$${cm[0].v} on ${cm[0].d} and ${cm[2].d}, $${cm[1].v} on ${cm[1].d}`;
                else if (cm[0].v !== cm[1].v && cm[1].v !== cm[2].v) otherCommission = `$${cm[0].v} on ${cm[0].d}, $${cm[1].v} on ${cm[1].d} and $${cm[2].v} on ${cm[2].d}`;
            }
        }

        const data = [
            {
                rows: [
                    { cells: [{ value: 'Type' }, { value: exploreData.programType.type }] },
                    { cells: [{ value: 'Broker' }, { value: exploreData.propFirm.brokerName }] },
                    { cells: [{ value: 'Platform' }, { value: platform }] },
                    { cells: [{ value: 'Commission on FX' }, { value: '$'+exploreData.propFirm.commissionFX, tooltip: otherCommission }] },
                    { cells: [{ value: 'Leverage' }, { value: exploreData.leverage }] },
                    { cells: currenciesCell }
                ]
            }
        ];

        setTable(data);

    }, [exploreData]);

    return (
        <div className="program-table">
            <DataGrid
                titleSettings={{ text: selectedProgram?.label }}
                tableSettings={{ columnSeparator: true }}
                dataSets={table} />
        </div>
    )
}