import { useState } from 'react'
import { useEffect } from 'react'
import { DataGrid } from '../../../../components/DataGrid/DataGrid';
import useWindowSize from '../../../../hooks/useWindowSize';
import PriceCompareTable from '../PriceCompareTable/PriceCompareTable';
import './CompareTable.css';
import thousands from "../../../../hooks/thousands";

export default function CompareTable({ exploreData, mobile }) {
    const [tables, setTables] = useState(null);
    const [width] = useWindowSize();

    useEffect(() => {
        let tables = [];
        /* General Information */
        const generalInfo = {
            name: 'General Information',
            tableData: [{
                rows: [
                    { cells: cell(exploreData, 'Minimum Days', 'minDays', mobile), },
                    { cells: cell(exploreData, 'Maximum Days', 'maxDays', mobile), },
                    { cells: cell(exploreData, 'Max Drawdown Type', 'maxLossType', mobile), },
                    { cells: cell(exploreData, 'Max Drawdown Limit', 'maxLossPer', mobile, undefined, 'percent'), },
                    { cells: cell(exploreData, 'Daily Drawdown Type', 'dailyLossType', mobile), },
                    { cells: cell(exploreData, 'Daily Drawdown Limit', 'dailyLossPer', mobile, undefined, 'percent'), }
                ]
            }]
        };

        const phasesData = phases(exploreData, mobile);
        phasesData.length > 0 && generalInfo.tableData[0].rows.push(...phasesData);

        Object.entries(exploreData).forEach(entry => {
            if (entry[1]) {
                const it = entry[1];
                let drawdownToProfitRatio = 'N/A'
                if (it.phases === null || it.phases?.length === 0) {
                    drawdownToProfitRatio = 'N/A'
                } else {
                    const profitTargetsNum = it.phases.map(it => parseFloat(it.target?.split('%')[0] ?? 0));
                    drawdownToProfitRatio = (profitTargetsNum.reduce((a, b) => a + b) / (it.maxLossPer ?? 1)).toFixed(3);
                }

                exploreData[entry[0]].drawdownToProfitRatio = drawdownToProfitRatio;
            }
        });

        generalInfo.tableData[0].rows.push({ cells: cell(exploreData, 'Profit to Drawdown Ratio', 'drawdownToProfitRatio', mobile) })

        tables.push(generalInfo);


        /* Affiliate Program */
        Object.entries(exploreData).forEach(entry => {
            if (entry[1]) {
                const tiers = entry[1].affiliateProgram?.tiers;
                const affiliateAvail = tiers?.length > 0;
                const minCommission = affiliateAvail
                    ? (tiers.reduce((prev, cur) => cur.commission < prev.commission ? cur : prev)).commission
                    : 'N/A';
                const maxCommission = affiliateAvail
                    ? (tiers.reduce((prev, cur) => cur.commission > prev.commission ? cur : prev)).commission
                    : 'N/A';
                const maxCouponCode = affiliateAvail
                ? (tiers.reduce((prev, cur) => cur.couponCode > prev.couponCode ? cur : prev)).couponCode
                : 'N/A';

                exploreData[entry[0]].minCommission = affiliateAvail ? minCommission + '%' : 'N/A';
                exploreData[entry[0]].maxCommission = affiliateAvail ? maxCommission + '%' : 'N/A';
                exploreData[entry[0]].customCode = affiliateAvail ? tiers.some(it => it.customCode) : 'N/A';
                exploreData[entry[0]].maxCouponCode = affiliateAvail ? maxCouponCode + '%' : 'N/A';
                exploreData[entry[0]].withdrawalMethods = entry[1].affiliateProgram ? entry[1].affiliateProgram.withdrawalMethods : 'N/A';
            }
        })

        const affiliateProgram = {
            name: 'Affiliate Program',
            tableData: [{
                rows: [
                    { cells: cell(exploreData, 'Min Commission', 'minCommission', mobile) },
                    { cells: cell(exploreData, 'Max Commission', 'maxCommission', mobile) },
                    { cells: cell(exploreData, 'Custom Coupon Code', 'customCode', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Max Coupon Code', 'maxCouponCode', mobile) },
                    { cells: cell(exploreData, 'Withdrawal Methods', 'withdrawalMethods', mobile) },
                ]
            }]
        };

        tables.push(affiliateProgram);


        /* Restrictions */
        const restrictions = {
            name: 'Restrictions',
            tableData: [{
                rows: [
                    { cells: cell(exploreData, 'Stop Loss Required', 'slRequired', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Lot Size Restriction', 'lotSizeRestriction', mobile, undefined, 'boolean'), },
                    { cells: cell(exploreData, 'Consistency Rule', 'consistencyRule', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'News Trading', 'newsTrading', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Ea\'s Allowed', 'easAllowed', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Weekend Holding', 'weekendHolding', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Crypto Trading On Weekends', 'cryptoTradingOnWeekends', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Copy Trading', 'copyTrading', mobile, undefined, 'boolean') },
                    { cells: cell(exploreData, 'Hedging', 'hedging', mobile, undefined, 'boolean') },
                ]
            }]
        };
        tables.push(restrictions);

        /* Prices */
        tables.push({
            comparePrice: true
        })

        /* Payouts */
        const payouts = {
            name: 'Payouts',
            tableData: [{
                rows: [
                    { cells: cell(exploreData, 'Refund', 'refund', mobile) },
                    { cells: cell(exploreData, 'First Profit Split', 'firstProfitSplitNum', mobile, undefined, 'percent') },
                    { cells: cell(exploreData, 'First Withdrawal', 'firstWithdrawalNum', mobile) },
                    { cells: cell(exploreData, 'Profits From Demo Phase', 'profitsFromChallengePer', mobile) },
                    { cells: cell(exploreData, 'Max Profit Split', 'maxProfitSplit', mobile, undefined, 'percent') },
                    { cells: cell(exploreData, 'Min Days To Payout', 'minTimeToPayout', mobile) },
                ]
            }]
        };
        tables.push(payouts);

        /* Scaling */
        const scaling = {
            name: 'Scaling',
            tableData: [{
                rows: [
                    { cells: cell(exploreData, 'Balance Increase Requirement', 'scalingPlanFirstProfitTarget', mobile) },
                    { cells: cell(exploreData, 'Scale Increase', 'scalingPlanBenefits', mobile) },
                    { cells: cell(exploreData, 'Max Funded Allocation', 'maxFundedAllocation', mobile, undefined, 'price') },
                    { cells: cell(exploreData, 'Max Scaling Allocation', 'maxAllocationWithScaling', mobile, undefined, 'price') },
                    { cells: cell(exploreData, 'Time To 1M From Max Allocation', 'minTimeToGet1MFromMaxAllocation', mobile) },
                ]
            }]
        };
        tables.push(scaling);

        /* Support */
        const support = {
            name: 'Support',
            tableData: [{
                rows: [
                    { cells: cell(exploreData, 'Website Live Chat', 'propFirm', mobile, 'customerSupportLiveChat', 'boolean') },
                    { cells: cell(exploreData, 'Live Chat Days', 'propFirm', mobile, 'customerSupportDays') },
                    { cells: cell(exploreData, 'Live Chat Hours', 'propFirm', mobile, 'customerSupportHours') },
                    { cells: cell(exploreData, 'Trustpilot Rating', 'propFirm', mobile, 'trustPilotStars') },
                ]
            }]
        };
        tables.push(support);


        setTables(tables);

    }, [exploreData, mobile]);

    return (
        <div className='compare-tables'>
            {
                tables && tables.map((t, i) => {
                    if (t.comparePrice === true) {
                        return (
                            <PriceCompareTable
                                key={i}
                                exploreData={exploreData}
                                cellWidth={'195px'}
                                mobile={mobile} />
                        )
                    } else {
                        return (
                            <DataGrid
                                key={i}
                                titleSettings={{ text: t.name }}
                                tableSettings={{ columnSeparator: true, collapsible: true, rowsHoverable: true, commonCellWidth: width < 990 ? width < 790 ? 'unset' : '50px' : '195px' }}
                                dataSets={t.tableData} />
                        )
                    }
                })
            }
        </div>
    )
}

function cell(exploreData, title, key, mobile, subkey = undefined, type = undefined) {
    const cells = [{ value: title }];
    exploreData && [...Array(mobile ? 1 : 3).keys()].forEach(i => {
        let data = exploreData && exploreData[i] && exploreData[i][key];
        subkey !== undefined && (data = !data ? undefined : data[subkey]);
        if (type === undefined || type === 'string' || type === 'price' || type === 'percent') {
            data = data === undefined || data === null || data === '-' || data === ' ' ? 'N/A' : data;
        }
        else if (type === 'boolean') {
            data = data === undefined || data === null || data === '-' || data === ' ' || data === 'N/A' || data === false ? false : true;
        }

        if (data && type === 'price') {
            if (data === 1000000000) {
                data = 'Unlimited';
            }
            else if (data === 'N/A') {
                data = 'N/A'
            }
            else {
                data = '$' + thousands(String(data))
            }
        }

        if (data && type === 'percent') {
            data = data === 'N/A' ? 'N/A' : data + '%';
        }


        if (key === 'firstWithdrawalNum') {
            if (exploreData[i] === undefined || exploreData[i] === null) {
                data = 'N/A';
            } else {
                if (exploreData[i]['firstWithdrawalNum'] === null || exploreData[i]['firstWithdrawalNum'] === undefined) {
                    if (exploreData[i]['firstWithdrawal'] === null || exploreData[i]['firstWithdrawal'] === undefined) {
                        data = 'N/A'
                    } else {
                        data = exploreData[i]['firstWithdrawal'];
                    }
                } else {
                    data = exploreData[i]['firstWithdrawalNum'] === -1 ? 'On Demand' : `After ${exploreData[i]['firstWithdrawalNum']} Days`;
                }
            }
        }


        cells.push({ value: exploreData && exploreData[i] ? data : 'N/A', type: exploreData && exploreData[i] ? (type === 'percent' || type === 'price' ? 'string' : type) : 'string' });
    });

    exploreData || [...Array(mobile ? 1 : 3).keys()].forEach(i => cells.push({ value: 'N/A' }));

    return cells;
}

function phases(exploreData, mobile) {
    const cellsList = [];
    let maxPhases = 0;

    exploreData && [...Array(mobile ? 1 : 3).keys()].forEach(i => {
        const length = exploreData && exploreData[i] && exploreData[i].phases ? exploreData[i].phases.length : 0;
        maxPhases = Math.max(length, maxPhases);
    });

    maxPhases > 0 && [...Array(maxPhases).keys()].forEach(p => {
        const days = [{ value: `Phase ${p + 1} Days` }];
        const targets = [{ value: `Phase ${p + 1} Target` }];

        [...Array(mobile ? 1 : 3).keys()].forEach(i => {
            const phase = exploreData[i] && exploreData[i].phases && exploreData[i].phases[p];
            days.push({ value: phase ? phase.days : 'N/A' });
            targets.push({ value: phase ? phase.target : 'N/A' });
        });

        cellsList.push({ cells: days }, { cells: targets });
    });

    return cellsList;
}
