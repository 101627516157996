import { useEffect } from 'react';
import { useState } from 'react';
import './MobilePriceTable.css';
import thousands from '../../../../../hooks/thousands';
import thousandsShort from '../../../../../hooks/thousandsShort';

export default function MobilePriceTable({ exploreData }) {
    const [selectedSize, setSelectedSize] = useState(null);
    const [sizes, setSizes] = useState(null);

    useEffect(() => {
        const maxLossValue = parseFloat(exploreData.maxLossPer === null ? null : exploreData.maxLossPer) / 100;
        const _sizes = exploreData.prices.map(price => ({
            ...price,
            accountPrice: thousands(String(price.accountPrice)),
            accountSize: thousandsShort(price.accountSize),
            pricePerDrawdown: (price.accountPrice / (price.accountSize * maxLossValue)).toFixed(3)
        }));

        setSizes(_sizes);
        setSelectedSize(_sizes[0]);

    }, [exploreData]);

    const selectSize = size => {
        setSelectedSize(size);
    }

    return (
        <div className='dg'>
            <div className='dg-header-fill'>
                <div className='font-2 title-text'>
                    Prices
                </div>
            </div>
            <div className='ml-body'>
                <div className='title'>Account Sizes</div>
                <div className='sizes'>
                    {
                        sizes && sizes.map((size, i) => {
                            return (
                                <div 
                                    key={i} 
                                    onClick={() => selectSize(size)}
                                    className={'size ' + (size._id === selectedSize._id ? 'selected' : '')}>
                                    {'$'+size.accountSize}
                                </div>
                            )
                        })
                    }
                </div>
                <div className='info'>
                    <div className='info-item'><span>Price:</span> {'$'+selectedSize?.accountPrice }</div>
                    <div className='info-item'><span>Price per drawdown:</span> {'$'+selectedSize?.pricePerDrawdown } </div>
                </div>
            </div>
        </div>
    )
}