import { useLayoutEffect, useState } from "react";
import { MED_COLLAPSE_WIDTH, MIN_COLLAPSE_WIDTH } from "../constants/constants";
import useWindowSize from "./useWindowSize";

export default function useRedrawOnResize(state, setStateFunc) {
    const [sizeState, setSizeState] = useState(null);
    const [width] = useWindowSize();

    const redraw = () => {
        const newState = state;
        setStateFunc(null)
        setTimeout(() => setStateFunc(newState));
    };

    useLayoutEffect(() => {
        if (width <= MED_COLLAPSE_WIDTH && width > MIN_COLLAPSE_WIDTH) {
            if (sizeState !== 1) {
                setSizeState(1);
                redraw();
            }
        }
        else if (width <= MIN_COLLAPSE_WIDTH) {
            if (sizeState !== 2) {
                setSizeState(2);
                redraw();
            }
        }
        else if (width > MED_COLLAPSE_WIDTH) {
            if (sizeState !== 0) {
                setSizeState(0);
                redraw();
            }
        }
        // eslint-disable-next-line
    }, [width]);
}