import { useParams } from 'react-router-dom';
import './ProgramDetailsPage.css'
import { DashboardCacheI } from '../../caches/DashboardCache'
import { useState } from 'react';
import { useEffect } from 'react';
import LoaderMask from '../../components/LoaderMask/LoaderMask';
import { useRef } from 'react';
import { OurProgramTable } from '../../components/OurProgramTable/OurProgramTable';
import useWindowSize from '../../hooks/useWindowSize'
import OurProgramTableMobile from '../../components/OurProgramTableMobile/OurProgramTableMobile';
import { MED_COLLAPSE_WIDTH } from '../../constants/constants';

export default function ProgramDetailsPage() {
    const { id } = useParams();
    const [width] = useWindowSize();
    const [data, setData] = useState();
    const [subdescriptions, setSubdescriptions] = useState();
    const [loading, setLoading] = useState(true);
    const priceRef = useRef(null);

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0));
        setLoading(true);
        DashboardCacheI.getOurProgramsDetails(id)
            .then(data => {
                setTimeout(() => {
                    setLoading(false);
                }, 200)
                setData(data);
                setSubdescriptions(data?.subdescription?.split('[[break]]'))
                document.title = data.name + ' - Prop Firm Journal';
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            })
    }, [id])


    return (
        <>
            {loading && <LoaderMask />}
            {
                data &&
                <main className='fade-in'>
                    <div className='programs-header-container' style={{ backgroundImage: `url("../assets/${data.background}")` }}>
                        <div className='programs-header'>
                            <div className='program-header-card'>
                                <div className='program-header-card-title h-font'>{data.name}</div>
                                <div className='program-header-card-description s-font'>{data.description}</div>
                                <button className='pfj-btn' onClick={() => priceRef.current.scrollIntoView({ behavior: 'smooth' })}>Start Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='programs-subheader-container'>
                        <div className='programs-subheader'>
                            <div className='programs-subheader-title h-font'>{data.subtitle}</div>
                            {
                                subdescriptions && subdescriptions.map((it, i) => {
                                    return (
                                        <div key={i} className='programs-subheader-subdescription s-font'>
                                            {it}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='learn-more-container'>
                        <div className='zig-zag' style={{ transform: 'rotate(180deg)' }}></div>
                        <div className='learn-more-section'>
                            <div className='h-font'>{data.question}</div>
                            <div className='font-1' style={{ fontSize: '14px', opacity: 0.7 }}>{data.underquestion}</div>
                            <br />
                            <button className='pfj-btn' style={{ width: '120px' }} onClick={() => 
                                data.linkId 
                                    ? window.open('/programs/' + data.linkId, '_self')
                                    : priceRef.current.scrollIntoView({ behavior: 'smooth' })}>Learn More</button>
                        </div>
                        <div className='zig-zag'></div>
                    </div>
                    <div className='highlights-container'>
                        <div className='highlights-section'>
                            <div className='text-label' style={{ width: '120px', marginLeft: '10px', alignSelf: width <= 910 && width > 530 ? 'center' : 'unset' }}>Key Highlights</div>
                            <div className='h-font' style={{ marginLeft: '10px', alignSelf: width <= 910 && width > 530 ? 'center' : 'unset' }}>Benefits of the {data.shortName}</div>
                            <div className='highlights-set'>
                                {
                                    data?.highlights?.length > 0 && data.highlights.map((it, i) => {
                                        return (
                                            <div key={i} className='highlight-card'>
                                                <div className='highlight-pic'><img src={`../assets/${it.pic}`} alt={it.title} /></div>
                                                <div className='font-2' style={{ padding: '10px 20px 10px 20px', fontSize: '20px', textAlign: 'left', height: !data.linkId && width > 530 ? '45px' : 'auto' }}>{it.title}</div>
                                                <div className='s-font' style={{ padding: '0 20px 20px 20px' }}>{it.description}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div ref={priceRef} className='price-table-container'>
                        {
                            width >= MED_COLLAPSE_WIDTH
                                ? <OurProgramTable data={data} />
                                : <OurProgramTableMobile data={data} />
                        }
                    </div>
                    <div className='highlights-container' style={{ backgroundColor: 'var(--main-light-darker)', marginTop: 0, paddingTop: '50px', paddingBottom: '150px' }}>
                        <div className='highlights-section'>
                            <div className='h-font' style={{ alignSelf: width <= 910 ? 'center' : 'unset' }}>Rules to follow</div>
                            <div className='highlights-set'>
                                {
                                    data?.rules?.length > 0 && data.rules.map((it, i) => {
                                        return (
                                            <div key={i} className='highlight-card' style={{ background: 'var(--main-light)', padding: '20px 0', border: 'none' }}>
                                                <div className='font-2' style={{ padding: '5px 20px 20px 20px', fontSize: '20px', height: !data.linkId && width > 530 ? '30px' : 'auto' }}>{it.title}</div>
                                                <div className='s-font' style={{ padding: '0 20px 20px 20px' }}>{it.description}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <button
                                className='pfj-btn dark'
                                style={{ alignSelf: 'center', width: '160px', marginTop: '30px' }}
                                onClick={() => window.open('http://help.propfirmjournal.com/')}>
                                {'Read All Rules'}
                            </button>
                        </div>
                    </div>
                </main>
            }

        </>
    )
}
