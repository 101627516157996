import Select from "react-select";

export default function DropdownLoading({placeholder}) {
    return (
        <Select
            isDisabled={true}
            isLoading={!placeholder}
            className={"pfj-dropdown"}
            classNamePrefix={"pfj-dropdown"}
            placeholder={placeholder ? placeholder : 'Loading...'} />
    )
}