import usePageInit from '../../hooks/usePageInit';
import './Legal.css'

export function RefundPolicy() {
    usePageInit('Refund Policy - Prop Firm Journal');
    return (
        <main>
            <div className="legal-template">
                <div className='legal-title legal-bold'>
                    REFUND POLICY
                </div>
                <div className='legal-text'>
                    <div className='legal-bold'>Refund After Successful Completion of a Challenge</div>
                    <div>
                        After you have successfully completed a Challenge with Prop Firm Journal and proceeded to the funded
                        phase, you will receive a refund of the initial sign-up fee with your first withdrawal. Your sign-up fee will
                        not be refunded until you have generated sufficient virtual profits that meet the withdrawal request
                        criteria.
                    </div>
                </div><br />
                <div className='legal-text'>
                    <div className='legal-bold'>Refund Policy</div>
                    <div>
                        Prop Firm Journal provides a full refund for any account before any trades are placed. There is no limit
                        to the refund period - meaning if you have not placed any trades on your account, you are eligible for
                        the refund. If you have not placed any trades and would like a refund, please contact Live Chat Support
                        so a member of our team can assist you. Refunds typically take 3-5 business days to be processed with
                        an additional 2-4 business days for the funds to enter your bank account or crypto wallet.
                    </div>
                </div><br />
                <div className='legal-text'>
                    <div className='legal-bold'>Dispute Policy</div>
                    <div>
                        Clients who improperly dispute charges or request charge backs with their bank will be permanently
                        banned from the Platform. Please contact Live Chat Support if you have any questions.
                    </div>
                </div><br />
                <div className='legal-text'>
                    <div className='legal-bold'>Acceptance of this Policy</div>
                    <div>
                        It is your responsibility to familiarize yourself with this refund policy. By placing an order for any of our
                        products, you indicate that you have read this refund policy and that you agree with and fully accept the
                        terms of this refund policy. If you do not agree with or fully accept the terms of this refund policy, we
                        ask that you do not place an order with us. Please contact us at <a href="mailto:support@propfirmjournal.com">support@propfirmjournal.com</a> should
                        you have any questions regarding our refund policy.<br />
                        We reserve the right to amend this refund policy from time to time. By keeping your account open, you
                        agree that you have knowledge of the amendments to this refund policy and fully understand and
                        accept the terms thereof. If you have any questions regarding any changes to this refund policy, please
                        contact us at <a href="mailto:support@propfirmjournal.com">support@propfirmjournal.com</a>.
                    </div>
                </div><br />
            </div>
        </main>
    )
}