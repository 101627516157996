import { useEffect } from 'react'
import { useState } from 'react'
import thousands from '../../hooks/thousands'
import Dropdown from '../Dropdown/Dropdown'
import './OurProgramTableMobile.css'

export default function OurProgramTableMobile({ data }) {
    const [selected, setSelected] = useState()
    const [selectedStep, setSelectedStep] = useState();
    const [stepIndex, setStepIndex] = useState();
    const [promise, setPromise] = useState();

    useEffect(() => {
        setSelected(null)
        setSelectedStep(null)
        setPromise(null)
        setStepIndex(null)

        setTimeout(() => {
            setSelected(data.sizes[0])
            setSelectedStep(data.steps[0])
            setStepIndex(1)
            setPromise(new Promise((resolve, _) => {
                const sizes = data?.sizes?.map(it => {
                    const value = `$${thousands(it.size?.toString())}`;
                    return { ...it, size: value };
                });

                resolve(sizes);
            }))
        })

    }, [data])

    const sizeChange = (e) => {
        setSelected(data.sizes.filter(it => it._id === e.value)[0]);
    }

    return (
        <div className='price-table-section-mobile' style={{ padding: '30px 0' }}>
            <div className='h-font' style={{ textAlign: 'center' }}>Account Size</div>
            <br />
            {
                promise && selected &&
                <Dropdown
                    promise={promise}
                    transform={['_id', 'size']}
                    defaultValue={selected?.size?._id}
                    onChange={(e) => sizeChange(e)} />
            }

            <br />
            <div className='price-table-size-label-set' style={{ gap: 0, justifyContent: 'center' }}>
                {
                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                        return (
                            <button
                                key={i}
                                style={{ width: 'auto' }}
                                className={'price-table-size-label' + (i + 1 === stepIndex ? ' selected' : '')}
                                onClick={() => { setSelectedStep(it); setStepIndex(i + 1) }}
                            >Step {i + 1}</button>
                        )
                    })
                }
            </div>
            <div className='price-table-size' style={{ marginRight: '0' }}>
                {
                    selected && selectedStep &&
                    <table style={{ width: '90vw' }}>
                        <thead>
                            <th></th>
                            <th>
                                <div className='price-table-header-cell'>
                                    <div className='h-font' style={{ fontSize: '22px' }}>Step {stepIndex}</div>
                                    <div style={{ fontFamily: 'Articulat Medium', fontSize: '14px' }}>{selectedStep.title}</div>
                                </div>
                            </th>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Trading Period</td>
                                <td>{selectedStep.tradingPeriod}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Minimum Trading Days</td>
                                <td>
                                    {selectedStep.minTradingDays === null ? 'X' : selectedStep.minTradingDays + ' Days'}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Trading Leverage</td>
                                <td>{data.leverage}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Daily Loss Type</td>
                                <td>{data.dailyLossType}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Maximum Daily Loss</td>
                                <td>
                                    ${thousands((selected.size * (selectedStep.maxDailyLoss / 100)).toFixed(0))} / {selectedStep.maxDailyLoss}%
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Maximum Overall Loss Type</td>
                                <td>{data.maxOverallLossType}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Maximum Overall Loss</td>
                                <td>
                                    ${thousands((selected.size * (selectedStep.maxOverallLoss / 100)).toFixed(0))} / {selectedStep.maxOverallLoss}%
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Profit Target</td>
                                <td>
                                    {
                                        selectedStep.profitTarget !== null &&
                                        <> ${thousands((selected.size * (selectedStep.profitTarget / 100)).toFixed(0))} / {selectedStep.profitTarget}%</>
                                    }
                                    {
                                        selectedStep.profitTarget == null &&
                                        <>X</>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Weekend Holding</td>
                                <td>
                                    {selectedStep.weekendHolding ? 'Yes' : 'No'}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>News Trading</td>
                                <td>
                                    {selectedStep.newsTrading ? 'Yes' : 'No'}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>First Withdrawal</td>
                                <td>
                                    {selectedStep.firstWithdrawal === null ? 'X' : selectedStep.firstWithdrawal + ' Days'}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Profit Split</td>
                                <td>
                                    {selectedStep.profitSplit === null ? 'X' : selectedStep.profitSplit}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6', borderBottomLeftRadius: '10px' }}>Refundable Fee</td>
                                <td style={{
                                    fontFamily: 'Articulat Medium',
                                    borderBottomRightRadius: '10px'
                                }}>
                                    {
                                        stepIndex === 1 &&
                                        <>${thousands(selected.firstStepFee.toString())}</>
                                    }
                                    {
                                        stepIndex > 1 &&
                                        <>{selectedStep.fee}</>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
            {
                selected &&
                <button onClick={() => selected.buyLink && window.open(selected.buyLink) } className='pfj-btn dark' style={{ alignSelf: 'center', width: '160px', marginTop: '30px' }}>
                    {'Buy ' + (selected.size / 1000).toFixed(0) + 'K Challenge'}
                </button>
            }
        </div>
    )
}
