import Icon from '../Icon/Icon'
import './HomeImpCard.css'

export default function HomeImpCard({ icon, title, description }) {
    return (
        <div className='important-item'>
            <div className='important-item-img'>
                <Icon name={icon} style={{ width: '50px', fill: 'black' }}/>
            </div>
            <div className='important-item-text'>
                <div className='font-2 important-item-title'>
                    {title}
                </div>
                <div className='s-font important-item-details'>
                    {description}
                </div>
            </div>
        </div>
    )
}