import './ProgramCard.css'

export default function ProgramCard({ title, description, link, opacity }) {
    return (
        <div className='program-card' style={{ background: opacity !== undefined ? `rgb(0 0 0 / ${opacity}%)` : '--var(main-dark)' }}>
            <div className='font-2 program-card-title'>{title}</div>
            <div className='font-1 program-card-description'>{description}</div>
            <button className='pfj-btn' onClick={() => window.open(link, '_self')}>Start Now</button>
        </div>
    )
}
