import { useNavigate } from 'react-router-dom';
import { MIN_COLLAPSE_WIDTH } from '../../../constants/constants';
import useWindowSize from '../../../hooks/useWindowSize';
import './HeadSection.css';

export default function HeadSection() {
    const navigate = useNavigate();
    const [width] = useWindowSize();

    return (
        <div className='home-header-container'>
            <div className='home-header'>
                {
                    width <= MIN_COLLAPSE_WIDTH &&
                    <button className='pfj-btn dark start-now-btn' style={{width: '110px'}} onClick={() => navigate('dashboard/explore')}>
                        Start Now
                    </button>
                }
                <img src="assets/too-many-pf.png" alt='Too many prop firms img' />
                <div className='text-panel'>
                    <div className='header h-font'>
                        Too many Prop Firms. We make it easy for you.
                    </div>
                    <div className='subheader font-1'>
                        Use our integrated dashboard to find the perfect program across +20 top Prop Firms.
                    </div>
                    {width > MIN_COLLAPSE_WIDTH &&
                        <button className='pfj-btn dark start-now-btn' style={{width: '110px'}} onClick={() => navigate('dashboard/explore')}>
                            Start Now
                        </button>
                    }
                </div>
            </div>
            <div className='zig-zag'></div>
        </div>
    )
}
