import { useState } from 'react';
import usePageInit from '../../../hooks/usePageInit';
import './ExplorePage.css';
import { DashboardCacheI } from '../../../caches/DashboardCache'
import Dropdown from '../../../components/Dropdown/Dropdown';
import Icon from '../../../components/Icon/Icon';
import starSelect from '../../../hooks/useStarSelect';
import LoaderMask from '../../../components/LoaderMask/LoaderMask';
import ProgramTable from './Tables/ProgramTable';
import GeneralChallengeTable from './Tables/GeneralChallengeTable';
import RestrictionsTable from './Tables/RestrictionsTable';
import PayoutsTable from './Tables/PayoutsTable';
import PriceTable from './Tables/PriceTable';
import ScalingProgramTable from './Tables/ScalingProgramTable';
import { useLocation } from 'react-router-dom';
import TraderSupportTable from './Tables/TraderSupportTable';
import WebsiteTable from './Tables/WebsiteTable';
import SocialTable from './Tables/SocialTable';
import { LayoutStoreInstance } from '../../../stores/LayoutStore';
import useWindowSize from '../../../hooks/useWindowSize';
import ExploreMobileLayout from './ExploreMobileLayout/ExploreMobileLayout';
import TrustpilotTable from './Tables/TrustpilotTable/TrustpilotTable';
import AffiliateProgramTable from './Tables/AffiliateProgramTable';
import CeoSection from './CeoSection/CeoSection';
import { BIG_COLLAPSE_WIDTH, MED_COLLAPSE_WIDTH } from '../../../constants/constants';
import { useEffect } from 'react';

export default function ExplorePage() {
    usePageInit('Explore - Prop Firm Journal');

    const [width] = useWindowSize();
    const [companies, setCompanies] = useState();
    const [programs, setPrograms] = useState();
    const [companiesLoaded, setCompaniesLoaded] = useState(false);
    const [programsLoaded, setProgramsLoaded] = useState(false);
    const [programsLoaded2, setProgramsLoaded2] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [companyStarFilled, setCompanyStarFilled] = useState(false);
    const [maxPropFirms, setMaxPropFirms] = useState(false);
    const [explore, setExplore] = useState(null);
    const [firstExploreLoaded, setFirstExploreLoaded] = useState(false);

    const [defaultCompanyId, setDefaultCompanyId] = useState();
    const [defaultProgramId, setDefaultProgramId] = useState();

    const [platforms, setPlatforms] = useState();

    const location = useLocation();

    useEffect(() => {
        const defCompanyId = location.state?.companyId;
        const defProgramId = location.state?.programId;
        
        DashboardCacheI.getPlatforms().then(platforms => {
            setPlatforms(platforms);
        });

        DashboardCacheI.getCompanies().then(data => {
            if (data) {
                setCompanies(data);
                
                let companyToSelect = data.find(it => it.exploreDefault === true) ?? data[0];
                let companyToSelectId = companyToSelect?._id;
                companyToSelectId && setDefaultCompanyId(companyToSelectId);
                if (defCompanyId) {
                    companyToSelect = data.find(it => it._id === defCompanyId) ?? data[0];
                    companyToSelectId = companyToSelect?._id;
                    setDefaultCompanyId(defCompanyId);
                }

                const selected = {label: companyToSelect.companyName, value: companyToSelect._id};
                setSelectedCompany(selected);
                starSelect('pfjSelectedCompanies', selected, setCompanyStarFilled, true, undefined, setMaxPropFirms);
                
                setCompaniesLoaded(true);
                DashboardCacheI.getPrograms(companyToSelectId).then(data2 => {
                    if (data2) {
                        if (defProgramId) {
                            setDefaultProgramId(defProgramId);
                        }
                        setPrograms(data2);
                        setProgramsLoaded(true);
                        setProgramsLoaded2(true);
                        setTimeout(() => window.scrollTo(0, 0));
                    }
                }).catch(err => console.log(err))
            }
        }).catch(err => console.log(err));
    }, []);

    const companyChange = e => {
        setProgramsLoaded2(false);
        DashboardCacheI.getPrograms(e.value).then(data => {
            if (data) {
                setPrograms(data);
            } else {
                setPrograms([]);
            }
            location.state = null;
            setSelectedCompany(e);
            starSelect('pfjSelectedCompanies', e, setCompanyStarFilled, true, undefined, setMaxPropFirms);
            setProgramsLoaded2(true);
        });
    }

    const programChange = e => {
        setSelectedProgram(e);
        e && DashboardCacheI.getExplore(e.value).then(data => {
            if (data) {
                const exploreData = data[0];
                setExplore(exploreData);
                firstExploreLoaded || setFirstExploreLoaded(true);
            }
        });
    }

    return (
        <>
            {
                width < MED_COLLAPSE_WIDTH &&
                <ExploreMobileLayout exploreData={explore} />
            }
            {
                width >= MED_COLLAPSE_WIDTH &&
                <div className='fade-in'>
                    {(companiesLoaded && programsLoaded && firstExploreLoaded) || <div className='explore-loader'><LoaderMask /></div>}
                    <div className='explore-container' style={{ height: (companiesLoaded && programsLoaded && firstExploreLoaded) ? 'unset' : '2000px' }}>
                        {
                            width >= BIG_COLLAPSE_WIDTH &&
                            <div className='h-font'>Explore</div>
                        }
                        {
                            width < BIG_COLLAPSE_WIDTH &&
                            <div style={{
                                'display': 'flex',
                                'alignItems': 'center',
                                'gap': '20px',
                                'justifyContent': 'space-between'
                            }}>
                                <div className='h-font'>Explore</div>
                                <div className='company-logo-container' onClick={() => explore?.propFirm?.affiliateLink && window.open(explore?.propFirm?.affiliateLink)} style={{ 'backgroundColor': explore?.propFirm?.color2, cursor: 'pointer' }}>
                                    {
                                        explore?.propFirm?.companyLogoUrl && <div className='company-logo' style={{ backgroundImage: `url('${explore.propFirm.companyLogoUrl}')` }}></div>
                                    }
                                </div>
                            </div>
                        }

                        <div className='dropdown-set'>
                            <div className='dropdown'>
                                <div className='dropdown-container'>
                                        {
                                            companiesLoaded && companies.length > 0 &&
                                            <Dropdown
                                                promise={companies}
                                                placeholder={'Select the Company'}
                                                transform={['_id', 'companyName']}
                                                defaultValue={defaultCompanyId}
                                                dontFireChangeOnInit={true}
                                                onChange={companyChange}/>
                                        }
                                </div>
                                {
                                    companiesLoaded &&
                                    <div className={'selection-icon ' + (maxPropFirms && !companyStarFilled ? 'max star-tooltip' : '')} onClick={() => starSelect('pfjSelectedCompanies', selectedCompany, setCompanyStarFilled, false, LayoutStoreInstance.setSelectedCompanies, setMaxPropFirms)}>
                                        <Icon name={'star'} style={{ width: '21px', fill: companyStarFilled ? 'inherit' : 'none', stroke: 'black' }} />
                                        {
                                            maxPropFirms && !companyStarFilled &&
                                            <div className='star-tooltip-text'>{'Maximum 10 Favorites Firms Allowed To Select'}</div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className='dropdown'>
                                <div className='dropdown-container'>
                                    {
                                        programsLoaded2 && programs.length > 0 &&
                                            <Dropdown
                                                promise={programs}
                                                placeholder={'Select the Program'}
                                                transform={[{ group: 'programType', map: ['_id', 'programName'] }]}
                                                defaultValue={defaultProgramId}
                                                onChange={programChange} />
                                    }
                                </div>
                            </div>
                            {
                                width >= BIG_COLLAPSE_WIDTH &&
                                <div className='company-logo-container' onClick={() => explore?.propFirm?.affiliateLink && window.open(explore?.propFirm?.affiliateLink)} style={{ 'backgroundColor': explore?.propFirm?.color2, cursor: 'pointer' }}>
                                    {
                                        explore?.propFirm?.companyLogoUrl && <div className='company-logo' style={{ backgroundImage: `url('${explore.propFirm.companyLogoUrl}')` }}></div>
                                    }
                                </div>
                            }
                        </div>
                        {
                            width >= BIG_COLLAPSE_WIDTH &&
                            <>
                                <div className='tables-container'>
                                    <div className='tables-set'>
                                        {explore && platforms && <ProgramTable selectedProgram={selectedProgram} exploreData={explore} platforms={platforms} />}
                                        {explore && <PayoutsTable exploreData={explore} />}
                                    </div>
                                    <div className='tables-set'>
                                        {explore && <GeneralChallengeTable exploreData={explore} />}
                                        {explore && <PriceTable exploreData={explore} />}
                                    </div>
                                    <div className='tables-set'>
                                        {explore && <RestrictionsTable exploreData={explore} />}
                                        {explore && <ScalingProgramTable exploreData={explore} />}
                                    </div>
                                </div>
                            </>

                        }

                        {
                            width >= MED_COLLAPSE_WIDTH && width < BIG_COLLAPSE_WIDTH &&
                            <>
                                <div className='tables-container laptop'>
                                    <div className='tables-set'>
                                        {explore && platforms && <ProgramTable selectedProgram={selectedProgram} exploreData={explore} platforms={platforms} />}
                                        {explore && <PayoutsTable exploreData={explore} />}
                                        {explore && <RestrictionsTable exploreData={explore} />}
                                    </div>
                                    <div className='tables-set'>
                                        {explore && <GeneralChallengeTable exploreData={explore} />}
                                        {explore && <PriceTable exploreData={explore} />}
                                        {explore && <ScalingProgramTable exploreData={explore} />}
                                    </div>
                                </div>
                            </>

                        }


                        {
                            selectedCompany && explore && explore.propFirm.affiliateLink &&
                            <div className='banner'>
                                <div className='logo-container' style={{ 'backgroundColor': explore.propFirm.color2 }}>
                                    {
                                        explore.propFirm.companyLogoUrl && <div className='company-logo' style={{ backgroundImage: `url('${explore.propFirm.companyLogoUrl}')` }}></div>
                                    }
                                </div>
                                <div className='text-container' style={{ 'backgroundColor': explore.propFirm.color1, 'color': explore.propFirm.textColor }}
                                    onClick={() => explore.propFirm.affiliateLink && window.open(explore.propFirm.affiliateLink, '_blank')}>
                                    {`Click here to start your challenge with ${selectedCompany.label}`}
                                    {
                                        explore.propFirm.promo &&
                                        <span className='promo'>{explore.propFirm.promo}</span>
                                    }
                                </div>
                            </div>
                        }

                        {
                            <div style={{ marginBottom: '40px' }}>
                                {explore?.affiliateProgram && <AffiliateProgramTable affiliateProgram={explore.affiliateProgram} />}
                            </div>
                        }

                        {
                            width >= BIG_COLLAPSE_WIDTH &&
                            <div className='trader-section'>
                                <div className='h-font'>{explore?.propFirm?.companyName} Information</div>
                                <div className='trader-set'>
                                    {explore && <TraderSupportTable companyData={explore.propFirm} />}
                                    {explore && <SocialTable companyData={explore.propFirm} />}
                                    {explore && <WebsiteTable companyData={explore.propFirm} />}
                                    {
                                        explore &&
                                        <TrustpilotTable
                                            trustPilotLink={explore.propFirm.trustPilotLink}
                                            trustPilotStars={explore.propFirm.trustPilotStars} />
                                    }
                                </div>
                            </div>
                        }

                        {
                            width < BIG_COLLAPSE_WIDTH &&
                            <div className='trader-section laptop'>
                                <div className='h-font'>{explore?.propFirm?.companyName} Information</div>
                                <div className='trader-set laptop'>
                                    <div className='trader-set-item'>
                                        {explore && <TraderSupportTable companyData={explore.propFirm} />}
                                        {explore && <SocialTable companyData={explore.propFirm} />}
                                    </div>
                                    <div className='trader-set-item'>
                                        {explore && <WebsiteTable companyData={explore.propFirm} />}
                                        {
                                            explore &&
                                            <TrustpilotTable
                                                trustPilotLink={explore.propFirm.trustPilotLink}
                                                trustPilotStars={explore.propFirm.trustPilotStars} />
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            explore && <CeoSection propFirmData={explore.propFirm} />
                        }

                    </div>
                </div>
            }
        </>
    )
}