import { observable, makeObservable, action } from 'mobx';

class LayoutStore {
    subscribed = false;
    footerStartPanelOpened = false;
    selectedCompanies = [];
    selectedPrograms = [];

    constructor() {
        makeObservable(this, {
            subscribed: observable,
            setSubscribed: action,
            footerStartPanelOpened: observable,
            setFooterStartPanel: action,
            selectedCompanies: observable,
            setSelectedCompanies: action,
            selectedPrograms: observable,
            setSelectedPrograms: action
        });
    }

    setSubscribed = () => this.subscribed = true;
    setFooterStartPanel = state => this.footerStartPanelOpened = state;
    setSelectedCompanies = state => this.selectedCompanies = state;
    setSelectedPrograms = state => this.selectedPrograms = state;
}

export const LayoutStoreInstance = new LayoutStore();
