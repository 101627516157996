import './MetricsSection.css';

export default function MetricsSection({ metrics }) {
    return (
        metrics &&
        <div className='metrics-container'>
            <div className='metrics'>
                <div className='metric-block'>
                    <div className='number font-2'>+{metrics.programsCount}</div>
                    <div className='description font-1'>Analyzed Programs</div>
                </div>
                <div className='metric-block'>
                    <div className='number font-2'>+{metrics.comparedPropFirms}</div>
                    <div className='description font-1'>Compared Prop Firms</div>
                </div>
                <div className='metric-block'>
                    <div className='number font-2'>+{metrics.keyMetrics}</div>
                    <div className='description font-1'>Key Metrics</div>
                </div>
                <div className='metric-block'>
                    <div className='number font-2'>{metrics.topFirmSelected}</div>
                    <div className='description font-1'>Top Firms Selected</div>
                </div>
            </div>
        </div>
    )
}