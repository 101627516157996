import { observer } from 'mobx-react-lite'
import { NavLink, useNavigate } from 'react-router-dom';
import './Footer.css';
import Icon from '../../../components/Icon/Icon';
import { LayoutStoreInstance } from '../../../stores/LayoutStore';
import useWindowSize from '../../../hooks/useWindowSize';
import { MIN_COLLAPSE_WIDTH } from '../../../constants/constants';
import { useState } from 'react';

export const Footer = observer(() => {
    const navigate = useNavigate();
    const [width] = useWindowSize()
    const [disclaimer, setDisclaimer] = useState(false);

    return (
        <>
            {
                disclaimer &&
                <div className='disclaimer-modal fade-in'>
                    <div className='disclaimer-modal-container'>
                        <div className='disclaimer-close-btn' onClick={() => setDisclaimer(false)}>
                            <Icon name={'cross'} style={{ width: '20px' }} />
                        </div>
                        <div className='diclaimer-modal-title'>Funded Accounts Disclaimer</div>
                        <div className='disclaimer-modal-text'>
                            Prop Firm Journal funded accounts (the “Funded Accounts”) are not live trading accounts; the
                            Funded Accounts are fully simulated accounts utilizing real market quotes from liquidity
                            providers.<br /><br />
                            The Traders featured are representative only and include selected traders who consent to public
                            disclosure of their personal information. Trader performance quoted represents past performance
                            in a simulated environment and does not guarantee current or future performance. Further,
                            financial instrument trading involves substantial risk of loss and is not suitable for every investor.
                            Trading activities referenced by or undertaken utilizing Prop Firm Journal may not be suitable for
                            all investors.
                        </div>
                    </div>
                </div>
            }
            <footer>
                {
                    LayoutStoreInstance.footerStartPanelOpened &&
                    <div className="find-prop-firm-panel">
                        <div className="mask">
                            <div className="use-dashboard-text">Use our dashboard</div>
                            <div className="find-prop-firm-text">Find Your Prop Firm</div>
                            <button className="pfj-btn" style={{width: '110px'}} onClick={() => navigate('/dashboard/explore')}>Start Now</button>
                        </div>
                    </div>
                }
                <div className="footer-info-panel">
                    <div className="f-logo" onClick={() => window.open('/', '_self')} style={{ cursor: 'pointer' }}>
                        <Icon name={'short-logo'} style={{
                            width: '70px',
                            rectFill: 'white',
                            rectFont: 'black'
                        }} />
                        {width > MIN_COLLAPSE_WIDTH && <div className="f-logo-below font-1">@ { new Date().getFullYear() + '' }, PROP FIRM JOURNAL</div>}
                    </div>
                    <div className="f-options">
                        <div className="font-2 f-opt-title">Your Trusted Source</div>
                        <div className="font-1 nav-options">
                            <nav>
                                <ul>
                                    <li><NavLink to="/about">About Us</NavLink></li>
                                    <li><NavLink to="/programs">Our Challanges</NavLink></li>
                                    <li><NavLink to="/contact">Contact</NavLink></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="f-options">
                        <div className="font-2 f-opt-title">Explore Statistics</div>
                        <div className="font-1 nav-options">
                            <nav>
                                <ul>
                                    <li><NavLink to="/dashboard/explore">Explore</NavLink></li>
                                    <li><NavLink to="/dashboard/compare">Compare</NavLink></li>
                                    <li><NavLink to="/dashboard/rankings">Ranking</NavLink></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="f-options social">
                        <div className="font-2 f-opt-title">Follow Us</div>
                        <div className="f-social-networks">
                            <a href="https://www.instagram.com/propfirmjournal" target="_blank" rel="noreferrer" title='Instagram'>
                                <Icon name={'instagram'} style={{ width: '32px', fill: 'white' }} />
                            </a>
                            <a href="https://twitter.com/PropFirmJournal" target="_blank" rel="noreferrer" title='Twitter'>
                                <Icon name={'twitter2'} style={{ width: '34px', fill: 'white' }} />
                            </a>
                            <a href="https://www.youtube.com/channel/UC4Sh58FJal8JAyvAZtcIgbg" target="_blank" rel="noreferrer" title='Youtube'>
                                <Icon name={'youtube'} style={{ width: '32px', fill: 'white' }} />
                            </a>
                            <a href="https://www.tiktok.com/@propfirmjournal?lang=en" target="_blank" rel="noreferrer" title='TikTok' style={{ marginTop: '4px' }}>
                                <Icon name={'tikTok'} style={{ width: '24px', fill: 'white' }} />
                            </a>
                        </div>
                    </div>
                    <div className="f-options">
                        <div className="font-2 f-opt-title">Legal</div>
                        <div className="font-1 nav-options">
                            <nav>
                                <ul>
                                    <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                    <li><NavLink to="/refund-policy">Refund Policy</NavLink></li>
                                    <li><NavLink to="/terms-of-use">Terms of Use</NavLink></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    {width <= MIN_COLLAPSE_WIDTH && <div className="f-logo-below font-1" style={{ alignSelf: 'center', marginTop: '20px' }}>@ { new Date().getFullYear() + '' }, PROP FIRM JOURNAL</div>}
                </div>

                <div style={{ textAlign: 'justify', backgroundColor: 'var(--main-dark)', color: 'var(--main-light)', fontSize: '10px', fontFamily: 'Articulat Light', padding: width < 1270 ? '15px' : '10px 15%', letterSpacing: 2 }}>
                    All content published and distributed by Prop Firm Journal, LLC and its affiliates (collectively,
                    the “Company”) is to be treated as general information only. None of the information provided by
                    the Company or contained herein is intended as investment advice, an offer or solicitation of an
                    offer to buy or sell, or a recommendation, endorsement, or sponsorship of, any security, company,
                    or fund.<br />
                    Use of information contained on the Company's website is at your own risk and the Company
                    assumes no responsibility or liability for any use or misuse of such information. Nothing contained
                    herein is an offer or solicitation of an offer to buy or sell stock, futures, options, or forex. Past
                    performance is not necessarily indicative of future results.<br />
                    Hypothetical or simulated performance results have certain limitations. Unlike an actual
                    performance record, simulated results do not represent actual trading. In addition, because the
                    virtual trades have not been executed in reality, results may have under- or over-compensated for
                    the impact of certain market factors, such as lack of liquidity. Simulated trading programs, in
                    general, are designed with the benefit of hindsight and as such may be subject to hindsight biases.
                    No representation is hereby being made that any account will or is likely to achieve profit or losses
                    similar to those shown.<br />
                    All purchases made through the Prop Firm Journal website are used for training and certification
                    purposes only. Upon purchase of a challenge, a demo account will be provided to the customer,
                    upon which the customer may perform virtual trading activities, but not live trading. Further, the
                    Company does not use any proceeds of any purchases, whether the payment is made through a
                    cryptocurrency or a credit card, for trading activities. No live trading of any kind whatsoever,
                    including but not limited to stock, futures, options, and forex, is performed by the Company or
                    otherwise on the website.
                </div>
                <div style={{ backgroundColor: 'var(--main-dark)', padding: width < 1270 ? '15px' : '10px 15%', display: 'flex', 'justifyContent': 'flex-end' }}>
                    <button onClick={() => setDisclaimer(true)} className='pfj-btn' style={{ width: '230px' }}>Funded Accounts Disclaimer</button>
                </div>
            </footer>
        </>

    )
})


