import { useEffect } from 'react'
import { useState } from 'react'
import YouTube from 'react-youtube';
import { NewsCacheI } from '../../caches/NewsCache'
import LoaderMask from '../../components/LoaderMask/LoaderMask'
import usePageInit from '../../hooks/usePageInit'
import './MediaPage.css'

export default function MediaPage() {
    usePageInit('Media - Prop Firm Journal')

    const [selectedTab, setSelectedTab] = useState(['propFirmReviews', 'Prop Firm Reviews']);
    const [media, setMedia] = useState();
    const [newMedia, setNewMedia] = useState();
    const [selectedMedia, setSelectedMedia] = useState();
    const [loaded, setLoaded] = useState(true);
    const [signUpLink, setSignUpLink] = useState();

    useEffect(() => {
        loadMedia(['propFirmReviews', ''], true);
    }, [])

    const loadMedia = (category, firstLoad, loadMore) => {
        setLoaded(false);
        const lastDate = firstLoad || !media || media.length === 0 || !loadMore
            ? new Date().toISOString()
            : media[media.length - 1]?.date;

        setSelectedTab(category);
        loadMore || setSelectedMedia(null);
        loadMore || setMedia(null);
        NewsCacheI.getMedia(category[0], lastDate).then(media => {
            const data = media?.media;
            firstLoad && setSignUpLink(media?.signUpLink);
            if (data) {
                const newMedia = media?.length > 0 && loadMore
                    ? [...media, ...data]
                    : data;
                setMedia(newMedia);
                setNewMedia(data);
                loadMore || setSelectedMedia(newMedia[0]);
                setLoaded(true);
            }
        }).catch(err => {
            setLoaded(true);
            console.error(err);
        })
    }

    const getPreview = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url?.match(regExp);
        const videoId = match[7];
        if (videoId.length === 11) {
            return { id: videoId, preview: `//img.youtube.com/vi/${videoId}/0.jpg` };
        }

        return { id: '', preview: '' };
    }

    return (
        <main className='media-page'>
            <div className='media-header-container' style={{ 'backgroundImage': 'url("assets/media.png")' }}>
                <div className='news-header-outer'>
                    <div className='news-header'>
                        <div className='h-font'>Media</div>
                        <div className='s-font'>Interviews, Podcasts and more media</div>
                    </div>
                </div>
            </div>
            <div className='zig-zag'></div>
            <div className='media-page-container'>
                <div className='media-page-tab-section'>
                    <div className='media-tab-container'>
                        {
                            [
                                ['propFirmReviews', 'Prop Firm Reviews'],
                                ['challengeReviews', 'Challenge Reviews'],
                                ['traderInterviews', 'Trader Interviews'],
                                ['propFirmNews', 'Prop Firm News'],
                                ['pfjDetective', 'PFJ Detective']
                            ].map((it, i) => {
                                return (
                                    <div key={i}
                                        onClick={() => it[0] !== selectedTab[0] && loadMedia(it)}
                                        className={'font-2 media-tab' + (it[0] === selectedTab[0] ? ' active' : '')}>
                                        {it[1]}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='media-main-container'>
                        <div className='media-main-video'>
                            {
                                !loaded &&
                                <LoaderMask />
                            }
                            {
                                selectedMedia
                                    ? loaded && 
                                        <YouTube
                                            videoId={getPreview(selectedMedia?.videoUrl).id}
                                            iframeClassName='media-iframe'
                                            style={{ width: '100%', height: '100%'}}
                                            opts={{ playerVars: { autoplay: 1 } }} />
                                    : loaded && <div className='s-font'>No Media</div>
                            }

                        </div>
                        <div className='media-main-list'>
                            {
                                !loaded &&
                                <LoaderMask />
                            }
                            {
                                loaded &&
                                (media?.length > 0 ?
                                    <div className='media-list'>
                                        {
                                            media.map((it, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        onClick={() => setSelectedMedia(it)}
                                                        className={'media-list-item' + (selectedMedia?._id === it._id ? ' active' : '')}>
                                                        <div className='preview'>
                                                            <img src={getPreview(it.videoUrl ?? '').preview} width="100" height="60" />
                                                        </div>
                                                        <div className='s-font'>
                                                            {it.title}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            newMedia?.length > 0 ?
                                            newMedia.length === 100 &&
                                            <div
                                                className='read-more-btn pfj-btn dark'
                                                style={{ 'width': '100px', 'alignSelf': 'center', 'textAlign': 'center', 'margin': '10px' }}
                                                onClick={() => loadMedia(selectedTab, false, true)}>More Media
                                            </div> :
                                            <div className='s-font' style={{'alignSelf': 'center'}}>
                                                That was all media for now. Thanks for watching!
                                            </div>
                                        }
                                    </div>
                                    : <div className='s-font'>No Media</div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='media-page-container'>
                <div className='sign-up-container'>
                    <div className='font-2'>Want to be interviewed by PFJ?</div>
                    <button 
                        onClick={() => signUpLink && window.open(signUpLink)}
                        className='pfj-btn dark' style={{width: '140px'}}>Sign Up here</button>
                </div>
            </div>
        </main>
    )
}