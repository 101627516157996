import { useEffect } from 'react';
import { useState } from 'react';
import { DashboardCacheI } from '../../../../caches/DashboardCache';
import Icon from '../../../../components/Icon/Icon';
import Paginator from '../../../../components/Paginator/Paginator';
import useWindowSize from '../../../../hooks/useWindowSize';
import { scrapeRankings } from '../../../../requests/dashboard.requests';
import './Rankings2Table.css';
import thousands from "../../../../hooks/thousands";
import { MED_COLLAPSE_WIDTH, MIN_COLLAPSE_WIDTH } from '../../../../constants/constants';

export default function Rankings2Table() {
    const [data, setData] = useState(null);
    const [curPage, setCurPage] = useState(0);
    const [curSort, setCurSort] = useState('profit');
    const [curSortDir, setCurSortDir] = useState('desc');
    const [width] = useWindowSize();

    useEffect(() => {
        scrapeRankings().then();
        onLoad(0, 'profit');
        // eslint-disable-next-line
    }, []);

    const onLoad = (page, sort, dir) => {
        curPage !== page && setCurPage(page);
        setCurSort(sort);
        dir && setCurSortDir(dir);
        DashboardCacheI.getRankingsScraped(page, sort, dir ?? curSortDir).then(data => {
            if (data) {
                setData(data);
            }
        }).catch(err => console.error(err));
    };

    return (
        <div className='ranking-table'>
            <div className='h-font' style={{ paddingLeft: width < MED_COLLAPSE_WIDTH && '5%' }}>Leaderboard</div>
            <table style={{ 'height': 'unset' }}>
                <thead className='h-font'>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        {
                            width > 780 &&
                            <th>Account Size</th>
                        }
                        <th>
                            <div
                                style={{ 'display': 'flex', 'gap': '10px', 'alignItems': 'center', 'justifyContent': 'center', 'cursor': 'pointer' }}
                                onClick={() => onLoad(curPage, 'profit', curSort === 'profit' ? (curSortDir === 'asc' ? 'desc' : 'asc') : 'desc')}>
                                <div><u>Profit</u></div>
                                {
                                    curSort === 'profit' &&
                                    <div>
                                        <Icon name={curSortDir} style={{ width: width < MIN_COLLAPSE_WIDTH ? '16px' : '24px', fill: 'white' }} />
                                    </div>
                                }
                            </div>
                        </th>
                        <th>
                            <div
                                style={{ 'display': 'flex', 'gap': '10px', 'alignItems': 'center', 'justifyContent': 'center', 'cursor': 'pointer' }}
                                onClick={() => onLoad(curPage, 'gain', curSort === 'gain' ? (curSortDir === 'asc' ? 'desc' : 'asc') : 'desc')}>
                                <div><u>Gain</u></div>
                                {
                                    curSort === 'gain' &&
                                    <div>
                                        <Icon name={curSortDir} style={{ width: width < MIN_COLLAPSE_WIDTH ? '16px' : '24px', fill: 'white' }} />
                                    </div>
                                }
                            </div>
                        </th>
                        <th>
                            Prop Firm
                        </th>
                    </tr>
                </thead>
                {
                    data?.items &&
                    <tbody>
                        {
                            data.items.map((it, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ borderRadius: data.items.length - 1 === i && '0 0 0 10px' }}>
                                            <div style={{ 'display': 'flex', 'gap': '10px', 'alignItems': 'center', 'justifyContent': 'center' }}>
                                                {
                                                    width >= MIN_COLLAPSE_WIDTH &&
                                                    <div>{((curPage + 1) * 10 - 9 + i)}.</div>
                                                }
                                                {
                                                    width < MIN_COLLAPSE_WIDTH && curPage !== 0 &&
                                                    <div>{((curPage + 1) * 10 - 9 + i)}</div>
                                                }

                                                <div>
                                                    {
                                                        i >= 0 && i < 3 && curPage === 0 &&
                                                        <Icon name={'cup'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: i === 0 ? 'gold' : i === 1 ? 'silver' : 'brown' }} />
                                                    }
                                                    {
                                                        i >= 3 && i < 11 && curPage === 0 &&
                                                        <Icon name={'medal'} style={{ width: width > MIN_COLLAPSE_WIDTH ? '24px' : '20px', fill: i === 3 ? 'gold' : i === 4 ? 'silver' : 'brown' }} />
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ 'maxWidth': width < MIN_COLLAPSE_WIDTH ? '60px' : '100px', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap', 'overflow': 'hidden' }}>
                                            {
                                                width <= 780 ?
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' }}>
                                                        <div style={{ maxWidth: '60px', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap', 'overflow': 'hidden' }}>{it.name}</div>
                                                        <div className='font-1' style={{ fontSize: '10px' }}> {it.size !== undefined ? '$' + thousands(it.size?.toFixed(0)) : 'N/A'}</div>
                                                    </div> : it.name
                                            }
                                        </td>
                                        {
                                            width > 780 &&
                                            <td>
                                                {it.size !== undefined ? '$' + thousands(it.size?.toFixed(0)) : 'N/A'}
                                            </td>
                                        }
                                        <td>
                                            ${thousands(it.profit?.toFixed(2))}
                                        </td>
                                        <td>
                                            {it.gain?.toFixed(2)}%
                                        </td>
                                        <td style={{ 'maxWidth': '170px', borderRadius: data.items.length - 1 === i && '0 0 10px 0' }}>
                                            <div style={{ 'display': 'flex', 'gap': '10px', 'alignItems': 'center', 'justifyContent': width < MIN_COLLAPSE_WIDTH ? 'center' : 'space-between', 'padding': '0 20px' }}>
                                                <div>{it.companyName}</div>
                                                {
                                                    width > MED_COLLAPSE_WIDTH &&
                                                    <div className="ranking-company-logo-container" style={{ 'backgroundColor': it.color2 ? it.color2 : 'black' }}>
                                                        <div className="ranking-company-logo" style={{ backgroundImage: `url("${it.companyLogoUrl}")` }}></div>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                }
                {
                    !data?.items &&
                    <tbody>
                        {
                            [...Array(10).keys()].map((it) => {
                                return (
                                    <tr key={it}>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                }
            </table>
            <div style={{ 'alignSelf': width >= MED_COLLAPSE_WIDTH ? 'flex-end' : 'center' }}>
                {
                    data && data.total > 1 &&
                    <Paginator
                        totalPages={data.total}
                        pageSetNum={width >= MIN_COLLAPSE_WIDTH ? 5 : 3}
                        pageChange={p => onLoad(p, curSort)} />
                }
            </div>
        </div>
    )
}