import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";
import thousands from "../../../../hooks/thousands";
import thousandsShort from "../../../../hooks/thousandsShort";

export default function PriceTable({ exploreData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        const headers = [{ name: 'Account Sizes' }];
        const prices = [{ value: 'Price' }];
        const pricesPerDrawdown = [{ value: 'Price per drawdown' }];
        const maxLossValue = parseFloat(exploreData.maxLossPer === null ? 0 : exploreData.maxLossPer) / 100;

        exploreData.prices.forEach(price => {
            const size = thousandsShort(price.accountSize);
            headers.push({ name: '$' + size });
            prices.push({ value: '$' + thousands(String(price.accountPrice)) });

            const pricePerDrawdown = price.accountPrice / (price.accountSize * maxLossValue);
            pricesPerDrawdown.push({ value: '$' + pricePerDrawdown.toFixed(3) });
        });

        const data = [{
            columnHeaders: headers,
            horScrolling: true,
            rows: [
                { cells: prices },
                { cells: pricesPerDrawdown }
            ]
        }];

        setTable(data);

    }, [exploreData])

    return (
        <div className="price-table">
            <DataGrid
                titleSettings={{ text: 'Price' }}
                tableSettings={{ columnSeparator: true }}
                dataSets={table} />
        </div>
    )
}