import NewsCard from '../../../components/NewsCard/NewsCard';
import { MIN_COLLAPSE_WIDTH } from '../../../constants/constants';
import key from '../../../hooks/key';
import useWindowSize from '../../../hooks/useWindowSize';
import './NewsSection.css';

export default function NewsSection({ news }) {
    const [width] = useWindowSize();
    return (
        news && news.length > 0 &&
        <div className='news-container'>
            <div className='news'>
                <div className='text-label' style={{ width: '130px' }}>Prop Firm News</div>
                <div className='h-font'>Stay up to date</div>
                <div className='news-set'>
                    {
                        news.map((it) => (<NewsCard key={key()} img={it.img} title={it.title} date={it.date} link={`news/${it._id}`} />))
                    }
                    {
                        news.length < 3 && width > MIN_COLLAPSE_WIDTH &&
                        [...Array(3 - news.length).keys()].map(() => (<NewsCard key={key()} />))
                    }
                </div>
            </div>
        </div>
    )
}