import { useEffect } from 'react';
import { useState } from 'react';
import { DashboardCacheI } from '../../../caches/DashboardCache';
import Dropdown from '../../../components/Dropdown/Dropdown';
import thousands from '../../../hooks/thousands';
import thousandsShort from '../../../hooks/thousandsShort';
import { DataGrid } from '../../../components/DataGrid/DataGrid';
import Paginator from '../../../components/Paginator/Paginator';
import useWindowSize from '../../../hooks/useWindowSize';
import './CompareByPricePage.css';
import { MIN_COLLAPSE_WIDTH } from '../../../constants/constants';
import usePageInit from '../../../hooks/usePageInit';

export default function CompareByPricePage() {
    
    usePageInit('Compare By Price - Prop Firm Journal');

    const [sizes, setSizes] = useState();
    const [selectedSize, setSelectedSize] = useState();
    const [tableData, setTableData] = useState();
    const [chartData, setChartData] = useState();
    const [total1, setTotal1] = useState();
    const [total2, setTotal2] = useState();
    const [page1, setPage1] = useState(0);
    const [page2, setPage2] = useState(0);
    const [sortObj, setSortObj] = useState(null);
    const [windowSize] = useWindowSize();

    useEffect(() => {
        DashboardCacheI.getSizes().then(data => {
            if (data) {
                const forSelect = data.map(it => ({ value: it, label: '$' + thousands(it.toString()) }));
                setSizes(new Promise((resolve) => resolve(forSelect)));
                setSelectedSize(forSelect[0]);
            }
        }).catch(err => console.error(err));
    }, []);

    const selectSize = (curSize, curPage, curSort) => {
        setPage2(0);

        curSize === null || curSize === undefined || setSelectedSize(curSize);
        curPage === null || curPage === undefined || setPage1(curPage);
        curSort === null || curSort === undefined || setSortObj(curSort);

        const _curSize = curSize === null || curSize === undefined ? selectedSize.value : curSize.value;
        const _curPage = curPage === null || curPage === undefined ? page1 : curPage;
        const _curSort = curSort === null || curSort === undefined ? sortObj : curSort;

        DashboardCacheI.getProgramsBySize(_curPage, _curSize, _curSort?.by ?? 'none', _curSort?.dir ?? 'none').then(data => {
            setTotal1(data?.total ?? 0);
            if (data?.items) {
                const rows = [];
                data.items.forEach(row => {
                    const phases = row.phases;
                    let profitTarget = 'N/A';
                    if (!(phases === undefined || phases === null || phases?.length === 0)) {
                        profitTarget = "";
                        phases.forEach(phase => {
                            profitTarget += `Phase ${phase.number}: ${phase.target}[[break]]`;
                        });
                    }

                    rows.push({
                        cells: [
                            { value: windowSize > MIN_COLLAPSE_WIDTH ? undefined : row.companyName, type: windowSize > MIN_COLLAPSE_WIDTH ? 'picture' : null, picture: row.companyLogoUrl, background: row.color2 },
                            { value: row.programName },
                            { value: '$' + thousands(row.accountPrice?.toString()) },
                            { value: profitTarget, type: 'list' },
                            { value: row.maxLossType },
                            { value: row.maxLossPer + '%' },
                            { value: row.dailyLossType },
                            { value: row.dailyLossPer === null || row.dailyLossPer === undefined ? 'N/A' : row.dailyLossPer + '%' },
                            { value: row.maxProfitSplit + '%' },
                            { value: row.firstWithdrawal },
                        ]
                    })
                })
                const tData = [];
                tData.push({
                    columnHeaders: [
                        { name: 'Prop Firm', sorting: true, key: 'companyName', dir: _curSort?.by === 'companyName' ? _curSort?.dir : 'none' },
                        { name: 'Program', sorting: true, key: 'programName', dir: _curSort?.by === 'programName' ? _curSort?.dir : 'none' },
                        { name: 'Price', sorting: true, key: 'accountPrice', dir: _curSort?.by === 'accountPrice' ? _curSort?.dir : 'none' },
                        { name: 'Profit Target', sorting: true, key: 'phasesTargetSum', dir: _curSort?.by === 'phasesTargetSum' ? _curSort?.dir : 'none' },
                        { name: 'Max Drawdown Type', sorting: true, key: 'maxLossType', dir: _curSort?.by === 'maxLossType' ? _curSort?.dir : 'none' },
                        { name: 'Max Drawdown %', sorting: true, key: 'maxLossPer', dir: _curSort?.by === 'maxLossPer' ? _curSort?.dir : 'none' },
                        { name: 'Daily Drawdown Type', sorting: true, key: 'dailyLossType', dir: _curSort?.by === 'dailyLossType' ? _curSort?.dir : 'none' },
                        { name: 'Daily Drawdown %', sorting: true, key: 'dailyLossPer', dir: _curSort?.by === 'dailyLossPer' ? _curSort?.dir : 'none' },
                        { name: 'Profit Split', sorting: true, key: 'maxProfitSplit', dir: _curSort?.by === 'maxProfitSplit' ? _curSort?.dir : 'none' },
                        { name: 'First Payout', sorting: true, key: 'firstWithdrawal', dir: _curSort?.by === 'firstWithdrawal' ? _curSort?.dir : 'none' },
                    ],
                    horScrolling: true,
                    rows
                });

                setTableData(tData);
            }
        }).catch(err => console.error(err));

        DashboardCacheI.getProgramsBySize(0, _curSize, null, null, true).then(data => {
            if (data?.items) {
                setTotal2(data.total);
                setChartData(handleBarWidth(data.items));
            }
        }).catch(err => console.error(err));
    }

    const loadBarChart = () => {
        let newPage = page2 + 1;
        const oldData = chartData;
        
        DashboardCacheI.getProgramsBySize(newPage, selectedSize.value, null, null, true).then(data => {
            if (data?.items) {
                setTotal2(data.total);

                const allChartData = oldData.concat(data.items).sort((a, b) => a.accountPrice > b.accountPrice ? 1 : -1);
                setChartData(handleBarWidth(allChartData));
                setPage2(newPage);
            }
        }).catch(err => console.error(err));
    }

    const handleBarWidth = (chartData) => {
        const unique = chartData.filter((value, index, self) =>
            index === self.findIndex(t => t.accountPrice === value.accountPrice));
        
        let diff = 80 / unique.length;
        let cur = 0;
        const performedData = [];
        chartData.forEach((it, i) => {
            if (!(chartData[i - 1] && it.accountPrice === chartData[i-1].accountPrice)) {
                cur += diff;
            }
            performedData.push({ ...it, width: cur });
        })

        return performedData;
    }

    return (
        <div className='compare-container fade-in'>
            <div className='h-font'>Compare By Size</div>
            <div className='compare-sizes-dropdown' style={{ display: 'flex' }}>
                <div className='compare-sizes-dropdown-item'>
                    {
                        sizes &&
                        <Dropdown
                            promise={sizes}
                            placeholder={'Select the size'}
                            defaultValue={selectedSize?.value}
                            onChange={e => selectSize(e, 0)} />
                    }
                </div>

                {
                    total1 > 1 &&
                    <Paginator
                        totalPages={total1}
                        pageSetNum={5}
                        pageChange={p => selectSize(null, p, sortObj)} />
                }
            </div>
            <div className='compare-sizes-table'>
                {
                    tableData &&
                    <DataGrid
                        titleSettings={{ text: thousandsShort(selectedSize?.value) + ' Challenges Comparison' }}
                        tableSettings={{ columnSeparator: true }}
                        dataSets={tableData}
                        onSort={e => { selectSize(null, null, e) }} />
                }
            </div>
            <div className='compare-sizes-chart-section'>
                <div className='h-font'>{thousandsShort(selectedSize?.value)} Challenges Lowest Prices</div>
                <div className='compare-sizes-chart'>
                    {
                        chartData?.length > 0 && chartData.map((it, i) => {
                            return (
                                <div key={i} className="size-chart-bar-group" style={{
                                        flexDirection:  windowSize < MIN_COLLAPSE_WIDTH && 'column', 
                                        alignItems:  windowSize < MIN_COLLAPSE_WIDTH && 'flex-start',
                                        gap: windowSize < MIN_COLLAPSE_WIDTH && '10px'
                                    }}>
                                    <div
                                        className={windowSize >= MIN_COLLAPSE_WIDTH ? 'ranking-company-logo-container' : ''}
                                        style={{ 'backgroundColor': windowSize >= MIN_COLLAPSE_WIDTH ? it.color2 ?? 'transparent' : 'unset' }}>

                                        {
                                            windowSize >= MIN_COLLAPSE_WIDTH &&
                                            <div
                                                title={it.companyName}
                                                className='ranking-company-logo'
                                                style={{ 'backgroundImage': `url("${it.companyLogoUrl}")` }}>
                                            </div>
                                        }

                                        {
                                            windowSize < MIN_COLLAPSE_WIDTH &&
                                            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                                                <div className='font-1' style={{fontFamily: 'Articulat Medium'}}>{it.companyName}:</div>
                                                <div className='font-1'>{it.programName}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className='size-chart-bar' title={it.programName} style={{ width: it.width + '%', backgroundColor: windowSize < MIN_COLLAPSE_WIDTH && it.color2 }}>
                                        <div className='font-1'>
                                            {windowSize < MIN_COLLAPSE_WIDTH ? '' : it.programName}
                                        </div>
                                        <div className='font-2' style={{padding: '5px 5px 2px 5px', background: 'white', textAlign: 'center', borderRadius: '5px'}}>
                                            ${thousands(it.accountPrice.toString())}
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
                <div style={{ display: 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                    {
                        (total2 - 1 !== page2 && tableData) &&
                        <button className='pfj-btn dark' style={{ width: '110px' }} onClick={() => loadBarChart()}>Load More</button>
                    }
                </div>
            </div>
        </div>
    )
}