import Icon from '../../../../components/Icon/Icon'
import isEmpty from '../../../../hooks/isEmpty'
import './CeoSection.css'

export default function CeoSection({ propFirmData }) {
    return (
        <div className='ceo-section'>
            <div className='ceo'>
                <div className='h-font'>CEO Information</div>
                <div className='ceo-set'>
                    {
                        propFirmData.ceoImage &&
                        <img src={propFirmData.ceoImage} width="200" style={{ 'borderRadius': '10px' }} alt="Ceo" />
                    }
                    {
                        !propFirmData.ceoImage &&
                        <img src='/assets/no-profile.svg' width="200" style={{ 'borderRadius': '10px' }} alt="No ceo pic" />
                    }
                    <div className='ceo-contacts'>
                        {
                            propFirmData.ceoName &&
                            <div className='contact-block txt'>
                                <div className='name'>Name</div>
                                <div className='value'>{propFirmData.ceoName}</div>
                            </div>
                        }
                        {
                            propFirmData.ceoCountry &&
                            <div className='contact-block txt'>
                                <div className='name'>Country</div>
                                <div className='value'>{propFirmData.ceoCountry}</div>
                            </div>
                        }

                        {
                            propFirmData.ceoInstagramLink &&
                            <div className='contact-block'>
                                <div className='name'>Social Media:</div>
                                <div className='value' onClick={() => window.open(propFirmData.ceoInstagramLink, '_blank')} style={{ 'marginLeft': '-5px', 'cursor': 'pointer' }}>
                                    <Icon name={'instagram'} style={{ width: '34px', fill: 'black' }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='ceo ceo-about'>
                <div className='h-font'>About {propFirmData.companyName}</div>
                <div className='s-font'>
                    {propFirmData.aboutCompany}
                </div>
                {
                    propFirmData && propFirmData.displayFullReviewBtn && !isEmpty(propFirmData.fullReviewLink) &&
                    <button className='pfj-btn dark' style={{ width: '160px' }} onClick={() => window.open(propFirmData.fullReviewLink)}>See Our Full Review</button>
                }

            </div>

        </div>
    )
}