import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { DashboardCacheI } from '../../../caches/DashboardCache';
import Dropdown from '../../../components/Dropdown/Dropdown';
import DropdownLoading from '../../../components/DropdownLoading/DropdownLoading';
import Icon from '../../../components/Icon/Icon';
import usePageInit from '../../../hooks/usePageInit';
import useWindowSize from '../../../hooks/useWindowSize';
import './ComparePage.css';
import CompareTable from './CompareTable/CompareTable';

function DropDownBlock({ index, selectedCompany, companyLoaded, companyChange, programChange }) {
    return (
        <>
            {
                <div className='dropdown-block'>
                    <div className='compare-dropdown-container'>
                        <Dropdown
                            promise={DashboardCacheI.getCompanies()}
                            placeholder={'Select the Company'}
                            transform={['_id', 'companyName']}
                            defaultValue={-1}
                            isClearable={true}
                            onLoaded={companyLoaded}
                            onChange={(e, action) => companyChange(e, action, index)} />
                    </div>
                    <div className='compare-dropdown-container'>
                        {
                            selectedCompany && selectedCompany[index] &&
                            <Dropdown
                                promise={DashboardCacheI.getPrograms(selectedCompany[index].value)}
                                placeholder={'Select the Program'}
                                transform={[{ group: 'programType', map: ['_id', 'programName'] }]}
                                onChange={e => programChange(e, index)} />
                        }
                        {
                            !selectedCompany[index] &&
                            <DropdownLoading placeholder={'Select the Program'} />
                        }
                    </div>
                </div>
            }

        </>

    )
}

export default function ComparePage() {
    usePageInit('Compare - Prop Firm Journal');
    const [width] = useWindowSize();
    const [firstCompLoaded, setFirstCompLoaded] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({ 0: null, 1: null, 2: null });
    const [selectedProgram, setSelectedProgram] = useState({ 0: null, 1: null, 2: null });
    const [explore, setExplore] = useState({ 0: null, 1: null, 2: null });

    /* Mobile Configuration */
    const [page, setPage] = useState(0);
    const [topPanel, setTopPanel] = useState(false);

    useLayoutEffect(() => {
        const onScroll = () => {
            setTopPanel(window.scrollY > 300);
        };
        window.innerWidth < 790 && window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [topPanel])

    const companyChange = (e, action, i) => {
        setSelectedCompany({ ...selectedCompany, [i]: null });
        setTimeout(() => {
            setSelectedCompany({ ...selectedCompany, [i]: action.action === 'clear' ? null : e });
            if (action.action === 'clear') {
                setSelectedProgram({ ...selectedProgram, [i]: null });
                setExplore({ ...explore, [i]: null });
            }
        });
    }

    const programChange = (e, i) => {
        setSelectedProgram({ ...selectedProgram, [i]: e });
        e && DashboardCacheI.getExplore(e.value).then(data => {
            if (data) {
                const exploreData = data[0];
                setExplore({ ...explore, [i]: exploreData });
            }
        });
    }

    return (
        <>
            {
                width < 790 && topPanel && explore[page] &&
                <div className='mobile-compare-info-panel'>
                    <div className='pages' style={{'fontSize': '20px'}}>
                        {
                            [...Array(3).keys()].map(i => 
                                <div key={i} className={'info-panel-page ' + (i === page ? 'active' : '')} onClick={() => {
                                    if (i !== page) {
                                        setPage(i);
                                        explore[i] || window.scrollTo(0, 0);
                                    }
                                }}>
                                    {i+1}
                                </div>
                            )
                        }
                    </div>
                    <div className='info-panel-company-info'>
                        <div>{selectedCompany[page]?.label ?? 'N/A'}</div>
                        <div style={{'fontFamily': 'Articulat Light'}}>{selectedProgram[page]?.label ?? 'N/A'}</div>
                    </div>
                </div>
            }

            {
                width >= 790 &&
                <div className='compare-container fade-in'>
                    <div className='h-font'>Compare </div>
                    <div className='compare-dropdowns'>
                        {
                            [...Array(3).keys()].map(i => {
                                if (i === 0 || firstCompLoaded)
                                    return (
                                        <DropDownBlock
                                            key={i}
                                            index={i}
                                            selectedCompany={selectedCompany}
                                            companyLoaded={() => i === 0 && setFirstCompLoaded(true)}
                                            companyChange={companyChange}
                                            programChange={programChange} />
                                    )
                                else
                                    return (<div key={i}></div>)
                            })
                        }
                    </div>
                    <div className='compare-pictures'>
                        {
                            [...Array(3).keys()].map(i => {
                                if (explore && explore[i]?.propFirm?.companyLogoUrl) {
                                    return (
                                        <div key={i} className='compare-company-logo-container' style={{'backgroundColor': explore[i]?.propFirm?.color2}}>
                                            <div className='compare-company-logo' style={{ backgroundImage: `url('${explore[i].propFirm.companyLogoUrl}')` }}></div>
                                        </div>

                                    )
                                }
                                return (<div className='compare-company-logo-container' style={{ 'background': 'unset' }} key={i}></div>)
                            })
                        }
                    </div>
                    <div>
                        {explore && <CompareTable exploreData={explore} />}
                    </div>
                </div>
            }

            {
                width < 790 &&
                <div className='compare-container mobile fade-in'>
                    <div className='h-font'>Compare </div>
                    <div className='compare-page-number'>
                        <div> {page + 1} </div>
                    </div>
                    <div className='compare-dropdown-block-mobile'>
                        {
                            page > 0 ?
                                <div className='arrow left' onClick={() => { setPage(page - 1) }}>
                                    <Icon name={'forward'} style={{ width: '20px', fill: 'white' }} />
                                </div> : <div className='arrow' style={{ 'visibility': 'hidden' }}></div>
                        }

                        {
                            [...Array(3).keys()].map(i => {
                                if (i === 0 || firstCompLoaded)
                                    return (
                                        <div key={i} className='block' style={{ 'display': i === page ? 'block' : 'none' }}>
                                            <DropDownBlock
                                                index={i}
                                                selectedCompany={selectedCompany}
                                                companyLoaded={() => i === 0 && setFirstCompLoaded(true)}
                                                companyChange={companyChange}
                                                programChange={programChange} />
                                        </div>

                                    )
                                else
                                    return (<div key={i}></div>)
                            })
                        }

                        {
                            page < 2 ?
                                <div className='arrow right' onClick={() => { setPage(page + 1) }}>
                                    <Icon name={'forward'} style={{ width: '20px', fill: 'white' }} />
                                </div> : <div className='arrow' style={{ 'visibility': 'hidden' }}></div>
                        }
                    </div>
                    <div className='compare-dropdown-logo-mobile'>
                        <div className='arrow' style={{ 'visibility': 'hidden' }}></div>

                        {
                            [...Array(3).keys()].map(i => {
                                if (explore && explore[i]?.propFirm?.companyLogoUrl)
                                    return (
                                        <div key={i} className='block' style={{ 'display': i === page ? 'block' : 'none' }}>
                                            <div key={i} className='compare-company-logo-container' style={{'backgroundColor': explore[i]?.propFirm?.color2}}>
                                                <div className='compare-company-logo' style={{ backgroundImage: `url('${explore[i].propFirm.companyLogoUrl}')` }}></div>
                                            </div>
                                        </div>
                                    )
                                else
                                    return (<div key={i}></div>)
                            })
                        }

                        <div className='arrow' style={{ 'visibility': 'hidden' }}></div>
                    </div>

                    {
                        [...Array(3).keys()].map(i => {
                            return (
                                <div key={i} style={{ 'display': i === page ? 'block' : 'none' }}>
                                    {
                                        explore[i] && <CompareTable exploreData={[explore[i]]} mobile={true} />
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            }
        </>
    )
}