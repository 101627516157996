import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutPage from './pages/LayoutPage/LayoutPage';
import HomePage from './pages/HomePage/HomePage';
import { ProgramsPage } from './pages/ProgramsPage/ProgramsPage';
import NewsPage from './pages/NewsPage/NewsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import { DashboardPage } from './pages/DashboardPage/DashboardPage';
import SoonPage from './pages/SoonPage/SoonPage';
import AboutPage from './pages/AboutPage/AboutPage';
import ExplorePage from './pages/DashboardPage/ExplorePage/ExplorePage';
import ComparePage from './pages/DashboardPage/ComparePage/ComparePage';
import RankingsPage from './pages/DashboardPage/RankingsPage/RankingsPage';
import NewsDetailsPage from './pages/NewsDetailsPage/NewsDetailsPage';
import ProgramDetailsPage from './pages/ProgramDetailsPage/ProgramDetailsPage';
import { PrivacyPolicy } from './pages/Legal/PrivacyPolicy';
import { RefundPolicy } from './pages/Legal/RefundPolicy';
import { TermsOfUse } from './pages/Legal/TermsOfUse';
import CompareByPricePage from './pages/DashboardPage/CompareByPricePage/CompareByPricePage';
import MatchPage from './pages/DashboardPage/MatchPage/MatchPage';
import CompetitionPage from './pages/DashboardPage/CompetitionPage/CompetitionPage';
import MediaPage from './pages/MediaPage/MediaPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          <Route index element={<HomePage />} />
          <Route path='programs' element={<ProgramsPage />} />
          <Route path='programs/:id' element={<ProgramDetailsPage />} />
          <Route path='news' element={<NewsPage />} />
          <Route path='news/:newsId' element={<NewsDetailsPage />} />
          <Route path='media' element={<MediaPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='dashboard' element={<DashboardPage />} >
            <Route path='explore' element={<ExplorePage />} />
            <Route path='compare' element={<ComparePage />} />
            <Route path='compare-by-price' element={<CompareByPricePage />} />
            <Route path='rankings' element={<RankingsPage />} />
            <Route path='match' element={<MatchPage />} />
            <Route path='competitions' element={<CompetitionPage />} />
          </Route>
          <Route path='about' element={<AboutPage />} />
          <Route path='coming-soon' element={<SoonPage />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='refund-policy' element={<RefundPolicy />} />
          <Route path='terms-of-use' element={<TermsOfUse />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
