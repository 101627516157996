import { useEffect } from 'react';
import { useState } from 'react';
import usePageInit from '../../hooks/usePageInit'
import { getNews } from '../../requests/news.requests';
import { NewsCacheI } from '../../caches/NewsCache'
import LoaderMask from './../../components/LoaderMask/LoaderMask'
import getNewsDate from '../../hooks/useNewsDate'
import key from '../../hooks/key'
import './NewsPage.css'
import { NavLink } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import { BASE_URL, MIN_COLLAPSE_WIDTH } from '../../constants/constants';

export default function NewsPage() {
    usePageInit('News - Prop Firm Journal');

    const [news, setNews] = useState(null);
    const [showMoreBtn, setShowMoreBtn] = useState(false);
    const [random, setRandom] = useState(null);
    const [categories, setCategories] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [width] = useWindowSize();

    useEffect(() => {
        Promise.all([
            getNews(new Date().toISOString()),
            NewsCacheI.getRandomNews(),
            NewsCacheI.getCategories()
        ]).then(responses => {
            responses[0] && responses[0].data && setNews(responses[0].data);
            responses[0] && responses[0].data?.length > 0 && setShowMoreBtn(true);
            responses[1] && setRandom(responses[1]);
            responses[2] && setCategories(responses[2]);

            setLoaded(true);
        }).catch(() => setLoaded(true))
    }, []);

    const selectCategory = it => {
        setLoaded(false);
        setShowMoreBtn(true);
        const sel = selectedCategory ?? it;
        getNews(new Date().toISOString(), it._id === sel._id && selectedCategory ? undefined : it._id).then(response => {
            response && response.data && setNews(response.data);
            setLoaded(true);
            setSelectedCategory(it._id === sel._id && selectedCategory ? null : it);
        })
    }

    const moreNews = () => {
        const lastDate = news[news.length - 1]?.date;
        getNews(lastDate, selectedCategory ? selectedCategory._id : undefined).then(response => {
            const data = response && response.data;
            if (data && data.length > 0) {
                setNews([...news, ...data]);
            } else {
                setShowMoreBtn(false);
            }
        })
    }

    return (
        <>
            {loaded || <LoaderMask />}
            <main className='news-page'>
                <div className='news-header-container' style={{ 'backgroundImage': 'url("assets/news.png")' }}>
                    <div className='news-header-outer'>
                        <div className='news-header'>
                            <div className='h-font'>Prop News</div>
                            <div className='s-font'>Lastest news and events in the industry</div>
                        </div>
                    </div>
                </div>
                <div className='zig-zag'></div>
                <div className='news-page-container'>
                    <div className='left'>
                        {
                            news && news.map(it => {

                                return (
                                    <div key={key()} className='news-block'>
                                        <div className='h-font'>{it.title}</div>
                                        <div className="text-label" style={{ 'marginRight': 'auto' }}>{it.category.name}</div>
                                        <div className='img-container' style={{ 'backgroundImage': (it.img && `url("${BASE_URL}image?url=${it.img}")`) }}></div>
                                        <div className="s-font" style={{ 'textTransform': 'uppercase' }}>{getNewsDate(it.date)}</div>
                                        <div className='details s-font'>{it.details}</div>
                                        <NavLink to={it._id} state={it}>
                                            <div className='read-more-btn pfj-btn dark' style={{ 'width': '80px' }}>Read More</div>
                                        </NavLink>

                                    </div>
                                )
                            })
                        }

                    </div>
                    {
                        width >= MIN_COLLAPSE_WIDTH &&
                        <div className='right'>
                            <div className='right-earn'>
                                <div className="text-label dark" style={{ width: '260px' }}>EARN AS A PROFESSIONAL TRADER</div>
                                <br />
                                <div className='h-font'>Our Challenge Programs</div>
                                <br />
                                <div className='s-font' style={{ textAlign: 'justify' }}>
                                    Our Prop Firm has multiple programs to start your journey as a professionalTrader. Choose one of our funding programs and start trading, earn bonuses and profit-splits.
                                </div>
                                <br />
                                <NavLink to='/programs'>
                                    <div className='news-start-challenge-btn pfj-btn dark'>Start Challenge</div>
                                </NavLink>
                            </div>
                            {
                                random?.length > 0 &&
                                <>
                                    <hr style={{ 'border': 'var(--main-light-darker) 2px solid' }} />
                                    <div className='random-news-container'>
                                        <div className='h-font'>You Maybe Interested In</div>
                                        <br />
                                        <div className='random-news-set'>
                                            {
                                                random && random.map((it, i) => {
                                                    return (
                                                        <div key={i} className='random-new-block'>
                                                            <NavLink to={it._id}>
                                                                <div className='random-img-container' style={{ 'backgroundImage': (it.img && `url("${BASE_URL}image?url=${it.img}")`) }}></div>
                                                                <div className='random-text-block'>
                                                                    <div className='random-text-title h-font'>{it.title}</div>
                                                                    <div className='random-text-date s-font'>{getNewsDate(it.date)}</div>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            <hr style={{ 'border': 'var(--main-light-darker) 2px solid' }} />
                            <div className='news-categories-container'>
                                <div className='h-font' style={{ 'textAlign': 'center' }}>Categories</div>
                                <br />
                                {
                                    categories && categories.map((it, i) => {
                                        return (
                                            <div key={i} className='font-1' style={{
                                                'textAlign': 'center',
                                                'padding': '10px 5px',
                                                'cursor': 'pointer',
                                                'borderRadius': '5px',
                                                'marginTop': '5px',
                                                'backgroundColor': it._id === selectedCategory?._id && 'var(--main-light-darker)'
                                            }} onClick={() => selectCategory(it)}>{it.name}</div>
                                        )
                                    })
                                }
                            </div>
                            <hr style={{ 'border': 'var(--main-light-darker) 2px solid' }} />
                        </div>
                    }

                </div>
                {
                    showMoreBtn
                        ?
                        <div
                            className='read-more-btn pfj-btn dark'
                            style={{ 'width': '80px', 'alignSelf': 'center', 'margin': '50px 0' }}
                            onClick={() => moreNews()}>More News
                        </div>
                        :
                        <div className='font-1' style={{ height: '140px', alignSelf: 'center' }}>
                            {news?.length > 0
                                ? 'That was all news for now. Thanks for reading!'
                                : 'There are no news for now'
                            }
                        </div>
                }

            </main>
        </>
    )
}