export default function starSelect(lsKey, cur, setStateFunc, isRetrieve, setStoreStateFunc, setMaxPropFirms) {
    const json = localStorage.getItem(lsKey);
    if (isRetrieve) {
        if (json) {
            const array = JSON.parse(json);
            setMaxPropFirms && setMaxPropFirms(array.length >= 10);
            const index = array.findIndex(it => it.value === cur.value);
            setStateFunc(index !== -1);
        } else {
            setStateFunc(false);
        }
    } else {
        if (!json) {
            localStorage.setItem(lsKey, JSON.stringify([cur]));
            return;
        }
        const array = JSON.parse(json);
        const index = array.findIndex(it => it.value === cur.value);
        if (index === -1) {
            if (array.length + 1 > 10) {
                return;
            }
            const arr = [...array, cur];
            localStorage.setItem(lsKey, JSON.stringify(arr));
            setStoreStateFunc && setStoreStateFunc(arr);
            setStateFunc(true);
        } else {
            const arr = array.filter(it => it.value !== cur.value);
            localStorage.setItem(lsKey, JSON.stringify(arr));
            setStoreStateFunc && setStoreStateFunc(arr);
            setStateFunc(false);
        }
        setMaxPropFirms && setMaxPropFirms(false);
    }
}