import { MIN_COLLAPSE_WIDTH } from '../../../constants/constants';
import useWindowSize from '../../../hooks/useWindowSize';
import './AboutSection.css';

export default function AboutSection() {
    const [width] = useWindowSize();

    return (
        <div className='about-us-container'>
            <div className='about-us'>
                <div className='text-label' style={{ width: '95px' }}>About Us</div>
                <div className='h-font'>Your trusted source.</div>
                <div className='s-font'>
                    We are the Prop Firm Journal. Your main data source on the Prop Firm Industry. We developed a dashboard for you to explore, compare, and find the perfect Trading Program.
                </div>
                <div className='about-us-set'>
                    <div className='about-us-item'>
                        <div className='about-us-img'>
                            <img src='assets/about-1.png' alt='at-hand-data' />
                        </div>
                        <div className='s-font'>At Hand Data</div>
                    </div>
                    <div className='about-us-item'>
                        <div className='about-us-img'>
                            <img src='assets/about-2.png' alt='easy-design' />
                        </div>
                        <div className='s-font'>Easy Design</div>
                    </div>
                    <div className='about-us-item'>
                        <div className='about-us-img'>
                            <img src='assets/about-3.png' alt='top-companies-selected' />
                        </div>
                        <div className='s-font'>Top Companies<br />Selected</div>
                    </div>
                </div>
            </div>
            {width > MIN_COLLAPSE_WIDTH && <img className='about-section-img' src='assets/about.png' alt='About' />}
        </div>
    )
}