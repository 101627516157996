import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";
import isEmpty from '../../../../hooks/isEmpty'

export default function SocialTable({ companyData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {

        const data = [{ icons: [] }];
        !isEmpty(companyData.instagramLink) && data[0].icons.push({ name: 'instagram', link: companyData.instagramLink });
        !isEmpty(companyData.facebookLink) && data[0].icons.push({ name: 'facebook', link: companyData.facebookLink });
        !isEmpty(companyData.tikTokLink) && data[0].icons.push({ name: 'tikTok', link: companyData.tikTokLink });
        !isEmpty(companyData.twitterLink) && data[0].icons.push({ name: 'twitter3', link: companyData.twitterLink });
        !isEmpty(companyData.youtubeLink) && data[0].icons.push({ name: 'youtube', link: companyData.youtubeLink });
        !isEmpty(companyData.discordLink) && data[0].icons.push({ name: 'discord', link: companyData.discordLink });
        !isEmpty(companyData.telegramLink) && data[0].icons.push({ name: 'telegram', link: companyData.telegramLink });

        setTable(data);

    }, [companyData])

    return (
        <div className="social-table">
            <DataGrid
                titleSettings={{ text: 'Social Media', mode: 'hr' }}
                dataSets={table} />
        </div>
    )
}