import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function AffiliateProgramTable({ affiliateProgram, isMobile }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        const data = [];
        const rows = [];

        affiliateProgram?.tiers?.forEach((it, i) => {
            let cells = [
                { value: it.title },
                { value: it.commission ? it.commission + '%' : 'N/A' },
                { value: it.customCode, type: 'boolean', tag: 'customCouponCode' },
                { value: it.couponCode ? it.couponCode + '%' : 'N/A' },
                { value: it.schedule ? it.schedule + ' days' : 'N/A' }
            ];

            i === 0 && !isMobile && (cells.push({ value: affiliateProgram.withdrawalMethods ?? '' }))
            isMobile && (cells = cells.filter(it => it.tag !== 'customCouponCode'))

            rows.push({ cells })
        });

        let columnHeaders = [
            { name: 'Affiliate Tiers' },
            { name: 'Commission' },
            { name: 'Custom Coupon Code?' },
            { name: 'Coupon Code' },
            { name: 'Withdrawal Schedule' },
        ];

        isMobile && (columnHeaders = columnHeaders.filter(it => it.name !== 'Custom Coupon Code?'));

        isMobile || columnHeaders.push({ name: 'Withdrawal Methods', rowspan: affiliateProgram?.tiers?.length ?? 1 })

        data.push({ columnHeaders, horScrolling: true, rows });

        setTable(data);

    }, [affiliateProgram]);

    return (
        <div className="general-challenge-table">
            <DataGrid
                titleSettings={{ text: 'Affiliate Program' }}
                tableSettings={{ columnSeparator: true }}
                dataSets={table} />
        </div>
    );
}