import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function GeneralChallengeTable({ exploreData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        const data = [];
        const profitTarget = [];

        if (exploreData.phases) {
            const minDays = [];
            const maxDays = [];
            const days = [];

            exploreData.phases.forEach(phase => {
                minDays.push({ value: exploreData.minDays });
                days.push({ value: phase.days });
                profitTarget.push({ value: phase.target });
            });

            data.push({
                columnHeaders: ['', ...exploreData.phases.map(h => ({ name: `Phase ${h.number}` }))],
                rows: [
                    { cells: [{ value: 'Minimum Days' }, ...minDays] },
                    { cells: [{ value: 'Maximum Days' }, ...days] },
                    { cells: [{ value: 'Profit Target' }, ...profitTarget] }
                ]
            });
        } else {
            data.push({
                noDataText: 'This is an Instant funding program. You will start trading on live account.',
                rows: []
            });
        }

        let drawdownToProfitRatio = 'N/A'
        if (exploreData.phases === null || exploreData.phases === undefined || exploreData.phases.length === 0) {
            drawdownToProfitRatio = 'N/A'
        } else {
            const profitTargetsNum = profitTarget.map(it => parseFloat(it.value?.split('%')[0] ?? 0));
            drawdownToProfitRatio = (profitTargetsNum.reduce((a, b) => a + b) / (exploreData.maxLossPer ?? 1)).toFixed(3);
        }

        data.push({
            columnHeaders: [{ name: 'Loss Limits' }, { name: 'Type' }, { name: 'Percentage' }],
            rows: [
                { cells: [{ value: 'Daily Drawdown' }, { value: exploreData.dailyLossType === null ? 'N/A' : exploreData.dailyLossType }, { value: exploreData.dailyLossPer === null ? 'N/A' : exploreData.dailyLossPer + '%' }] },
                { cells: [{ value: 'Max Drawdown' }, { value: exploreData.maxLossType === null ? 'N/A' : exploreData.maxLossType }, { value: exploreData.maxLossPer === null ? 'N/A' : exploreData.maxLossPer + '%' }] },
                { cells: [{ value: 'Profit to Drawdown Ratio' }, { value: drawdownToProfitRatio, colspan: 2, borderTop: true }] }
            ]
        });

        setTable(data);

    }, [exploreData]);

    return (
        <div className="general-challenge-table">
            <DataGrid
                titleSettings={{ text: 'General Challenge Information' }}
                tableSettings={{ columnSeparator: true }}
                dataSets={table} />
        </div>
    );
}
