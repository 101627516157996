import usePageInit from '../../hooks/usePageInit';
import './Legal.css'

export function TermsOfUse() {
    usePageInit('Terms Of Use - Prop Firm Journal');

    return (
        <main>
            <div className="legal-template">
                <div className='legal-title legal-bold'>
                    TERMS OF USE
                </div>
                <div className='legal-text'>
                    PLEASE READ THIS AGREEMENT CAREFULLY. BY ACCESSING OR USING THIS SITE OR OUR SERVICES OR
                    OTHERWISE AGREEING TO THIS AGREEMENT, YOU UNDERSTAND AND AGREE TO BE BOUND BY THIS
                    AGREEMENT AND RECOGNIZE THAT YOU MAY BE WAIVING CERTAIN RIGHTS. <br /> <br />
                    The propfirmjournal.com website (the “Site”) and all services and interactions with Prop Firm Journal, LLC
                    (“Prop Firm Journal”, the “Company”, “we” or “us”) are comprised of various web pages operated by the
                    Company. The Site is made available to you conditioned on and subject to your acceptance without
                    modification of the terms, conditions, and notices contained herein (collectively, the “Terms” or this
                    “Agreement”). Your use of the Site constitutes your acceptance of and agreement to all such Terms. Please
                    read these Terms carefully and keep a copy of them for your reference. These Terms are applicable for all
                    services provided by the Company and the Site.<br />
                    Prop Firm Journal provides various evaluation plans and programs intended for traders to try proving their
                    skills in simulated trading by passing evaluations with specific goals that must be achieved in order to pass
                    the evaluations. <br /> <br /> <br />

                    <div className='legal-bold'>Other Policies</div>
                    The Terms do not alter in any way the terms or conditions of any other agreement you may have with us
                    for products, services, programs or otherwise. Additional policies and terms may apply to use of specific
                    portions of a Site and to the purchase of certain services and are included as part of these Terms whether
                    they reference these Terms or not.
                    Other types of agreements and policies that you may be subject to include, but are not limited to:
                    <ul style={{ marginLeft: '15px' }}>
                        <li>Prop Firm Journal Knowledge Center/FAQ, and all rules and conditions therein, as amended from
                            time to time and which are incorporated by reference and made a part of these Terms. By
                            agreeing to these Terms, you are agreeing to abide by all rules identified within the knowledge
                            center and other sources within the website.</li>
                        <li>
                            The Refund Policy.
                        </li>
                        <li>
                            The Privacy Policy.
                        </li>
                    </ul><br />

                    <div className='legal-bold'>Electronic Communications</div>
                    Visiting this Site, contacting Prop Firm Journal via social media and live chat support, or sending emails to
                    Prop Firm Journal constitutes electronic communications. You consent to receiving electronic
                    communications and you agree that all agreements, notices, disclosures, and other communications that
                    we provide to you electronically, via email, live chat support, social media and on this Site, satisfy any legal
                    requirement that such communications be in writing. <br /><br />

                    <div className='legal-bold'>Your Account</div>
                    If you use this Site, you are responsible for maintaining the confidentiality of your account and password
                    and for restricting access to your computer, and you agree to accept responsibility for all activities that
                    occur under your account or password. You may not assign or otherwise transfer your account to any
                    other person or entity. You acknowledge that Prop Firm Journal is not responsible for third party access
                    to your account that results from theft or misappropriation of your account. Prop Firm Journal and its
                    employees and representatives reserve the right to refuse or cancel service, terminate accounts, or
                    remove or edit content in our sole discretion.<br /><br />
                    FOR ANY EVALUATION PROGRAMS THAT A USER IS PROVIDED THROUGH THIS SITE, UNDER NO
                    CIRCUMSTANCES MAY THE USER CHANGE THE PASSWORD TO THE DEMO SIMULATED TRADING
                    ACCOUNT. Any change of the password constitutes a breach of these Terms and Prop Firm Journal will
                    consider and treat such user's evaluation as having failed.<br /><br />

                    <div className='legal-bold'>Evaluation Demo Account Terms</div>
                    Evaluation accounts provided by Prop Firm Journal are demo accounts only, are not live actual simulated
                    trading and are subject to 1:200 FOREX leverage. Any other leverage amount, provision or level is subject
                    to the sole determination and judgment of Prop Firm Journal and what is deemed reasonable by Prop
                    Firm Journal in its sole discretion to provide to users.<br /><br />

                    <div className='legal-bold'>Minors</div>
                    Prop Firm Journal does not, to the best of our knowledge, collect, either online or offline, personal
                    information from persons under the age of thirteen (13). If you are under the age of eighteen, you are
                    prohibited from the use of services and interactions with Prop Firm Journal. We have implemented various
                    controls to restrict use of our services by persons under the age of a minor. If a minor circumvents the
                    controls and uses our services, any resulting actions or consequences are solely the responsibility of the
                    minor. By accessing our services, you agree to hold us harmless for any actions or consequences resulting
                    from your failure to comply with our age restrictions.<br /><br />

                    <div className='legal-bold'>Links to Third-Party Sites/Third-Party Services</div>
                    The Site may contain links to other websites (“Linked Sites”). The Linked Sites are not under the control
                    of Prop Firm Journal and Prop Firm Journal is not responsible for the contents of any Linked Site, including
                    without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Prop
                    Firm Journal is providing these links to you only as a convenience, and the inclusion of any link does not
                    imply endorsement by Prop Firm Journal of any site or any association with its operators.<br />
                    Certain services made available via the Site are delivered by third parties. By using any product, service or
                    functionality originating from the propfirmjournal.com domain, you acknowledge, agree and consent that
                    Prop Firm Journal may share such information and data with any third party with whom Prop Firm Journal
                    has a business relationship to provide the requested product, service or functionality on behalf of
                    propfirmjournal.com users and customers.<br /><br />

                    <div className='legal-bold'>No Unlawful or Prohibited Use/Intellectual Property</div>
                    You are granted a non-exclusive, non-transferable, revocable license to access and use
                    propfirmjournal.com strictly in accordance with these Terms. As a condition of your use of the Site, you
                    warrant to Prop Firm Journal that you will not use the Site for any purpose that is unlawful or prohibited
                    by these Terms or any applicable law, regulation or requirement to which Prop Firm Journal or you is or
                    may be subject. You may not use the Site in any manner which could damage, disable, overburden, or
                    impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or
                    attempt to obtain any materials or information through any means not intentionally made available or
                    provided for through the Site. Prop Firm Journal reserves the right to deny access to the Site in the event
                    that we determine, in our sole discretion, that your use of the Site or our products and services is for
                    unlawful or fraudulent purposes.<br /><br />
                    All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation
                    thereof, and any software used on the Site, is the property of Prop Firm Journal or its suppliers and
                    protected by copyright and other laws that protect intellectual property and proprietary rights. You agree
                    to observe and abide by all copyright and other proprietary notices, legends or other restrictions
                    contained in any such content and will not make any changes thereto.<br /><br />
                    You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                    derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Prop Firm
                    Journal materials and Site content are not for resale, and by accessing the Site you acknowledge and agree
                    that you will not resell, redistribute or otherwise use or attempt to use the Site or its contents in any
                    manner or for any purpose except as permitted or authorized by Prop Firm Journal. Your use of the Site
                    does not entitle you to make any unauthorized use of any content, and without limiting the foregoing, in
                    particular you will not delete, alter or otherwise modify or attempt to modify any proprietary rights or
                    attribution notices in any content. You will use protected content solely for your personal non-commercial
                    use and will make no other use of the content without the express written permission of Prop Firm Journal
                    and the copyright owner. You agree that you do not acquire any ownership rights in or claims to any Site
                    content. We do not grant you any licenses, express or implied, to the intellectual property of Prop Firm
                    Journal or our licensors except as expressly authorized by these Terms.<br /><br />

                    <div className='legal-bold'>Legal and Market Compliance</div>
                    Prop Firm Journal takes compliance with relevant laws, regulations, and requirements seriously and asks
                    our customers to do the same. Further, Prop Firm Journal also respects the functioning of actual markets.
                    As such, any simulated trading practices that interfere with the functioning of actual markets are
                    prohibited on our platform. Such simulated trading practices that do not comply with the functioning of
                    real markets include, but are not limited to:
                    <ul style={{ marginLeft: '15px' }}>
                        <li>Use of platform or data freezing</li>
                        <li>Use of delayed data feed</li>
                        <li>Trading on delayed charts</li>
                        <li>Trading at a time of significant macroeconomic reports</li>
                        <li>Use of the guarantee of compliance with limit orders</li>
                        <li>Use of hedging trades between Prop Firm Journal accounts and/or third-party accounts.</li>
                    </ul> <br />
                    Violation of any of these Terms or applicable laws, regulations or requirements in any way may lead to
                    immediate termination of all accounts associated with our services and no refund or payment of any kind
                    will be given. In addition, you may be held civilly and/or criminally liable if you violate these Terms or
                    applicable laws, regulations or requirements. Understanding the importance of having a secure, reliable
                    simulated trading platform, you explicitly instruct Prop Firm Journal to share your data with other
                    proprietary simulated trading firms or third parties for the purposes of detecting fraudulent, deceptive,
                    or criminal behavior or any violations of our Terms or this Agreement.<br /><br />

                    <div className='legal-bold'>Indemnification</div>
                    You agree to indemnify, defend and hold harmless Prop Firm Journal, its officers, directors, employees,
                    agents, representatives, and third parties, for any losses, costs, liabilities and expenses (including
                    reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services,
                    any user postings made by you, your violation of these Terms or your violation of any rights of a third
                    party or any applicable laws, regulations or requirements. Prop Firm Journal reserves the right, at its own
                    cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by
                    you, in which event you agree to fully cooperate with Prop Firm Journal in asserting any available
                    defenses.<br /><br />

                    <div className='legal-bold'>Disputes & Arbitration</div>
                    PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING
                    YOUR RIGHT TO FILE A LAWSUIT IN COURT.<br />
                    THIS AGREEMENT CONTAINS A BINDING ARBITRATION AGREEMENT WHICH LIMITS YOUR RIGHTS TO
                    BRING AN ACTION IN COURT, BRING A CLASS ACTION, AND HAVE DISPUTES DECIDED BY A JUDGE OR JURY,
                    AS WELL AS PROVISIONS THAT LIMIT OUR LIABILITY TO YOU.THE PRODUCTS AND SERVICES OFFERED ON
                    THIS SITE ARE NOT IN ANY WAY INTENDED TO BE CONSIDERED AN INVESTMENT.<br />
                    ARBITRATION USES A NEUTRAL ARBITRATOR INSTEAD OF A JUDGE OR JURY, ALLOWS FOR MORE LIMITED
                    DISCOVERY THAN IN COURT, AND IS SUBJECT TO VERY LIMITED REVIEW BY COURTS. YOU MAY CHOOSE
                    TO BE REPRESENTED BY A LAWYER IN ARBITRATION OR PROCEED WITHOUT ONE. THIS ARBITRATION
                    PROVISION SHALL SURVIVE TERMINATION OF THIS AGREEMENT. IF, HOWEVER, EITHER THE CLASS ACTION
                    WAIVER OR COORDINATED CLAIMS PROVISION BELOW ARE FOUND INVALID, THEN THE SPECIFIC INVALID
                    PROVISION WILL BE UNENFORCEABLE AND WILL BE SEVERED AND THE REMAINDER OF THE ARBITRATION
                    PROVISIONS WILL REMAIN IN FULL FORCE.<br />
                    In the event the parties are not able to resolve any dispute, claim or controversy, including those known
                    or unknown that may be later discovered, between them arising out of or concerning these Terms or any
                    provisions hereof, or other agreements on the Site, other agreements between us, or the breach,
                    termination, enforcement, interpretation or validity thereof, including the determination of the scope or
                    applicability of this agreement to arbitrate, whether in contract, tort, or otherwise, at law or in equity for
                    damages or any other relief, legal or equitable, then such dispute shall be resolved only by either: (i) final
                    and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator
                    and administered by the JAMS Comprehensive Arbitration Rules and Procedures as those Rules exist on
                    the effective date of this Agreement or (ii) submitted to small claims court in Miami-Dade County, Florida.
                    If the arbitrator finds this location of arbitration unreasonably burdensome to you, a new location may be
                    selected in a location mutually agreed upon by the parties, or the arbitration may be conducted over the
                    phone, using video conferencing, or similar. You may be entitled to an in-person hearing near your place
                    of residence.<br />
                    The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction.
                    In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these
                    Terms, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. Seeking
                    Arbitration: If you elect to seek arbitration or file a small claim court action, you must first send to us, by
                    certified mail, a written notice of your claim (“Notice”). The Notice to us must be addressed to: <br /><br />

                    &nbsp;&nbsp;&nbsp;&nbsp; Prop Firm Journal, LLC <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; 1657 N Miami Ave <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Unit 911-A <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Miami, FL 33136 <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; with an electronic copy to the following email address: <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; support@propfirmjournal.com <br /> <br />

                    If we initiate arbitration, we will send a written Notice to an email address you have previously provided
                    to us, if available. We may also use any other means to contact you, including a message in your account
                    or to an address we have on file. A Notice, whether sent by you or by us, must (a) describe the nature and
                    basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). If you and we do not
                    reach an agreement to resolve the claim within 30 days after the Notice is received, you or us may
                    commence an arbitration proceeding or file a claim in small claims court. Arbitration forms can be
                    downloaded from www.jamsadr.com.<br />
                    Hearing: If your claim is for US$10,000 or less, we agree that you may choose whether the arbitration will
                    be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic or video
                    hearing, or by an in-person hearing as established by the JAMS Rules. If your claim exceeds US$10,000,
                    the right to a hearing will be determined by the JAMS Rules. In the event that the arbitration will be
                    conducted solely on the basis of submitted documents, the arbitrator's decision and award will be made
                    and delivered within six (6) months of the selection of the arbitrator, unless extended by the arbitrator.
                    Except as expressly set forth herein, the payment of all filing, administration and arbitrator fees will be
                    governed by the JAMS Rules.<br />
                    Award: In the event arbitration awards you damages of an amount at least $100 greater than our last
                    documented settlement offer, we will pay your awarded damages or $2,500, whichever is greater.
                    Injunctive Relief: Notwithstanding the foregoing, you and we both agree that you or we may sue in court
                    to enjoin infringement or other misuse of intellectual property rights or in other scenarios where
                    injunctive relief is appropriate. In the event a court or arbitrator having jurisdiction finds any portion of
                    this Agreement unenforceable, that portion shall not be effective and the remainder of the Agreement
                    shall remain effective. No waiver, express or implied, by either party of any breach of or default under this
                    Agreement will constitute a continuing waiver of such breach or default or be deemed to be a waiver of
                    any preceding or subsequent breach or default.<br />
                    Confidentiality: The parties shall maintain the confidential nature of the arbitration proceeding and the
                    Award, including the hearing, except as may be necessary to prepare for or conduct the arbitration hearing
                    on the merits, or except as may be necessary in connection with a court application for a preliminary
                    remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial
                    decision.<br />
                    Coordinated Proceedings: If 25 or more individuals initiate Notices of dispute with us raising similar claims,
                    and counsel for the individuals bringing the claims are the same or are coordinated for these individuals
                    (“Coordinated Claims”), the claims shall proceed in arbitration in a coordinated proceeding. Counsel for
                    the individuals and counsel for Prop Firm Journal shall each select five cases to proceed first in arbitration
                    in a bellwether proceeding (“Test Cases”). The remaining cases shall not be filed in arbitration until the
                    first ten have been resolved. If the parties are unable to resolve the remaining cases after the conclusion
                    of the Test Cases, each side may select another five cases to proceed to arbitration for a second bellwether
                    proceeding. This process may continue until the parties have determined an objective methodology to
                    make an offer to resolve each and every outstanding claim. A court will have authority to enforce this
                    clause and, if necessary, to enjoin the mass filing of arbitration demands against Prop Firm Journal.
                    Individuals bringing Coordinated Claims shall be responsible for up to $250 of their filing fees or the
                    maximum permissible under the applicable arbitration rules. All applicable statutes of limitations and
                    defenses based upon the passage of time will be tolled while the Coordinated Proceedings specified in
                    this Section are pending. We will take such action, if any, required to effectuate such tolling.<br /><br />
                    Governing Law and Rules: This Agreement and the rights of the parties hereunder shall be governed by
                    and construed in accordance with the laws of the Cayman Islands, exclusive of conflict or choice of law
                    rules. The parties acknowledge that this Agreement evidences a transaction involving interstate
                    commerce. Notwithstanding the provision in the preceding paragraph with respect to applicable
                    substantive law, any arbitration conducted pursuant to the terms of this Agreement shall be governed by
                    the Federal Arbitration Act (9 U.S.C., Secs. 1-16). In any arbitration arising out of or related to this
                    Agreement, the arbitrator is not empowered to award punitive or exemplary damages, except where
                    permitted by statute, and the parties waive any right to recover any such damages. In any arbitration
                    arising out of or related to this Agreement, the arbitrator may not award any incidental, indirect or
                    consequential damages, including damages for lost virtual profit.. The parties adopt and agree to
                    implement the JAMS Optional Arbitration Appeal Procedure (as it exists on the effective date of this
                    Agreement) with respect to any final award in an arbitration arising out of or related to this Agreement.
                    <br />
                    Severance of Arbitration Agreement. If the clauses concerning and describing the procedures and
                    obligations related to Coordinated Claims and Test Case procedures is or becomes invalid or
                    unenforceable, then the remaining entire arbitration agreement and any clauses concerning, relating to,
                    specifying or otherwise describing the arbitration agreement shall be severed from this Agreement.
                    However, any duty of confidentiality whether or not such duty is connected with arbitration shall survive
                    such severance.<br /><br />

                    <div className='legal-bold'>Class Action Waiver</div>
                    PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING
                    YOUR RIGHT TO FILE A LAWSUIT IN COURT.<br /><br />
                    YOU AGREE THAT ANY ARBITRATION UNDER THESE TERMS AND CONDITIONS WILL TAKE PLACE ON AN
                    INDIVIDUAL BASIS. YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
                    YOUR OR OUR INDIVIDUALS CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                    CLASS OR REPRESENTATIVE PROCEEDING, INCLUDING BUT NOT LIMITED TO A PRIVATE ATTORNEY
                    GENERAL ACTION. CLASS ARBITRATIONS AND CLASS/REPRESENTATIVE/COLLECTIVE ACTIONS ARE NOT
                    PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ARBITRATION
                    OR CLASS ACTION. Further, unless both you and Prop Firm Journal agree otherwise, the arbitrator may
                    not consolidate more than one person's claims and may not otherwise preside over any form of a
                    representative or class proceeding.<br /><br />
                    If this specific provision is found to be unenforceable, then the entirety of this Class Action Waiver and
                    the Disputes & Arbitration provisions shall be null and void. The arbitrator may award declaratory or
                    injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to
                    provide relief warranted by that party's individual claim.<br /><br />

                    <div className='legal-bold'>Liability Disclaimer</div>
                    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THIS
                    SITE MAY INCLUDE INACCURACIES OR ERRORS. CHANGES ARE PERIODICALLY MADE TO THE
                    INFORMATION HEREIN. PROP FIRM JOURNAL LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS
                    AND/OR CHANGES IN THE SITE AT ANY TIME WITHOUT PRIOR NOTICE.<br /><br />
                    PROP FIRM JOURNAL, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
                    RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
                    SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM
                    EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES
                    AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. PROP
                    FIRM JOURNAL LLC AND/OR ITS SUPPLIERS DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD
                    TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL
                    IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    TITLE AND NON-INFRINGEMENT.<br /><br />
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL PROP FIRM JOURNAL LLC
                    AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
                    DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
                    USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED
                    SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION,
                    SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
                    OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
                    STRICT LIABILITY OR OTHERWISE, EVEN IF PROP FIRM JOURNAL LLC OR ANY OF ITS SUPPLIERS HAS BEEN
                    ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                    LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THIS SITE, OR
                    WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THIS
                    SITE.<br /><br />

                    <div className='legal-bold'>New Jersey Residents</div>
                    If you are a consumer residing in New Jersey, the following provisions of this Agreement do not apply to
                    you (and do not limit any rights that you may have) to the extent that they are unenforceable under New
                    Jersey law: (a) Disclaimer of Warranty; (b) Limitation of Liability; (c) Indemnity; and (d) under Disputes &
                    Arbitration and Class Action Waiver and the governing law provisions (solely to the extent that your rights
                    as a consumer residing in New Jersey are required to be governed by New Jersey law). According to
                    N.J.S.A. 56:12-16, you may have additional rights if you are a New Jersey resident and other provisions of
                    this Agreement are found to violate an established legal right.<br /><br />

                    <div className='legal-bold'>California Residents</div>
                    Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights
                    notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer Services
                    of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA
                    95834, or by telephone at (916) 445-1254 or (800) 952-5210.<br />
                    If you are a California resident, you agree to consciously waive all claims, both known and unknown that
                    may be later discovered and expressly forgo and waive all protections as by California Civil Code Section
                    1542, which states, “[a] general release does not extend to claims that the creditor or releasing party does
                    not know or suspect to exist in his or her favor at the time of executing the release and that, if known by
                    him or her, would have materially affected his or her settlement with the debtor or released party.” By
                    using this Site, you agree to that these California Civil Code Section 1542 protections no longer apply to
                    you.<br /><br />

                    <div className='legal-bold'>International Users</div>
                    This Site is controlled, operated and administered by Prop Firm Journal from our offices in the USA. If you
                    access the Site from a location outside the USA, you are responsible for compliance with all applicable
                    laws. You agree that you will not use Prop Firm Journal content accessed through the Site in any country
                    or in any manner prohibited by any applicable laws, regulations or requirements.<br />
                    The products referred to on Sites may only be available in the territory to which that Site is directed and
                    may not be available in your country. WE MAKE NO REPRESENTATION THAT THE INFORMATION AND
                    MATERIALS ON ANY SITE, INCLUDING WITHOUT LIMITATION THE INFORMATION AND OTHER MATERIALS
                    PROMOTING THE PRODUCTS IDENTIFIED ON THAT SITE, ARE APPROPRIATE OR AVAILABLE FOR USE IN
                    OTHER LOCATIONS OTHER THAN THE LOCATION FOR WHICH THE SITE IS DIRECTED. WE DO NOT
                    REPRESENT OR WARRANT THAT A SITE OR ANY PART THEREOF IS APPROPRIATE OR AVAILABLE FOR USE
                    IN ANY PARTICULAR JURISDICTION OTHER THAN THE UNITED STATES. Those who choose to access a Site
                    do so on their own initiative and at their own risk, and are responsible for complying with all local statutes,
                    orders, regulations, rules, and other laws. You are also subject to United States export controls and are
                    responsible for any violations of such controls, including without limitation any United States embargoes
                    or other federal rules and regulations restricting exports.<br />
                    Despite the above, as a consumer you will benefit from any mandatory provisions of the law of the country
                    in which you are a resident. Nothing in this Agreement affects your rights as a consumer to rely on such
                    mandatory provisions of local law. The local law of your jurisdiction may entitle you to have a dispute
                    relating to this Agreement heard by your local courts. This Agreement does not limit any such rights that
                    you have that apply. HOWEVER, BY ENTERING INTO THIS AGREEMENT, WE DO NOT CONSENT TO THE
                    JURISDICTION OF ANY COURTS OTHER THAN THOSE REFERENCED IN THIS AGREEMENT AND RESERVES
                    THE RIGHT TO CONTEST THAT IT IS NOT SUBJECT TO THE JURISDICTION OF ANY OTHER COURT. We may
                    limit a Site's availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at
                    any time and in our sole discretion. This Agreement, as well as all other documents related to it, including
                    notices and correspondence, will be in the English language only.<br /><br />
                    THE FOLLOWING TERMS AND CONDITIONS APPLY TO YOU, AND SUPERSEDE CONFLICTING TERMS IN THE
                    AGREEMENT, IF YOU ARE A RESIDENT OF THE NAMED JURISDICTION OR TO THE EXTENT REQUIRED BY
                    APPLICABLE LAW:<br /><br />

                    <div className='legal-bold'>AUSTRALIA</div>
                    Where any Act of Parliament implies any condition or warranty in relation to your use of a Site and that
                    Act prohibits exclusion of that term, then that term is included. To the maximum extent permitted by law,
                    our liability for any breach of such a non-excludable term is limited to the supplying of the products or
                    services again.<br /><br />

                    <div className='legal-bold'>CANADA</div>
                    L'acheteur confirme son intention expresse que cet accord, ainsi que tous les documents connexes, soient
                    rédigés en langue anglaise uniquement, y compris tous les avis et la correspondance.<br /><br />
                    Quebec Customers: For Quebec customers (or customers from other Canadian provinces where
                    applicable) we will, if required, send at least 30 days before the amendment comes into force, a written
                    notice drawn up clearly and legibly, setting out the new clause only, or the amended clause and the clause
                    as it read formerly, the date of the coming into force of the amendment and the customer's right to refuse
                    the amendment and rescind or, in the case of a contract involving sequential performance, cancel the
                    contract without cost, penalty or cancellation indemnity by sending us a notice to that effect no later than
                    30 days after the amendment comes into force, if the amendment entails an increase in the customer's
                    obligations or a reduction in our obligations.<br />
                    Dispute Resolution: The arbitration requirements of this Agreement will not apply to you if any such
                    provision is unenforceable under the laws of your Canadian province of residence.<br />
                    Cancellation Rights: Residents of certain provinces may have the right to cancel the provisions of certain
                    purchases as required by local law. We will honor such cancellation rights.<br />
                    Privacy and Consumer Complaints: Under relevant consumer protection laws, you are entitled to the
                    following consumer rights notice: If you have a question or complaint regarding the Site, please send an
                    e-mail to support@propfirmjournal.com or contact us in writing by writing to Prop Firm Journal LLC, 14001
                    W HWY 29 SUITE 102, Liberty Hill, Texas 78642.<br /><br />

                    <div className='legal-bold'>EUROPEAN UNION</div>
                    Children: You may not use any Site if you are under the age of 16.<br />
                    Exceptions to Liability Limitations: Nothing in this Agreement excludes or limits our liability for death or
                    personal injury arising from our negligence, or fraud or fraudulent misrepresentation, or any other liability
                    that cannot be excluded or limited by applicable law.<br /><br />
                    ARBITRATION MAY NOT APPLY TO YOU IF YOU ARE A RESIDENT OF THE EUROPEAN UNION AND SUBJECT
                    TO THE ALTERNATIVE DISPUTE RESOLUTION DIRECTIVE (2013/11/EU) AND THE ONLINE DISPUTE
                    RESOLUTION REGULATION (EU 524/2013) (AND ANY IMPLEMENTING REGULATIONS IN EACH MEMBER
                    STATE OF THE EU), THE AGREEMENT TO ARBITRATE IN THE AGREEMENT WILL NOT APPLY TO IF NOT
                    PERMITTED BY LAW.<br /><br />

                    <div className='legal-bold'>Japan</div>
                    Any intellectual property rights vested in us under these terms will include the rights set forth in Article
                    27 (Right of Adaptation) and Article 28 (Original Author's Right in Derivative Works) of the Copyright Act
                    of Japan, Act No. 48 of May 6, 1970. Further, you agree not to exercise against us any moral rights you
                    may have, including, without limitation, the feedback or suggestions. <br />
                    Each Party represents and warrants that it (a) is not an anti-social force (meaning here and hereinafter,
                    gangsters, anti-social forces, and others equivalent thereto) and (b) does not have any exchange or
                    involvement with anti-social forces, such as cooperation or involvement in the maintenance, operation,
                    or management of anti-social forces, through funding or other means.<br /><br />

                    <div className='legal-bold'>UNITED KINGDOM</div>
                    Children: You may not use any Site if you are under the age of 16.<br />
                    Exceptions to Liability Limitations: Nothing in this Agreement excludes or limits our liability for death or
                    personal injury arising from our negligence, or fraud or fraudulent misrepresentation, or any other liability
                    that cannot be excluded or limited by applicable law.<br /><br />
                    ARBITRATION MAY NOT APPLY TO YOU IF YOU ARE A RESIDENT OF THE UNITED KINGDOM AND SUBJECT
                    TO THE ALTERNATIVE DISPUTE RESOLUTION DIRECTIVE (2013/11/EU) AND THE ONLINE DISPUTE
                    RESOLUTION REGULATION (EU 524/2013) (OR ANY SUCCESSOR TO THESE ENACTED BY THE UK POST
                    BREXIT). THE AGREEMENT TO ARBITRATE IN THIS AGREEMENT WILL NOT APPLY TO IF NOT PERMITTED BY
                    LAW.<br /><br />

                    <div className='legal-bold'>Termination/Access Restriction</div>
                    Prop Firm Journal reserves the right, in its sole discretion, to terminate your access to this Site and the
                    related services or any portion thereof at any time, without notice. These Terms are governed by the laws
                    of the Cayman Islands, and you agree that any dispute or disagreement with Prop Firm Journal, or arising
                    out of or in connection with these Terms, that is not resolved through arbitration as provided herein shall
                    be resolved by the state courts located in <span className='legal-bold'>Miami-Dade County, Florida</span>. You consent to such exclusive
                    jurisdiction and venue of such courts for such purpose. Use of the Site is not permitted in any jurisdiction
                    that does not give effect to all provisions of these Terms, including, without limitation, this section.<br />
                    You agree that no joint venture, partnership, employment, or agency relationship exists between you and
                    Prop Firm Journal as a result of these Terms or your use of the Site. Prop Firm Journal's performance of
                    these Terms is subject to applicable laws, regulations and requirements, and nothing contained in these
                    Terms is in derogation of Prop Firm Journal's right to comply with governmental, judicial and law
                    enforcement requests or requirements relating to you or your use of the Site or information provided to
                    or gathered by Prop Firm Journal with respect to you or such use.<br />
                    If any part of these Terms is determined to be invalid or unenforceable pursuant to applicable law, then
                    the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision or
                    statement that most closely matches the intent of the original provision and the remainder of these Terms
                    will continue in effect.<br />
                    Unless otherwise specified herein, these Terms constitute the entire agreement between the user and
                    Prop Firm Journal with respect to the Site and supersede all prior or contemporaneous communications
                    and proposals, whether electronic, oral or written, between the user and Prop Firm Journal with respect
                    to the Site. A printed version of these Terms and of any notice given in electronic form shall be admissible
                    in judicial or administrative proceedings based upon or relating to these Terms to the same extent and
                    subject to the same conditions as other business documents and records originally generated and
                    maintained in printed form. <br /><br />

                    <div className='legal-bold'>Changes to Terms</div>
                    Prop Firm Journal reserves the right, in its sole discretion, to change the Terms under which this Site is
                    offered. The most current version of the Terms supersedes all previous versions. Prop Firm Journal
                    encourages you to periodically review the Terms to keep informed regarding any updates. By continuing
                    to use the Site and/or the products and services of Prop Firm Journal, you agree that you have reviewed
                    and consent to the most current version of the Terms. If you have any questions regarding the Terms,
                    please contact us at <a href='mailto: support@propfirmjournal.com'>support@propfirmjournal.com</a>.
                    <br /><br />

                    <div className='legal-bold'>Effective as of October 3, 2023</div>
                    <br /><br />
                </div>
            </div>
        </main>
    )
}