import Router from '../Router/Router';
import './MenuPanel.css';

export default function MenuPanel({ opened }) {

    return (
        opened && (
            <div className='menu-panel-container'>
                <Router isMobile={true} />
            </div>
        )
    )
}
