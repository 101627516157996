import usePageInit from '../../hooks/usePageInit';
import useWindowSize from '../../hooks/useWindowSize';
import './AboutPage.css';

export default function AboutPage() {
    usePageInit('About Us - Prop Firm Journal');
    const [size] = useWindowSize();
    return (
        <main className='fade-in'>
            <div className='about-container'>
                <div className='about'>
                    <div className='who-we-are'>
                        <div className='left'>
                            <div className='text-label' style={{ width: '95px' }}>About us</div>
                            <div className='h-font'>Who we are</div>
                            <div className='s-font'>
                                We are the definitive source of news and information through the lens of prop firms, traders, influencers and technology vendors, global forces that shape the industry and are key to understanding it.
                            </div>
                        </div>
                        <div className='right'>
                            <img className='company-img' src ='assets/about-us.jpg' alt='About Us' />
                        </div>
                    </div>
                    <div className='our-team'>
                        <div className='team-set'>
                            <div className='team-member-container'>
                                <div className='team-member'>
                                    <div className='h-font' style={{width: '100%'}}>Our Team</div>
                                    <div className='img' style={{ backgroundImage: `url('assets/angelo.png')` }}></div>
                                    <div className='s-font name'>Angelo Ciaramello</div>
                                    <div className='s-font'>Founder</div>
                                </div>
                                <div className='team-member'>
                                    <div className='h-font'>&nbsp;</div>
                                    <div className='img' style={{ backgroundImage: `url('assets/gian.png')` }}></div>
                                    <div className='s-font name'>Gianluca Panza</div>
                                    <div className='s-font'>Prop Firm Analyst</div>
                                </div>
                                {
                                    size >= 680 &&
                                    <div className='team-member'>
                                        <div className='h-font'>&nbsp;</div>
                                        <div className='img' style={{ backgroundImage: `url('assets/joao.png')` }}></div>
                                        <div className='s-font name'>João Cardoso</div>
                                        <div className='s-font'>Content Creator</div>
                                    </div>
                                }
                                
                            </div>
                            {
                                size < 680 && 
                                <div className='team-member-container'>
                                    <div className='team-member'>
                                        <div className='h-font'>&nbsp;</div>
                                        <div className='img' style={{ backgroundImage: `url('assets/joao.png')` }}></div>
                                        <div className='s-font name'>João Cardoso</div>
                                        <div className='s-font'>Content Creator</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
