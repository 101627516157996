import axios from "axios";
import { BASE_URL } from "../constants/constants";

export const getCompanies = () => axios.get(`${BASE_URL}dashboard/companies`);
export const getPrograms = companyId => axios.get(`${BASE_URL}dashboard/programs?companyId=${companyId}`);
export const getExplore = programId => axios.get(`${BASE_URL}dashboard/explore?programId=${programId}`);
export const getRankigns = (type, page, sort) => axios.get(`${BASE_URL}dashboard/rankings/1?type=${type}&page=${page}&sort=${sort}`);
export const scrapeRankings = () => axios.post(`${BASE_URL}dashboard/rankings/scrape`);
export const getRankingsScraped = (page, sort, dir) => axios.get(`${BASE_URL}dashboard/rankings/2?page=${page}&sort=${sort}&dir=${dir}`);
export const getOurPrograms = (id) => axios.get(`${BASE_URL}dashboard/ourPrograms${id ? '?id=' + id : ''}`);
export const getSizes = () => axios.get(`${BASE_URL}dashboard/sizes`);
export const getPrices = () => axios.get(`${BASE_URL}dashboard/prices`);
export const getTypes = () => axios.get(`${BASE_URL}dashboard/types`);
export const getDailyLosses = () => axios.get(`${BASE_URL}dashboard/dailyLosses`);
export const getMaxLosses = () => axios.get(`${BASE_URL}dashboard/maxLosses`);
export const getPhaseTargets = (num) => axios.get(`${BASE_URL}dashboard/phaseTargets?num=${num}`);
export const getProgramsBySize = (page, size, sortBy, sortDir, forChart) => axios.get(`${BASE_URL}dashboard/programsBySize?page=${page}&size=${size}${sortBy && sortBy !== 'none' ? '&sortBy=' + sortBy : ''}${sortDir && sortDir !== 'none' ? '&sortDir=' + sortDir : ''}${forChart ? '&forChart=true' : ''}`)
export const postMatch = (body) => axios.post(`${BASE_URL}dashboard/match`, body);
export const getCompetitions = () => axios.get(`${BASE_URL}dashboard/competitions`);
export const getPlatforms = () => axios.get(`${BASE_URL}dashboard/platforms`);