import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { postSubscribe } from '../../requests/home.requests';
import { LayoutStoreInstance } from '../../stores/LayoutStore';
import './SubscriptionFrom.css';

export const SubscriptionFrom = observer(({ isProgramsPage }) => {
    const [form, setForm] = useState({ name: '', email: '' });
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const subscribed = localStorage.getItem('pfjSub');
        subscribed && setSuccess(true);
    }, []);

    const subscribe = e => {
        e.preventDefault();
        const email = form.email;
        if (email === undefined || email === null || /^\s*$/.test(email)) {
            setError({ ...error, email: 'Email cannot be empty' });
            return;
        }

        if (!String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setError({ ...error, email: 'Email has invalid format' });
            return;
        }

        postSubscribe(form).then(() => {
            setSuccess(true);
            setForm({ name: '', email: '' });
            setError(null);
            localStorage.setItem('pfjSub', 'true');
            LayoutStoreInstance.setSubscribed();
        }, error => {
            console.error(error);
        });
    }
    return (
        <div className={"subscribe-section-container " + (!isProgramsPage ? 'programs-origin' : '')}>
            <div className="subscribe-section">
                {
                    isProgramsPage
                        ?
                        <>
                            {
                                (success || LayoutStoreInstance.subscribed)
                                    ? (<div className="s-font" style={{ marginTop: '20px' }}>Thanks for your subscription!</div>)
                                    : (
                                        <>
                                            <div className="s-font"><span>Not ready to start a program?</span> That's ok!</div>
                                            <div className="s-font">Get updates from our mailing list</div>
                                        </>
                                    )
                            }

                        </>
                        :
                        <>
                            <div className="s-font"><span>Good things are coming. Be the first to know when!</span></div>
                            {(success || LayoutStoreInstance.subscribed) && <div className="s-font" style={{ marginTop: '20px' }}>Thanks for your subscription!</div>}
                        </>

                }
                {(success || LayoutStoreInstance.subscribed) ||
                    <form>
                        <div className="inputs">
                            <div className="form-field">
                                <input type="text" value={form.name} name="name" maxLength="200" placeholder="Name" onChange={e => setForm({ ...form, name: e.target.value })} />
                                <div className="error"></div>
                            </div>
                            <div className="form-field">
                                <input type="email" value={form.email} name="email" maxLength="320" placeholder="Email"
                                    style={{ border: error && 'red 2px solid' }}
                                    onChange={e => setForm({ ...form, email: e.target.value })} />
                                <div className="error s-font">{error && error.email}</div>
                            </div>

                        </div>
                        <button className="pfj-btn dark" onClick={subscribe}>Subscribe</button>
                    </form>
                }

            </div>
        </div>
    )
});