import './TrustpilotTable.css'

export default function TrustpilotTable({ trustPilotLink, trustPilotStars }) {

    return (
        <>
            {
                trustPilotLink &&
                <div className='trustpilot-container' onClick={() => window.open(trustPilotLink, '_blank')}>
                    <div className='trustpilot-image'>
                        <img src='/assets/trust.png' width='150' alt='Trustpilot' />
                    </div>
                    <div className='trustpilot-data'>
                        {trustPilotStars} out of 5
                    </div>
                </div>
            }
        </>
    )
}
