import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DashboardCacheI } from '../../../../caches/DashboardCache';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import DropdownLoading from '../../../../components/DropdownLoading/DropdownLoading';
import Icon from '../../../../components/Icon/Icon';
import LoaderMask from '../../../../components/LoaderMask/LoaderMask';
import starSelect from '../../../../hooks/useStarSelect';
import { getExplore } from '../../../../requests/dashboard.requests';
import { LayoutStoreInstance } from '../../../../stores/LayoutStore';
import CeoSection from '../CeoSection/CeoSection';
import AffiliateProgramTable from '../Tables/AffiliateProgramTable';
import GeneralChallengeTable from '../Tables/GeneralChallengeTable';
import MobilePriceTable from '../Tables/MobilePriceTable/MobilePriceTable';
import PayoutsTable from '../Tables/PayoutsTable';
import ProgramTable from '../Tables/ProgramTable';
import RestrictionsTable from '../Tables/RestrictionsTable';
import ScalingProgramTable from '../Tables/ScalingProgramTable';
import SocialTable from '../Tables/SocialTable';
import TraderSupportTable from '../Tables/TraderSupportTable';
import TrustpilotTable from '../Tables/TrustpilotTable/TrustpilotTable';
import WebsiteTable from '../Tables/WebsiteTable';
import './ExploreMobileLayout.css'

export default function ExploreMobileLayout() {
    const [companies, setCompanies] = useState();
    const [programs, setPrograms] = useState();
    const [companiesLoaded, setCompaniesLoaded] = useState(false);
    const [programsLoaded, setProgramsLoaded] = useState(false);
    const [programsLoaded2, setProgramsLoaded2] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [companyStarFilled, setCompanyStarFilled] = useState(false);
    const [maxPropFirms, setMaxPropFirms] = useState(false);
    const [explore, setExplore] = useState(null);
    const [firstExploreLoaded, setFirstExploreLoaded] = useState(false);

    const [defaultCompanyId, setDefaultCompanyId] = useState();
    const [defaultProgramId, setDefaultProgramId] = useState();
    const [platforms, setPlatforms] = useState();

    const location = useLocation();

    useEffect(() => {
        const defCompanyId = location.state?.companyId;
        const defProgramId = location.state?.programId;

        DashboardCacheI.getPlatforms().then(platforms => {
            setPlatforms(platforms);
        });

        DashboardCacheI.getCompanies().then(data => {
            if (data) {
                setCompanies(data);

                let companyToSelect = data.find(it => it.exploreDefault === true) ?? data[0];
                let companyToSelectId = companyToSelect?._id;
                companyToSelectId && setDefaultCompanyId(companyToSelectId);
                if (defCompanyId) {
                    companyToSelect = data.find(it => it._id === defCompanyId) ?? data[0];
                    companyToSelectId = companyToSelect?._id;
                    setDefaultCompanyId(defCompanyId);
                }
                const selected = {label: companyToSelect.companyName, value: companyToSelect._id};
                setSelectedCompany(selected);
                starSelect('pfjSelectedCompanies', selected, setCompanyStarFilled, true, undefined, setMaxPropFirms);

                setCompaniesLoaded(true);
                DashboardCacheI.getPrograms(companyToSelectId).then(data2 => {
                    if (data2) {
                        if (defProgramId) {
                            setDefaultProgramId(defProgramId);
                        }
                        setPrograms(data2);
                        setProgramsLoaded(true);
                        setProgramsLoaded2(true);
                    }
                }).catch(err => console.log(err))
            }
        }).catch(err => console.log(err));
    }, []);

    const companyChange = e => {
        setProgramsLoaded2(false);
        DashboardCacheI.getPrograms(e.value).then(data => {
            if (data) {
                setPrograms(data);
            } else {
                setPrograms([]);
            }
            location.state = null;
            setSelectedCompany(e);
            starSelect('pfjSelectedCompanies', e, setCompanyStarFilled, true, undefined, setMaxPropFirms);
            setProgramsLoaded2(true);
        });
    }

    const programChange = e => {
        setSelectedProgram(e);
        e && getExplore(e.value).then(response => {
            if (response && response.data) {
                const exploreData = response.data[0];
                setExplore(exploreData);
                firstExploreLoaded || setFirstExploreLoaded(true);
            }
        });
    }

    return (
        <>
            {(companiesLoaded && programsLoaded && firstExploreLoaded) || <div className='explore-loader'><LoaderMask /></div>}
            <div className='ml-explore fade-in' style={{ height: (companiesLoaded && programsLoaded && firstExploreLoaded) ? 'unset' : '2000px' }}>
                <div className='ml-explore-header'>
                    <div className='h-font'>Explore</div>
                    <div className='company-logo-container' onClick={() => explore?.propFirm?.affiliateLink && window.open(explore?.propFirm?.affiliateLink)} style={{ 'backgroundColor': explore?.propFirm?.color2, cursor: 'pointer' }}>
                        {
                            explore?.propFirm?.companyLogoUrl &&
                            <div className='company-logo' style={{ backgroundImage: `url('${explore.propFirm.companyLogoUrl}')` }}></div>
                        }
                    </div>
                </div>

                <div className='ml-dropdown-set'>
                    <div className='ml-dropdown'>
                        <div className='ml-dropdown-container'>
                            {
                                companiesLoaded && companies.length > 0 &&
                                <Dropdown
                                    promise={companies}
                                    placeholder={'Select the Company'}
                                    transform={['_id', 'companyName']}
                                    defaultValue={defaultCompanyId}
                                    dontFireChangeOnInit={true}
                                    onChange={companyChange} />
                            }
                        </div>
                        {
                            companiesLoaded &&
                            <div className={'selection-icon ' + (maxPropFirms && !companyStarFilled ? 'max star-tooltip' : '')} onClick={() => starSelect('pfjSelectedCompanies', selectedCompany, setCompanyStarFilled, false, LayoutStoreInstance.setSelectedCompanies, setMaxPropFirms)}>
                                <Icon name={'star'} style={{ width: '21px', fill: companyStarFilled ? 'inherit' : 'none', stroke: 'black' }} />
                                {
                                    maxPropFirms && !companyStarFilled &&
                                    <div className='star-tooltip-text ml-star-tooltip-text'>{'Maximum 10 Favorites Firms Allowed To Select'}</div>
                                }
                            </div>
                        }
                    </div>
                    <div className='ml-dropdown'>
                        <div className='ml-dropdown-container'>
                            {
                                programsLoaded2 && programs.length > 0 &&
                                <Dropdown
                                    promise={programs}
                                    placeholder={'Select the Program'}
                                    transform={[{ group: 'programType', map: ['_id', 'programName'] }]}
                                    defaultValue={defaultProgramId}
                                    onChange={programChange} />
                            }
                        </div>
                        {
                            <div style={{ 'width': '21px' }}></div>
                        }
                    </div>
                </div>


                {
                    explore &&
                    <div className='ml-tables-container'>
                        { platforms && <ProgramTable selectedProgram={selectedProgram} exploreData={explore} platforms={platforms} /> }
                        <GeneralChallengeTable exploreData={explore} />
                        <PayoutsTable exploreData={explore} />
                        <RestrictionsTable exploreData={explore} />
                        <ScalingProgramTable exploreData={explore} />
                        <MobilePriceTable exploreData={explore} />
                    </div>
                }

                {
                    explore && selectedCompany &&
                    <div className='ml-banner' onClick={() => explore.propFirm.affiliateLink && window.open(explore.propFirm.affiliateLink, '_blank')}>
                        <div className='ml-logo-container' style={{ 'backgroundColor': explore.propFirm.color2 }}>
                            {
                                explore.propFirm.companyLogoUrl && <div className='company-logo' style={{ backgroundImage: `url('${explore.propFirm.companyLogoUrl}')` }}></div>
                            }
                        </div>
                        <div className='ml-text-container' style={{ 'backgroundColor': explore.propFirm.color1, 'color': explore.propFirm.textColor }}>
                            {`Click here to start your challenge with ${selectedCompany.label}`}
                            {
                                explore.propFirm.promo &&
                                <span className='promo'>{explore.propFirm.promo}</span>
                            }
                        </div>
                    </div>
                }

                {
                    explore?.affiliateProgram &&
                    <div className='affiliate-mobile-table'>
                        <AffiliateProgramTable affiliateProgram={explore.affiliateProgram} isMobile={true} />
                        <div style={{ padding: '10px 10px 10px 20px', fontSize: '14px', background: 'white', borderRadius: '0 0 10px 10px', borderTop: 'var(--main-light-darker) 2px solid' }}>
                            <span style={{ fontFamily: 'Articulat Medium' }}>Withdrawal Methods:<br /></span>
                            <span className='font-1'>{explore?.affiliateProgram?.withdrawalMethods}</span>
                        </div>
                    </div>
                }

                {
                    explore &&
                    <div className='ml-trader-section'>
                        <div className='h-font'>{explore?.propFirm?.companyName} Information</div>
                        <div className='ml-trader'>
                            <TraderSupportTable companyData={explore.propFirm} />
                            <SocialTable companyData={explore.propFirm} />
                            <WebsiteTable companyData={explore.propFirm} />
                            <TrustpilotTable
                                trustPilotLink={explore.propFirm.trustPilotLink}
                                trustPilotStars={explore.propFirm.trustPilotStars} />
                        </div>
                    </div>
                }

                {
                    explore && <CeoSection propFirmData={explore.propFirm} />
                }

            </div>
        </>
    )
}