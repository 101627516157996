import usePageInit from '../../hooks/usePageInit';
import './Legal.css'

export function PrivacyPolicy() {
    usePageInit('Privacy Policy - Prop Firm Journal');
    return (
        <main>
            <div className="legal-template">
                <div className='legal-title legal-bold'>
                    PRIVACY POLICY
                </div>
                <div className='legal-text'>
                    Prop Firm Journal, LLC (“PFJ”) and Flow Business Solutions, SPC (“FBS”, and collectively together with PFJ, “we”
                    or  “us”)  respect  your privacy and are committed  to  ensuring  that  consumers  who  access our website and/or use  or
                    access products or services offered by us (“you” or “your”) are informed regarding the way their personal data is used.
                    This  Privacy  Policy  (this  “Policy”)  applies  to  PFJ,  https://propfirmjournal.com  (the  “Site”),  and  all  products  and
                    services offered by PFJ, and governs personal data collection and usage by us. For the purposes of this Policy, unless
                    otherwise noted, all references to PFJ include the Site and PFJ. The Site is an ecommerce website. By using the Site,
                    you consent to the data practices described in this Policy and all revisions or amendments made to this Policy from
                    time to time. PFJ and FBS provide assistance to individuals with disabilities to communicate effectively with them.
                    Please contact PFJ and FBS through their respective e-mail addresses: <a href='mailto:support@propfirmjournal.com'>support@propfirmjournal.com</a> and <a href='mailto:flowbusinesssolutionsmanagement@gmail.com'>flowbusinesssolutionsmanagement@gmail.com</a>.
                </div>
                <div className='legal-text'>
                    <ul className='legal-bold' style={{ marginLeft: '15px' }}>
                        <li>Collection of your Personal Information</li>
                        <li>Use of your Personal Information </li>
                        <li>Sharing of your Personal Information</li>
                        <li>Tracking User Behavior </li>
                        <li>Automatically Collected Information</li>
                        <li>Use of Cookies</li>
                        <li>Third-Party Links</li>
                        <li>Security and Retention of Your Personal Information</li>
                        <li>Your Choices </li>
                        <li>Changes to this Notice</li>
                        <li>Contact Information</li>
                        <li>Additional Information For California Residents</li>
                    </ul>
                </div>
                <br />
                <div className='legal-text'>
                    <div className='legal-bold'>Collection of your Personal Information</div>
                    <div>
                        We collect information about you in a variety of ways depending on how you interact with us, the Site and our services,
                        including:
                        <ol style={{ marginLeft: '15px' }}>
                            <li>Directly from you when you provide it to us, such as when you register for an account, sign up to receive communications from us, start a challenge or contact us by phone, email, or otherwise;</li>
                            <li>Automatically through the use of cookies, server logs, and other similar technologies when you interact with the Site, promotional material, advertisements and emails; and</li>
                            <li>From other sources, including, for example, our affiliates, business partners, service providers, online social media networks, and other third parties, or from publicly available sources. For example, if you submit a job application, or become an employee, we may conduct a background check.</li>
                        </ol>
                        <br />
                        In order to better provide you with products and services offered, we may collect personally identifiable information,
                        such as the following, which sets forth the types of personal information we collect and how we use the information:
                        <table className='legal-table' border={1}>
                            <thead className='legal-bold'>
                                <th>Context</th>
                                <th>Types of Data </th>
                                <th>Primary Purpose for Collection and Use of Data</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='legal-bold'>Account Registration</td>
                                    <td>We may collect your name, financial
                                        information, and contact information when
                                        you create an account. We also collect
                                        information relating to the actions that you
                                        perform while logged into your account. </td>
                                    <td>We have a legitimate interest in providing
                                        account related functionalities to our users
                                        and preventing illegal conduct.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Biometric Identifiers</td>
                                    <td>We may collect certain biometric
                                        information about you, such as imagery of
                                        the iris, retina, fingerprint, face, hand, palm,
                                        vein patterns, and voice recordings, from
                                        which an identifier template, such as a
                                        faceprint, a minutiae template, or a
                                        voiceprint, can be extracted, and keystroke
                                        patterns or rhythms, gait patterns, or
                                        rhythms, and sleep, health, or exercise data
                                        that contain identifying information. </td>
                                    <td>We are required to collect this information
                                        to meet our legal obligations and have a
                                        legitimate interest in doing so and
                                        preventing fraud.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Client Information</td>
                                    <td>We collect the names and contact
                                        information of our clients and their
                                        employees with whom we may interact.</td>
                                    <td>We have a legitimate interest in contacting
                                        our clients and communicating with them
                                        concerning normal business administration
                                        such as projects, services, and billing.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Cookies and FirstParty Tracking</td>
                                    <td>We use cookies and clear GIFs. “Cookies”
                                        are small pieces of information that a website
                                        sends to a computer's hard drive while a
                                        website is viewed. </td>
                                    <td>We have a legitimate interest in making our
                                        website operate efficiently. </td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Cookies and Third-Party Tracking</td>
                                    <td>We may place tracking technology on our
                                        website that collects analytics, records how
                                        you interact with our website, or allows us to
                                        participate in behavior-based advertising.
                                        This means that a third party uses technology
                                        (e.g., a cookie) to collect information about
                                        your use of our website so that they can
                                        report analytics to us or provide advertising
                                        about products and services tailored to your
                                        interests. That third party might also collect
                                        information over time and across different
                                        websites in order to serve advertisements on
                                        our website or other websites.  </td>
                                    <td>Where required by law, we base the use of
                                        third-party cookies upon consent.  </td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Demographic Information</td>
                                    <td>We collect personal information, such as
                                        your date of birth, race or location. </td>
                                    <td>We have a legitimate interest in ensuring our
                                        users are legitimate and to prevent
                                        fraudulent and illegal activity. </td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Email Interconnectivity</td>
                                    <td>If you receive email from us, we use certain
                                        tools to capture data related to when you
                                        open our message, click on any links or
                                        banners it contains and make purchases. </td>
                                    <td>We have a legitimate interest in
                                        understanding how you interact with our
                                        communications to you.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Employment</td>
                                    <td>If you apply for a job posting, or become an
                                        employee, we collect information necessary
                                        to process your application or to retain you
                                        as an employee. This may include, among
                                        other things, your Social Security Number.
                                        Providing this information is required for
                                        employment. </td>
                                    <td>We use information about current
                                        employees to fulfil our obligations to
                                        employees. In some contexts, we are also
                                        required by law to collect information about
                                        employees or applicants. We also have a
                                        legitimate interest in using your information
                                        to have efficient staffing and work force
                                        operations.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Feedback/Support</td>
                                    <td>If you provide us feedback or contact us for
                                        support, we will collect your name and email address, as well as any other content that you
                                        send to us, in order to reply. </td>
                                    <td>We have a legitimate interest in receiving,
                                        and acting upon, your feedback or issues.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Mobile Devices</td>
                                    <td>We collect information from your mobile
                                        device such as unique identifying
                                        information broadcast from your device
                                        when visiting our website or when visiting
                                        one of our stores.</td>
                                    <td>We have a legitimate interest in identifying
                                        unique visitors, and in understanding how
                                        users interact with us on their mobile
                                        devices.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Order Placement </td>
                                    <td>We collect your name, billing address, email
                                        address, phone number, and credit card
                                        number when you place an order.</td>
                                    <td>We use your information to perform our
                                        contract to provide you with products or
                                        services</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Partner Promotion </td>
                                    <td>We collect information that you provide as
                                        part of a co-branded promotion with another
                                        company.</td>
                                    <td>We have a legitimate interest in fulfilling our
                                        promotions.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Public Health and
                                        Safety </td>
                                    <td>In certain situations where a serious public
                                        health threat has been identified, we may
                                        collect information from employees, guests,
                                        and other individuals accessing our facilities.
                                        This may include medical and health
                                        information, such as body temperature,
                                        symptoms, and underlying health conditions.
                                        In some cases, we may also collect medical,
                                        health, and related information about an
                                        employee’s children, family member, or
                                        other individuals under the employee’s care.</td>
                                    <td>We have a legitimate interest in protecting
                                        the health and safety of our employees and
                                        guests. In some jurisdictions we may be
                                        required by law, regulation, or governmental
                                        order to collect and retain information
                                        related to issues of public health and safety.
                                        We have a legitimate interest in complying
                                        with the laws in the jurisdictions in which
                                        we operate.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Surveys</td>
                                    <td>When you participate in a survey, we collect
                                        information that you provide through the
                                        survey. If the survey is provided by a thirdparty service provider, the third party's
                                        privacy policy applies to the collection, use,
                                        and disclosure of your information.</td>
                                    <td>We have a legitimate interest in
                                        understanding your opinions and collecting
                                        information relevant to our organization.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Sweepstakes or
                                        contests</td>
                                    <td>When you participate in a sweepstakes or
                                        contest, we collect information about you
                                        which includes contact information to notify
                                        you if you are selected.</td>
                                    <td>We have a legitimate interest in operating
                                        the sweepstakes and contests. In some
                                        contexts, we are also required by law to
                                        collect information about those that enter
                                        into our sweepstakes and contests, and we
                                        have a legitimate interest in complying with
                                        those laws.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Website
                                        interactions</td>
                                    <td>We use technology to monitor how you
                                        interact with our website. This may include
                                        which links you click on, or information that
                                        you type into our online forms. This may
                                        also include information about your device
                                        or browser.</td>
                                    <td>We have a legitimate interest in
                                        understanding how you interact with our
                                        website to better improve it, and in
                                        understanding your preferences and interests
                                        to select offerings that you might find most
                                        useful. We also have a legitimate interest in
                                        detecting and preventing fraud.</td>
                                </tr>
                                <tr>
                                    <td className='legal-bold'>Web logs</td>
                                    <td>We collect information, including your
                                        browser type, operating system, Internet
                                        Protocol (IP) address (a number that is
                                        automatically assigned to a computer when
                                        the internet is used), domain name, clickactivity, referring website, and/or a date/time
                                        stamp for visitors.</td>
                                    <td>We have a legitimate interest in monitoring
                                        our networks and the visitors to our
                                        websites. Among other things, it helps us
                                        understand which of our services is the most
                                        popular.</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <span>
                            We collect this data and provide it to third parties, as discussed below, if it is a necessary step in the user's account
                            creation process, contributes to the ease of use, reliability, security, or functionality of our products. If you purchase
                            our products or services, we collect billing and credit card information. This information is used to complete the
                            purchase transaction or issue payments to you.
                            <br /><br />
                            You may be required to provide certain personal information to us when you elect to use certain products or services.
                            We also may gather additional personal or non-personal information in the future for the same purpose.
                        </span>
                    </div>
                </div>
                <br />
                <div className='legal-text'>
                    <div className='legal-bold'>Use of your Personal Information</div>
                    <div>
                        We collect and use your personal information to:
                        <ol style={{ marginLeft: '15px' }}>
                            <li>Operate and deliver the products or services you have requested.</li>
                            <li>We may also use your personally identifiable information to inform you of other products or services
                                available from PFJ and its affiliates.</li>
                            <li>To identify you when you visit our Sites or use our products or services. </li>
                            <li>To improve our services and product offerings. </li>
                            <li>To conduct analytics.</li>
                            <li>To communicate with you, such as to respond to and/or follow-up on your requests, inquiries, issues, or feedback. </li>
                            <li>To detect and protect against malicious, deceptive, fraudulent, or illegal activity, including violations of our <a href="/terms-of-use">Terms of Use</a>, the <a href="http://help.propfirmjournal.com/">PFJ Knowledge Center rules and conditions</a>, other policies, security incidents, and harm
                                to the rights, property, or safety of our company and our users, employees, or others.</li>
                            <li>To debug, identify and repair errors that impair existing intended functionality of our website and services</li>
                            <li>To comply with our legal or regulatory obligations, to establish or exercise our rights, and to defend against
                                a legal claim.</li>
                            <li>For internal administrative purposes, as well as to manage our relationships.</li>
                            <li>For such other purposes as you may consent (from time to time).</li>
                        </ol><br />
                        Although the sections above describe our primary purpose in collecting your information, in many situations we have
                        more than one purpose. As a result, our collection and processing of your information may be based in different
                        contexts depending on your consent, our need to fulfil a contract, our obligations under law, and/or our legitimate
                        interest in conducting our business. You explicitly consent to sharing your data by us and/or our affiliates with other
                        proprietary trading firms or third parties for the purposes of detecting fraudulent, deceptive, malicious, or criminal
                        behavior or any violations of our Terms or this Agreement.
                    </div>
                </div>
                <br />
                <div className='legal-text'>
                    <div className='legal-bold'>Sharing Information with Third Parties</div>
                    <div>
                        In addition to the specific situations discussed elsewhere in this Statement, we may disclose personal information in
                        the following situations:
                        <ol style={{ marginLeft: '15px' }}>
                            <li><span className='legal-bold'>Affiliates and Acquisitions.</span> We may share information with our corporate affiliates (e.g., parent company,
                                sister companies, subsidiaries, joint ventures, or other companies under common control). If another
                                company acquires, or plans to acquire, our company, business, or our assets, we will also share information
                                with that company, including at the negotiation stage. </li>
                            <li><span className='legal-bold'>Other Disclosures without Your Consent.</span> We may disclose information in response to subpoenas,
                                warrants, or court orders, or in connection with any legal process, or to comply with relevant laws. We may
                                also share your information in order to establish or exercise our rights, to defend against a legal claim, to
                                investigate, prevent, or take action regarding possible illegal activities, suspected fraud, safety of person or
                                property, or a violation of our policies, or to comply with your request for the shipment of products to or the
                                provision of services by a third-party intermediary.</li>
                            <li><span className='legal-bold'>Public.</span> Our website may provide the opportunity to post comments, or reviews, in a public forum. If you
                                decide to submit information on these pages, that information may be publicly available.</li>
                            <li><span className='legal-bold'>Service Providers.</span> We may share your information with service providers. Among other things service
                                providers may help us to administer our website, conduct surveys, provide technical support, process
                                payments, and assist in the fulfillment of orders.</li>
                            <li><span className='legal-bold'>Other trading platforms.</span> We may share information with other trading platforms and/or related affiliates
                                in order to detect and protect against malicious, deceptive, fraudulent, or illegal activity or violations of our <a href="/terms-of-use">Terms of Use</a>, the <a href="http://help.propfirmjournal.com/">PFJ Knowledge Center rules and conditions</a>, and other policies. </li>
                            <li><span className='legal-bold'>Other Disclosures with Your Consent.</span> We may disclose your information to other third parties when we
                                have your consent or direction to do so.</li>
                        </ol>
                    </div>
                </div>
                <div className='legal-text'>
                    <div className='legal-bold'>Tracking User Behavior</div>
                    We may track and record the websites and pages our users visit, in order to determine what our services are the most
                    popular. This data is used to deliver customized content and advertising to customers whose behavior indicates that
                    they are interested in a particular subject area.
                    <div className='legal-bold'>Automatically Collected Information</div>
                    Information about your computer hardware and software may be automatically collected by us. This information can
                    include: your IP address, browser type, domain names, access times and referring website addresses. This information
                    is used for the operation of the service, to maintain the quality of the service, and to provide general statistics regarding
                    use of our website.
                    <div className='legal-bold'>Use of Cookies</div>
                    Our website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed
                    on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer.
                    Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to
                    you.<br />
                    One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie
                    is to tell the Web server that you have returned to a specific page. For example, if you personalize the webpages, or
                    register with our site or services, a cookie helps us to recall your specific information on subsequent visits. This
                    simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so
                    on. When you return to the same website, the information you previously provided can be retrieved, so you can easily
                    use the features that you customized.<br />
                    You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can
                    usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not
                    be able to fully experience the interactive features of our services or websites you visit. Additionally, depending on
                    where you live, you may have the right to restrict certain cookies using the cookie banner on our website.
                    <div className='legal-bold'>Third-Party Links</div>
                    This website contains links to other sites. Please be aware that we are not responsible for the content or privacy
                    practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy
                    statements of any other site that collects personally identifiable information.
                    <div className='legal-bold'>Security and Retention of your Personal Information</div>
                    We take reasonable security measures to protect against unauthorized access to or alteration of your personal
                    information. Notwithstanding the foregoing, no data transmission over the Internet or any wireless network can be
                    guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge
                    that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b)
                    security, integrity, and privacy of any and all information and data exchanged between you and us through this Site
                    cannot be guaranteed. In the event that we are required by law to inform you of a breach to your personal information
                    we may, at our discretion, notify you electronically, in writing, or by telephone, if permitted to do so by law.
                </div>
                <div className='legal-text'>
                    <div className='legal-bold'>Your Choices</div>
                    <div>
                        Subject to certain exceptions, and only for those individuals who meet specific jurisdictional and legal requirements,
                        you have the right to make the following requests:
                        <ol style={{ marginLeft: '15px' }}>
                            <li><span className='legal-bold'>Access to Your Personal Information.</span> You may request access to your personal information or
                                confirmation that we have information about you. In certain limited circumstances, you may also request to
                                receive access to your data in a portable, machine-readable format.</li>
                            <li><span className='legal-bold'>Changes to Your Personal Information.</span> We rely on you to update and correct your personal information.
                                Most of our websites allow you to modify or delete your account profile. If our website does not permit you
                                to update or correct certain information, you can contact us at the address described below to request that
                                your information be modified. You may ask us to correct information that is inaccurate or incomplete. Note
                                that we may keep historical information in our backup files as permitted by law. </li>
                            <li><span className='legal-bold'>Deletion of Your Personal Information.</span> You may request that we delete your personal information. If
                                required by law, we will grant a request to delete information, but you should note that in many situations we
                                must keep your personal information to comply with our legal obligations, resolve disputes, enforce our
                                agreements, or for other business purposes. </li>
                            <li><span className='legal-bold'>Opt-out of Selling or Sharing for Targeted Advertising.</span> You may opt-out of online tracking based targeted
                                advertising (e.g., cookies) by clicking the “Reject” button in the “We value your privacy” window. Please
                                note that if you change browsers or computers, or if you clear your browser’s cache, you may need to click
                                the link again to apply your preference. You can also reject cookies on our cookie banner. Please note that
                                not all sharing or disclosure of your personal information is a “sale” and not all sharing or disclosure is subject
                                to the right to opt out.</li>
                            <li><span className='legal-bold'>Objection to Certain Processing.</span> You may object to our use or disclosure of your personal information by
                                contacting us at the address described below.  </li>
                            <li><span className='legal-bold'>Online Tracking.</span> We do not currently recognize the “Do Not Track” signal. </li>
                            <li><span className='legal-bold'>Promotional Emails.</span> From time to time, we may contact you via email for the purpose of providing
                                announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. If
                                you would like to stop receiving marketing or promotional communications via email from us, you may opt
                                out of such communications by clicking on the “unsubscribe” button found at the bottom of each email. </li>
                            <li><span className='legal-bold'>Revocation of Consent.</span> Where we process your personal information based upon consent, you may revoke
                                consent. Please note, if you revoke your consent for the processing of personal information then we may no
                                longer be able to provide you with the Services.</li>
                        </ol>
                    </div>
                    <br />
                    <div>
                        To exercise any of your rights, you may email us at support@propfirmjournal.com, Attn: Data Privacy Officer. In
                        some circumstances, you may authorize an agent to exercise your rights by emailing us on your behalf. If you use an
                        agent to submit an opt out request, you must provide the agent with signed written permission demonstrating that they
                        have been authorized by you to act on your behalf. We may require more information to ensure proper verification of
                        you and your agent's identity and authorization. Depending on your request, we will ask for information such as your
                        name, information about the last interaction you had with us, or the date of your last purchase from us. We may also
                        ask you to provide a signed declaration confirming your identity. If you are utilizing an agent, we require a signed and
                        notarized permission from you stating that the agent has authorization to act on your behalf. We will deny the request
                        if we are unable to verify the requestor or the authorized agent.
                    </div>
                </div>
                <br />
                <div className='legal-text'>
                    <div className='legal-bold'>Changes to this Statement</div>
                    <div>
                        We reserve the right to amend this Policy from time to time. We will notify you about significant changes in the way
                        we treat personal information by sending a notice to the primary email address specified in your account, by placing
                        a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website
                        and/or Services available after any such modifications will constitute your: (a) acknowledgment of the revised Policy;
                        and (b) agreement to abide and be bound by such Policy as revised.
                    </div>
                </div>
                <br />
                <div className='legal-text'>
                    <div className='legal-bold'>Contact Information</div>
                    <div>
                        We welcome your questions or comments regarding this Statement. If you have any questions or comments, please
                        contact us at:<br />
                        Prop Firm Journal, LLC<br />
                        Attn: Data Privacy Officer<br />
                        1657 N Miami Ave, Unit 911-A<br />
                        Miami, FL 33136<br />
                        Email Address:<br />
                        <a href="mailto:support@propfirmjournal.com">support@propfirmjournal.com</a>
                    </div>
                    <br />
                    <div>
                        Flow Business Solutions, SPC<br />
                        Attn: Mourant<br />
                        94 Solaris Avenue<br />
                        Camana Bay<br />
                        P.O. Box 1348<br />
                        Grand Cayman KY1-1108<br />
                        Cayman Islands<br />
                        Email Address: <a href="mailto:flowbusinesssolutionsmanagement@gmail.com">flowbusinesssolutionsmanagement@gmail.com</a>
                    </div>
                </div>
                <br />
                <div className='legal-text'>
                    <div className='legal-bold'><u>Additional Information For California Residents</u></div>
                    <div>
                        California law requires us to disclose the following additional information related to our privacy practices. If you are
                        a California resident, the following privacy disclosures apply to you in addition to the rest of the Privacy Policy.
                        <ul style={{ marginLeft: '15px' }}>
                            <li>
                                <div className='legal-bold'>California Shine the Light.</div>
                                If you would like more information concerning the categories of personal
                                information (if any) we share with third parties or affiliates for those parties to use for direct marketing, please
                                submit a written request to us using the information in the <div className='legal-bold'><u>Contact Information</u></div>section above.
                            </li>
                            <li>
                                <div className='legal-bold'>Notice of Collection.</div>
                                The table below describes the categories of personal information we collect, disclose for a
                                business purpose, “sell” and/or “share” (as those terms are defined by California law). Please note, in addition to
                                the recipients identified below, we may disclose any of the categories of personal information we collect with
                                government entities, as may be needed to comply with law or prevent illegal activity. We may sell or lease
                                customer information to third parties for the following reasons:
                                <ul style={{ marginLeft: '15px' }}>
                                    <li>
                                        We may, from time to time, contact you on behalf of external business partners about a particular
                                        offering that may be of interest to you. In those cases, your unique personally identifiable
                                        information (e-mail, name, address, telephone number) is transferred to the third party.
                                    </li>
                                    <li>
                                        We may, if we in our discretion consider it potentially beneficial to you, share personal data or
                                        information with our affiliates or trusted third-party partners in order to provide you with further
                                        educational, marketing and promotional offers we believe may be of interest to you. You are always
                                        entitled to opt-out from receiving such offers.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div className='legal-bold'>Fraud Prevention.</div>
                                You explicitly consent to PFJ and FBS sharing your personal information with other
                                proprietary trading firms or third parties for the purposes of detecting fraudulent, deceptive, malicious, or criminal
                                behavior or any violations of our Terms or this Agreement.
                            </li>
                        </ul>
                    </div>
                </div>
                <br />
                <div className='legal-text'>
                    <table className='legal-table' border={1}>
                        <tbody>
                            <tr>
                                <td className='legal-bold' rowSpan={2}>Category of Personal Information</td>
                                <td className='legal-bold' colSpan={3} style={{ textAlign: 'center' }}>Category of Recipients</td>
                            </tr>
                            <tr>
                                <td className='legal-bold'>Disclosures for Business Purposes </td>
                                <td className='legal-bold'>Sharing for CrossContext Behavioral Advertising</td>
                                <td className='legal-bold'>Sales</td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Identifiers - this may include
                                    real name, alias, postal
                                    address, unique personal
                                    identifier, online identifier,
                                    email address, account name,
                                    or other similar identifiers
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other service
                                    providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations - this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                                <td>
                                    1. Business
                                    Partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Government Issued
                                    Identification - this may
                                    include social security number,
                                    driver's license number, or
                                    state issued identification
                                    number, passport number.
                                </td>
                                <td>
                                    1. Service providers<br />
                                    2. Professional
                                    services
                                    organizations - this
                                    may include auditors
                                    and legal counsel
                                </td>
                                <td>None</td>
                                <td>None</td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Financial Information - this
                                    may include bank account
                                    number, credit card number,
                                    debit card number, and other
                                    financial information.
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Internet service
                                    providers<br />
                                    4. Operating systems
                                    and platforms<br />
                                    5. Other service
                                    providers<br />
                                    6. Payment processors
                                    and financial
                                    institutions<br />
                                    7. Professional
                                    services
                                    organizations - this may include auditors
                                    and legal counsel
                                </td>
                                <td>None</td>
                                <td>None</td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Characteristics of protected
                                    classifications - this may
                                    include age, sex, race,
                                    ethnicity, physical, or mental
                                    handicap, etc.
                                </td>
                                <td>
                                    1. Service providers<br />
                                    2. Payment processors
                                    and financial
                                    institutions<br />
                                    3. Professional
                                    services
                                    organizations - this
                                    may include auditors
                                    and legal counsel
                                </td>
                                <td>None</td>
                                <td>None</td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Commercial information – this
                                    may include information about
                                    products or services
                                    purchased, obtained, or
                                    considered, or other
                                    purchasing or consuming
                                    histories or tendencies.
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other service
                                    providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations, this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Biometric information – this
                                    may include imagery of the
                                    iris, retina, fingerprint, face,
                                    hand, palm, vein patterns, and
                                    voice recordings, from which
                                    an identifier template, such as
                                    a faceprint, a minutiae
                                    template, or a voiceprint, can
                                    be extracted, and keystroke
                                    patterns or rhythms, gait
                                    patterns, or rhythms, and
                                    sleep, health, or exercise data
                                    that contain identifying
                                    information
                                </td>
                                <td>1. Service providers</td>
                                <td>None</td>
                                <td>None</td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Internet or other electronic
                                    network activity information –
                                    this may include browsing
                                    history, search history, and
                                    information regarding an individual’s interaction with
                                    an internet website,
                                    application, or advertisement.
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other service
                                    providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations, this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Geolocation data
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other Service
                                    Providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations, this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>
                                    None
                                </td>
                                <td>
                                    None
                                </td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Professional or employmentrelated information
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other Service
                                    Providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations, this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>None</td>
                                <td>None</td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Inferences drawn from any of
                                    the information listed above
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other Service
                                    Providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations, this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                                <td>
                                    1. Business
                                    partners<br />
                                    2. Joint
                                    marketing
                                    partners
                                </td>
                            </tr>
                            <tr style={{ 'verticalAlign': 'top' }}>
                                <td className='legal-bold'>
                                    Additional categories of
                                    personal information
                                    described in
                                    the California Customer
                                    Records statute
                                    (Cal. Civ. Code § 1798.80(e)) –
                                    this may include signature,
                                    physical characteristics, or
                                    description, , insurance policy
                                    number.
                                </td>
                                <td>
                                    1. Affiliates or
                                    subsidiaries<br />
                                    2. Business partners<br />
                                    3. Data analytics
                                    providers<br />
                                    4. Data brokers<br />
                                    5. Internet service
                                    providers<br />
                                    6. Joint marketing
                                    partners<br />
                                    7. Operating systems
                                    and platforms<br />
                                    8. Other Service
                                    Providers<br />
                                    9. Payment processors
                                    and financial
                                    institutions<br />
                                    10. Professional
                                    services
                                    organizations, this
                                    may include auditors
                                    and legal counsel<br />
                                    11. Social networks
                                </td>
                                <td>None</td>
                                <td>None</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <ul style={{ marginLeft: '15px' }}>
                        <li>
                            California Sensitive Information Disclosure. We collect the following categories of sensitive personal
                            information (as defined under California law): your biometric data. This information is collected to process
                            transactions, comply with laws, manage our business, or provide you with services. Note that we do not use
                            such information for any purposes that are not identified within the California Privacy Rights Act Section
                            1798.121. We do not “sell” or “share” sensitive personal information for purposes of cross-context behavioral
                            advertising.
                        </li>
                    </ul>
                    <br />
                    <br />
                    <div className='legal-bold'>Effective Date: October 3, 2023.</div><br/>
                    <div className='legal-bold'>Last Update: October 3, 2023.</div>
                </div>
                <br/>
            </div>
        </main>
    )
}