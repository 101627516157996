import "./MenuButton.css"

export default function MenuButton({ opened, click }) {

    return (<>
        <div className="menu-button-handler" onClick={click}></div>
        <input type="checkbox" id="menu" checked={opened} className="menu-trigger" readOnly />
        <label htmlFor="menu" className="menu-icon">
            <div className="menu-internal"></div>
        </label>
    </>
    )
}