import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";
import thousands from "../../../../hooks/thousands"
import isEmpty from "../../../../hooks/isEmpty"

export default function ScalingProgramTable({ exploreData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        const maxFundedAllocationExists =
            exploreData.maxFundedAllocation !== 'N/A' &&
            !isEmpty(exploreData.maxFundedAllocation);

        let mfa = 'N/A';
        if (maxFundedAllocationExists) {
            mfa = '$' + thousands(exploreData.maxFundedAllocation);
        }

        const scalingAllocationExists =
            exploreData.maxAllocationWithScaling !== null &&
            exploreData.maxAllocationWithScaling !== undefined

        let sfa = 'N/A';
        if (scalingAllocationExists) {
            if (exploreData.maxAllocationWithScaling === 1000000000) {
                sfa = 'Unlimited';
            }
            else {
                sfa = thousands(String(exploreData.maxAllocationWithScaling))
            }

            sfa = sfa !== 'Unlimited' ? '$' + sfa : sfa;
        }

        const data = [{
            pairs: [
                { name: 'Requirements for Scale', value: exploreData.scalingPlanFirstProfitTarget },
                { name: 'Benefits', value: exploreData.scalingPlanBenefits },
                { name: 'Max Funded Allocation', value: mfa },
                { name: 'Max Scaling Allocation', value: sfa },
                { name: 'Time to 1M from Max Allocation', value: exploreData.minTimeToGet1MFromMaxAllocation }
            ]
        }];

        setTable(data);

    }, [exploreData])

    return (
        <div className="scaling-program-table">
            <DataGrid
                titleSettings={{ text: 'Scaling Program' }}
                tableSettings={{ columnSeparator: true }}
                dataSets={table} />
        </div>
    )
}