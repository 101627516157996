import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MIN_COLLAPSE_WIDTH } from '../../constants/constants';
import Icon from '../Icon/Icon';
import './HorCardSlider.css'

export default function HorCardSlider({ cards }) {
    const navigate = useNavigate();
    const sliderRef = useRef();

    const [cardWidth, setCardWidth] = useState(0);

    const hit = () => {
        const slider = sliderRef.current;
        const cardsCh = slider.children;
        const cardWidth = cardsCh[0].offsetWidth;
        const cardPerView = window.innerWidth < MIN_COLLAPSE_WIDTH ? 2 : Math.round(slider.offsetWidth / cardWidth);
        const cardsArr = Array.from(cardsCh)

        cardsArr.slice(-cardPerView).reverse().forEach(card => {
            slider.insertAdjacentHTML("afterbegin", card.outerHTML);
        });

        cardsArr.slice(0, cardPerView).forEach(card => {
            slider.insertAdjacentHTML("beforeend", card.outerHTML);
        });

        slider.classList.add("no-transition");
        slider.scrollLeft = slider.offsetWidth;
        slider.classList.remove("no-transition");

        setCardWidth(cardWidth);
    };

    useEffect(hit, []);

    const scroll = () => {
        if (sliderRef.current.scrollLeft === 0) {
            sliderRef.current.classList.add("no-transition");
            sliderRef.current.scrollLeft = sliderRef.current.scrollWidth - (2 * sliderRef.current.offsetWidth);
            sliderRef.current.classList.remove("no-transition");
        }
        else if (Math.ceil(sliderRef.current.scrollLeft) === sliderRef.current.scrollWidth - sliderRef.current.offsetWidth) {
            sliderRef.current.classList.add("no-transition");
            sliderRef.current.scrollLeft = sliderRef.current.offsetWidth;
            sliderRef.current.classList.remove("no-transition");
        }
    }

    return (
        <div className='card-slider-wrapper'>
            <div className='arrow left' onClick={() => sliderRef.current.scrollLeft += -cardWidth}>
                <Icon name={'forward'} style={{ width: '20px', fill: 'white' }} />
            </div>
            <ul className="slider" ref={sliderRef} onScroll={scroll}>
                {
                    cards && cards.map(it => {
                        return (
                            <li className='card'
                                key={it.key}
                                title={it.title}
                                onClick={() => navigate(it.url, { state: it.state })}>
                                <div className='img' style={{ 'backgroundColor': it?.color2, border: 'black 1px solid' }}>
                                    <img
                                        src={it.image}
                                        draggable="false"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = 'assets/no-image-96.png'
                                        }}
                                        alt={it.title} />
                                </div>
                                <div className='caption' style={{ 'backgroundColor': it?.color1, 'color': it?.textColor }}>
                                    <div className='title font-1'>{it.title}</div>
                                    <div className='details font-1'>{it.details}</div>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
            <div className='arrow right' onClick={() => sliderRef.current.scrollLeft += cardWidth}>
                <Icon name={'forward'} style={{ width: '20px', fill: 'white' }} />
            </div>
        </div>
    )
}