import HomeImpCard from '../../../components/HomeImpCard/HomeImpCard';
import './ImportantSection.css';

export default function ImportantSection() {
    return (
        <div className='important-metrics-container'>
            <div className='important-metrics'>
                <div className='text-label' style={{ width: '95px' }}>Key metrics</div>
                <div className='font-2 h-font'>We track what is important.</div>
                <div className='important-metrics-set'>
                    <HomeImpCard icon={'drawdown'} title='Drawdown & Targets' description='Profit Targets and Loss Limits' />
                    <HomeImpCard icon={'scaling'} title='Price & Scaling Plans' description='How much you have to pay, and how much can you can get' />
                    <HomeImpCard icon={'restriction'} title='Trading Restrictions' description='Trading styles allowed' />
                    <HomeImpCard icon={'support'} title='Support Care' description='Rating and Support offered' />
                </div>
            </div>
        </div>
    )
}