import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function PayoutsTable({ exploreData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        let firstWithdrawal = exploreData.firstWithdrawal;
        if (exploreData.firstWithdrawalNum !== null && exploreData.firstWithdrawalNum !== undefined) {
            firstWithdrawal = exploreData.firstWithdrawalNum === -1 ? 'On Demand' : `After ${exploreData.firstWithdrawalNum} Days`;
        }
        const data = [{
            rows: [
                { cells: [{ value: 'Refund' }, { value: exploreData.refund }] },
                { cells: [{ value: 'First Profit Split' }, { value: exploreData.firstProfitSplitNum + '%' }] },
                { cells: [{ value: 'First Withdrawal' }, { value: firstWithdrawal === null || firstWithdrawal === undefined ? 'N/A' : firstWithdrawal }] },
                { cells: [{ value: 'Profits From Demo' }, { value: exploreData.profitsFromChallengePer }], profitsFromChallenge: true },
                { cells: [{ value: 'Max Profit Split' }, { value: exploreData.maxProfitSplit + '%' }] },
                { cells: [{ value: 'Min Days To Payout' }, { value: exploreData.minTimeToPayout }] },
            ]
        }];

        exploreData.profitsFromChallenge || (data[0].rows = data[0].rows.filter(r => !r.profitsFromChallenge));
        setTable(data);

    }, [exploreData])

    return (
        <div className="payouts-table">
            <DataGrid
                titleSettings={{ text: 'Payouts' }}
                tableSettings={{ columnSeparator: true, height: '380px' }}
                dataSets={table} />
        </div>
    )
}