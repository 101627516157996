import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function WebsiteTable({ companyData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {

        const url = companyData.companyWebsiteUrl.endsWith('/') ? companyData.companyWebsiteUrl.slice(0, -1) : companyData.companyWebsiteUrl;
        const languagesCell = companyData.websiteInOtherLanguages !== true
            ? [{ value: 'Website in other languages?' }, { value: false, type: 'boolean' }]
            : [{ value: 'Website in other languages?' }, { type: 'tooltip', tooltip: companyData.languagesForWebsite }]
        const data = [{
            rows: [
                { cells: [{ value: url, link: companyData.affiliateLink }] },
                { cells: [{ value: 'Own App?' }, { value: companyData.ownMobileApp, type: 'boolean' }] },
                { cells: languagesCell }
            ]
        }];

        setTable(data);

    }, [companyData])

    return (
        <div className="website-table">
            <DataGrid
                titleSettings={{ text: 'Website', mode: 'hr' }}
                dataSets={table} />
        </div>
    )
}