import { useState } from 'react';
import Icon from '../Icon/Icon';
import './Paginator.css';

export default function Paginator({ totalPages, pageSetNum, pageChange }) {
    const [curPage, setCurPage] = useState(0);
    const [numbers, setNumbers] = useState([...Array(pageSetNum).keys()]);
    const [rightPeriod, setRightPeriod] = useState(true);
    const [leftPeriod, setLeftPeriod] = useState(false);
    const [showPrev, setShowPrev] = useState(false);
    const [showNext, setShowNext] = useState(true);

    const select = p => {
        if (p >= 0 && p < pageSetNum - 1) {
            setNumbers([...Array(pageSetNum).keys()]);
            setLeftPeriod(false);
            setRightPeriod(true);
        }
        else if (p === numbers[numbers.length - 1] && p < totalPages - 1) {
            setNumbers([...Array(pageSetNum).keys()].map(it => it += p))
            setLeftPeriod(true);
            setRightPeriod(p < totalPages - pageSetNum);
        }
        else if ((p === numbers[0] || p === numbers[0] - 1) && p !== 0) {
            const numbers = [];
            for (let i = p; i > p - pageSetNum; i--) {
                numbers.unshift(i + 1);
            }
            setNumbers(numbers);
            setLeftPeriod(true);
            setRightPeriod(true);
        }
        else if (p === totalPages - 1) {
            const numbers = [];
            for (let i = totalPages - 1; i > totalPages - 1 - pageSetNum; i--) {
                numbers.unshift(i);
            }
            setNumbers(numbers);
            setLeftPeriod(true);
            setRightPeriod(false);
        }
        setShowPrev(p !== 0);
        setShowNext(p !== totalPages - 1);
        setCurPage(p);
        pageChange && pageChange(p);
    };

    return (
        <div className='paginator'>
            {
                showPrev &&
                <button className='pg-pagination-btn' onClick={() => select(curPage - 1)} style={{ 'transform': 'rotate(180deg)', 'paddingRight': '1px' }}>
                    <Icon name={'forward'} style={{ width: '20px', fill: 'white' }} />
                </button>
            }
            {
                leftPeriod && totalPages > pageSetNum &&
                <div className={'num ' + (0 === curPage ? 'cur' : '')} onClick={() => 0 !== curPage && select(0)}>{1}</div>
            }
            {
                leftPeriod && totalPages > pageSetNum &&
                <div>...</div>
            }
            {
                numbers &&
                <div className='paginator-numbers'>
                    {
                        numbers.map(i => {
                            if (i < totalPages) {
                                return (
                                    <div className={'num ' + (i === curPage ? 'cur' : '')} key={i} onClick={() => i !== curPage && select(i)}>{i + 1}</div>
                                )
                            } else {
                                return ''
                            }
                        })
                    }
                </div>
            }
            {
                rightPeriod && totalPages > pageSetNum &&
                <div>...</div>
            }
            {
                rightPeriod && totalPages > pageSetNum &&
                <div className={'num ' + (totalPages - 1 === curPage ? 'cur' : '')} onClick={() => totalPages - 1 !== curPage && select(totalPages - 1)}>{totalPages}</div>
            }
            {
                showNext &&
                <button className='pg-pagination-btn' onClick={() => select(curPage + 1)} style={{ 'paddingRight': '1px' }}>
                    <Icon name={'forward'} style={{ width: '20px', fill: 'white' }} />
                </button>
            }
        </div>
    )
}