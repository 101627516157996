import { useState } from 'react';
import { useEffect } from 'react';
import usePageInit from '../../../hooks/usePageInit';
import { DashboardCacheI } from '../../../caches/DashboardCache';
import './CompetitionPage.css';
import LoaderMask from '../../../components/LoaderMask/LoaderMask';

export default function CompetitionPage() {
    usePageInit('Competitions - Prop Firm Journal');

    const [data, setData] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        DashboardCacheI.getCompetitions().then(data => {
            setLoaded(true);
            if (data) {
                setData(data);
            }
        }).catch(err => {
            console.error(err);
            setLoaded(true);
        })
    }, [])
    return (
        <>
            {
                !loaded &&
                <div className='explore-loader'><LoaderMask /></div>
            }
            <div className='compare-container fade-in' style={{ 'overflow': 'hidden', paddingBottom: '50px' }}>
                <div className='competitions-section'>
                    <div className='h-font'>Competitions</div>
                    <div className='s-font'>Test your trading skills! Explore this section to discover companies offering Free Competitions, where you can put your abilities to the test, gain valuable experience, and potentially win exciting prizes.</div><br/>
                    <div className='competitions-container'>
                        {
                            data?.length > 0 && data.map((it, i) => {
                                return (
                                    <div key={i} className='competition-card'>
                                        <div className='competition-card-logo-container' style={{ backgroundColor: it.color2 }}>
                                            <div className='competition-card-logo' style={{ backgroundImage: `url("${it.companyLogoUrl}")` }}></div>
                                        </div>
                                        <div className='competition-card-text-block'>
                                            <div className='font-1' style={{ fontFamily: 'Articulat Medium', fontSize: '20px' }}>{it.companyName}</div>
                                            <div className='s-font'>{!it.competitionPrice || it.competitionPrice === '' ? 'Free Competition' : it.competitionPrice}</div>
                                        </div>
                                        {
                                            it.affiliateLink &&
                                            <button style={{ margin: '10px 0 20px 0', 'alignSelf': 'center' }} onClick={() => window.open(it.affiliateLink)} className='pfj-btn dark'>Register</button>
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            data?.length === 0 &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '100px 0'}}>
                                <div className='font-1' style={{fontFamily: 'Articulat Medium'}}>No competitions are available for now</div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}