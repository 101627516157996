import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function RestrictionsTable({ exploreData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {
        const lotExplained = exploreData.lotSizeRestriction && 
                             exploreData.lotSizeRestrictionExplained !== undefined &&
                             exploreData.lotSizeRestrictionExplained !== null &&
                             exploreData.lotSizeRestrictionExplained !== "" ? exploreData.lotSizeRestrictionExplained : null;
        const data = [{
            rows: [
                { cells: [{ value: 'Stop Loss Required' }, { value: exploreData.slRequired, type: 'boolean' }] },
                { cells: [{ value: 'Lot Size Restriction' }, { value: exploreData.lotSizeRestriction, type: lotExplained ? 'tooltip' : 'boolean', tooltip: lotExplained }] },
                { cells: [{ value: 'News Trading' }, { value: exploreData.newsTrading, type: 'boolean' }] },
                { cells: [{ value: 'Ea\'s Allowed' }, { value: exploreData.easAllowed, type: 'boolean' }] },
                { cells: [{ value: 'Weekend Holding' }, { value: exploreData.weekendHolding, type: 'boolean' }] },
                { cells: [{ value: 'Crypto Trading On Weekends' }, { value: exploreData.cryptoTradingOnWeekends, type: 'boolean' }] },
                { cells: [{ value: 'Copy Trading' }, { value: exploreData.copyTrading, type: 'boolean' }] },
                { cells: [{ value: 'Hedging' }, { value: exploreData.hedging, type: 'boolean' }] },
            ]
        }];

        if (exploreData.otherRestrictions) {
            data[0].rows.push({ cells: [{ value: 'Other...' }, { type: 'tooltip', tooltip: exploreData.otherRestrictions }] });
        }

        setTable(data);
    }, [exploreData])

    return (
        <div className="restrictions-table">
            <DataGrid
                titleSettings={{ text: 'Restrictions' }}
                dataSets={table} />
        </div>
    )
}