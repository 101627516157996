import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MIN_COLLAPSE_WIDTH } from '../../constants/constants';
import MenuButton from "../../components/MenuButton/MenuButton";
import Router from "../../components/Router/Router";
import useWindowSize from "../../hooks/useWindowSize";
import "./LayoutPage.css";
import MenuPanel from "../../components/MenuPanel/MenuPanel";
import Icon from "../../components/Icon/Icon";
import { Footer } from "./FooterSection/Footer";
import { LayoutStoreInstance } from "../../stores/LayoutStore";

export default function LayoutPage() {
    const [width] = useWindowSize();
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpened, setMenuOpened] = useState(false);

    const closeMenuOnBodyClick = (e) => {
        e.target?.className === 'menu-button-handler' || setMenuOpened(false);
    }

    useEffect(() => {
        document.body.addEventListener('click', closeMenuOnBodyClick);
        window.addEventListener('scroll', closeMenuOnBodyClick);

        setMenuOpened(false);
        LayoutStoreInstance.setFooterStartPanel(!location.pathname?.includes('/dashboard/'));

        return function cleanup() {
            document.body.removeEventListener('click', closeMenuOnBodyClick);
            window.removeEventListener('scroll', closeMenuOnBodyClick);
        };
        // eslint-disable-next-line
    }, [location])

    return (
        <>
            {width < MIN_COLLAPSE_WIDTH && <MenuPanel opened={menuOpened} />}
            <header className="menu-container">
                <div className="menu-panel">
                    <div className="icon" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                        <Icon name={'long-logo'} style={
                            {
                                width: width < MIN_COLLAPSE_WIDTH ? '135px' : '220px',
                                font: 'white',
                                rectFill: 'white',
                                rectFont: 'black'
                            }} />
                    </div>
                    {
                        width < MIN_COLLAPSE_WIDTH
                            ? <MenuButton opened={menuOpened} click={() => setMenuOpened(!menuOpened)} />
                            : <Router isMobile={false} />}
                </div>
            </header>

            <Outlet />

            <Footer />
        </>
    )
}