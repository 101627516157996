import { useEffect } from 'react'
import { useState } from 'react'
import thousands from '../../hooks/thousands'
import './OurProgramTable.css'

export function OurProgramTable({ data }) {
    const [selected, setSelected] = useState()

    useEffect(() => {
        setSelected(data.sizes[0])
    }, [data])

    return (
        <div className='price-table-section'>
            <div className='h-font'>Account Size</div>
            <div className='price-table-size-label-set'>
                {
                    data?.sizes?.length > 0 && data.sizes.map((it, i) => {
                        return (
                            <button
                                key={i}
                                className={'price-table-size-label' + (it?.size === selected?.size ? ' selected' : '')}
                                onClick={() => setSelected(it)}
                            >${thousands(it.size.toString())}</button>
                        )
                    })
                }
            </div>
            <div className='price-table-size'>
                {
                    selected &&
                    <table>
                        <thead>
                            <th></th>
                            {
                                data?.steps?.length > 0 && data.steps.map((it, i) => {
                                    return (
                                        <th key={i}>
                                            <div className='price-table-header-cell'>
                                                <div className='h-font' style={{ fontSize: '22px' }}>Step {i + 1}</div>
                                                <div style={{ fontFamily: 'Articulat Medium', fontSize: '14px' }}>{it.title}</div>
                                            </div>
                                        </th>
                                    )
                                })
                            }
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Trading Period</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {it.tradingPeriod}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Minimum Trading Days</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {it.minTradingDays === null ? 'X' : it.minTradingDays + ' Days'}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Trading Leverage</td>
                                <td colSpan={data.steps.length}>{data.leverage}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Daily Loss Type</td>
                                <td colSpan={data.steps.length}>{data.dailyLossType}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Maximum Daily Loss</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                ${thousands((selected.size * (it.maxDailyLoss / 100)).toFixed(0))} / {it.maxDailyLoss}%
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Maximum Overall Loss Type</td>
                                <td colSpan={data.steps.length}>{data.maxOverallLossType}</td>
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Maximum Overall Loss</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                ${thousands((selected.size * (it.maxOverallLoss / 100)).toFixed(0))} / {it.maxOverallLoss}%
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Profit Target</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {
                                                    it.profitTarget !== null &&
                                                    <> ${thousands((selected.size * (it.profitTarget / 100)).toFixed(0))} / {it.profitTarget}%</>
                                                }
                                                {
                                                    it.profitTarget == null &&
                                                    <>X</>
                                                }
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Weekend Holding</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {it.weekendHolding ? 'Yes' : 'No'}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>News Trading</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {it.newsTrading ? 'Yes' : 'No'}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>First Withdrawal</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {it.firstWithdrawal === null ? 'X' : it.firstWithdrawal + ' Days'}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6' }}>Profit Split</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{ background: (i % 2 !== 0 ? '#f6f6f6' : '') }}>
                                                {it.profitSplit === null ? 'X' : it.profitSplit}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <td style={{ fontFamily: 'Articulat Medium', background: '#f6f6f6', borderBottomLeftRadius: '10px' }}>Challenge Fee</td>
                                {
                                    data?.steps?.length > 0 && data.steps.map((it, i) => {
                                        return (
                                            <td key={i} style={{
                                                background: (i % 2 !== 0 ? '#f6f6f6' : ''),
                                                fontFamily: 'Articulat Medium',
                                                borderBottomRightRadius: i === data.steps.length - 1 ? '10px' : 0
                                            }}>
                                                {
                                                    i === 0 &&
                                                    <>${thousands(selected.firstStepFee.toString())}</>
                                                }
                                                {
                                                    i > 0 &&
                                                    <>{it.fee}</>
                                                }
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
            {
                selected &&
                <button onClick={() => selected.buyLink && window.open(selected.buyLink) } className='pfj-btn dark' style={{ alignSelf: 'center', width: '160px', marginTop: '30px' }}>
                    {'Buy ' + (selected.size / 1000).toFixed(0) + 'K Challenge'}
                </button>
            }

        </div>
    )
}