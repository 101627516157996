import HorCardSlider from '../../../components/HorCardSlider/HorCardSlider';
import './SelectionSection.css';

export default function SelectionSection({ selection }) {
    return (
        selection &&
        <div className='selection-container'>
            <div className='selection'>
                <div className='text-label' style={{ width: '120px' }}>Our Selection</div>
                <div className='font-2 h-font'>Selected Challenges</div>
                <HorCardSlider cards={selection} />
            </div>
        </div>
    )
}