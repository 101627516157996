import "./DashboardPage.css";
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom'
import Icon from "../../components/Icon/Icon";
import usePageInit from "../../hooks/usePageInit";
import getNewsDate from "../../hooks/useNewsDate";
import { useEffect } from "react";
import { LayoutCacheI } from '../../caches/LayoutCache';
import { useState } from "react";
import { LayoutStoreInstance } from "../../stores/LayoutStore";
import { observer } from "mobx-react-lite";
import useWindowSize from "../../hooks/useWindowSize";
import key from "../../hooks/key";
import { MIN_COLLAPSE_WIDTH } from "../../constants/constants";

export const DashboardPage = observer(() => {
    const [news, setNews] = useState(null);
    const [width] = useWindowSize();
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedCompaniesState, setSelectedCompaniesState] = useState(false);

    const closeSelectedPanelOnBodyClick = (e) => {
        e.target?.id === 'favPropFirmsBtn' || e.target?.id === 'fav-companies' ||
            setSelectedCompaniesState(false);
    }

    useEffect(() => {
        window.innerWidth >= MIN_COLLAPSE_WIDTH && LayoutCacheI.getHomeNews()
            .then(data => {
                data && setNews(data);
            })
            .catch(e => {
                console.log('Error: ', e);
            });

        if (localStorage.getItem('pfjSelectedCompanies') === undefined || localStorage.getItem('pfjSelectedCompanies') === null) {
            localStorage.setItem('pfjSelectedCompanies', JSON.stringify([]));
        }

        if (LayoutStoreInstance.selectedCompanies?.length === 0) {
            const lsc = localStorage.getItem('pfjSelectedCompanies') || null;
            LayoutStoreInstance.setSelectedCompanies(lsc && JSON.parse(lsc));
        }

        document.body.addEventListener('click', closeSelectedPanelOnBodyClick);
        window.addEventListener('scroll', closeSelectedPanelOnBodyClick);

        return function clearEvents() {
            document.body.removeEventListener('click', closeSelectedPanelOnBodyClick);
            window.removeEventListener('scroll', closeSelectedPanelOnBodyClick);
        };
    }, []);

    const selectCompany = (id) => {
        navigate('/dashboard/explore', { state: { companyId: id } });
        if (location.pathname === '/dashboard/explore') {
            window.location.reload();
        }
    }

    return (
        <>
            <main className="dashboard-page-main">
                {
                    LayoutStoreInstance.selectedCompanies?.length > 0 && width < MIN_COLLAPSE_WIDTH &&
                    <div className="selected-top-panel" style={{ opacity: (width < MIN_COLLAPSE_WIDTH ? '1' : '0') }}>
                        <div>
                            <div onClick={() => { setSelectedCompaniesState(!selectedCompaniesState); }}>
                                <Icon name={'star'} style={{ width: '21px', fill: 'white', stroke: 'white' }} />
                            </div>
                            <div id="favPropFirmsBtn" onClick={() => { setSelectedCompaniesState(!selectedCompaniesState); }}>
                                Favorite Prop Firms
                            </div>
                            <div id="fav-companies" className="selected-popup" style={{ 'visibility': selectedCompaniesState ? 'visible' : 'hidden' }}>
                                <ol style={{ 'marginLeft': '15px', 'marginTop': '-10px' }}>
                                    {
                                        LayoutStoreInstance.selectedCompanies && LayoutStoreInstance.selectedCompanies.map(it => {
                                            return (
                                                <li key={key()} style={{ 'marginTop': '15px' }} onClick={() => selectCompany(it.value)}>{it.label}</li>
                                            )
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                }
                <main className="dashboard-page" style={{ paddingTop: LayoutStoreInstance.selectedCompanies?.length > 0 && width < 680 ? '0' : '50px' }}>
                {
                        width >= MIN_COLLAPSE_WIDTH &&
                        <div className="dashboard-left">
                            <div className="dashboards">
                                <div className="group">
                                    <div className="font-2">Dashboards</div>
                                    <div className="list">
                                        <div className="item">
                                            <div className="font-1">
                                                <NavLink exact="true" to="explore" activeclassname='active'>
                                                    <Icon name={'explore'} style={{ width: '28px', fill: 'white' }} />
                                                    <div>Explore</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="font-1 nav-parent">
                                                <Icon name={'compare'} style={{ width: '26px', fill: 'white' }} />
                                                <div>Compare</div>
                                            </div>
                                            <div className="font-1">
                                                <NavLink exact="true" to="compare" activeclassname='active'>
                                                    <div style={{marginLeft: '60px', fontSize: '14px' }}>Challenge</div>
                                                </NavLink>
                                            </div>
                                            <div className="font-1">
                                                <NavLink exact="true" to="compare-by-price" activeclassname='active'>
                                                    <div style={{marginLeft: '60px', fontSize: '14px' }}>By Price</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="font-1">
                                                <NavLink exact="true" to="rankings" activeclassname='active'>
                                                    <Icon name={'rankings'} style={{ width: '30px', fill: 'white' }} />
                                                    <div>Rankings</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="font-1">
                                                <NavLink exact="true" to="match" activeclassname='active'>
                                                    <Icon name={'match'} style={{ width: '30px', fill: 'white' }} />
                                                    <div>Match</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="font-1">
                                                <NavLink exact="true" to="competitions" activeclassname='active'>
                                                    <Icon name={'cup'} style={{ width: '30px', fill: 'white' }} />
                                                    <div>Competitions</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ border: 'rgb(95, 95, 95) 1px solid' }}></hr>
                                {
                                    news?.length > 0 &&
                                    <div className="group">
                                        <div className="font-2">Updates</div>
                                        <div className="items">
                                            {
                                                news && news.map(it => {
                                                    return (
                                                        <div
                                                            style={{ 'cursor': 'pointer' }}
                                                            onClick={() => window.open('/news/' + it._id, '_self')} key={key()}
                                                            className="dashboard-right-item">
                                                            <div className="title">{it.title}</div>
                                                            <div className="subtitle">{getNewsDate(it.date)}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    LayoutStoreInstance.selectedCompanies && LayoutStoreInstance.selectedCompanies.length > 0 &&
                                    <div className="group">
                                        <div className="font-2">Your favorite Prop Firms</div>
                                        <div className="items">
                                            {
                                                LayoutStoreInstance.selectedCompanies.map(it => {
                                                    return (
                                                        <div key={key()} className="dashboard-right-item-ls" onClick={() => selectCompany(it.value)}>
                                                            <Icon name={'star'} style={{ width: '21px', fill: 'rgb(191, 191, 191)', stroke: 'rgb(191, 191, 191)' }} />
                                                            <div className="ls-item">{it.label}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className={"dashboard" + (location.pathname === '/dashboard/rankings' ? ' rankings' : '')}>
                        <Outlet />
                    </div>
                </main>
            </main>
        </>
    )
});