import { useState } from 'react';
import { useEffect } from 'react';
import ProgramCard from '../ProgramCard/ProgramCard'
import { DashboardCacheI } from '../../caches/DashboardCache'
import './ChallengePrograms.css'

export default function ChallengePrograms({ gradients }) {
    let grads = gradients ? [85, 100] : [];

    const [programs, setPrograms] = useState()

    useEffect(() => {
        DashboardCacheI.getOurPrograms().then(data => {
            setPrograms(data);
        }).catch(err => console.error(err))
    }, [])
    return (
        <div className='challenge-programs'>
            <div className='text-label dark' style={{ width: '260px' }}>EARN AS A PROFESSIONAL TRADER</div>
            <div className='font-2 h-font'>Our Challenge Programs</div>
            <div className='font-1 s-font'>Our Prop Firm has multiple programs to start your journey as a professional Trader. Choose one of our funding programs and start trading, earn bonuses and profit-splits.</div>
            <div className='program-set'>
                {
                    programs && programs?.length && programs?.map((it, i) => {
                        return (
                            <ProgramCard key={i} title={it.shortName} description={it.shortDescription} link={'/programs/' + it._id} opacity={grads[i]} />
                        )
                    })
                }
            </div>
        </div>
    )
}