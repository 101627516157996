import { useRef, useState } from 'react';
import Icon from '../../components/Icon/Icon';
import usePageInit from '../../hooks/usePageInit'
import { postSend } from '../../requests/home.requests';
import './ContactPage.css'

export default function ContactPage() {
    usePageInit('Contact - Prop Firm Journal');
    const [touched, setTouched] = useState(false);

    const [form, setForm] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const contactForm = useRef();

    const validate = ({ name, email, message }) => {
        let error;
        (name === null || name === undefined || /^\s*$/.test(name)) && (error = { ...error, name: 'Name cannot be empty' });
        (email === undefined || email === null || /^\s*$/.test(email)) && (error = { ...error, email: 'Email cannot be empty' });
        !String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) &&
            (error = { ...error, email: 'Email has invalid format' });
        (message === null || message === undefined || /^\s*$/.test(message)) && (error = { ...error, message: 'Message cannot be empty' });
        return error;
    };

    const send = e => {
        e.preventDefault();
        const err = validate(form);
        touched || setTouched(true);
        if (err) {
            setError(err);
            return;
        }
        setLoading(true);
        postSend(form)
            .then(() => {
                setError(null);
                setLoading(false);
                setTouched(false);
                contactForm.current.reset();
                setForm({
                    name: '',
                    email: '',
                    message: ''
                });
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 4000);
            })
            .catch(() => {
                setLoading(false);
                setTouched(false);
            });
    };

    const fillForm = (key, value) => {
        const formObj = { ...form }
        form[key] = value;
        formObj[key] = value;
        if (touched) {
            const err = validate(form);
            err && setError(err);
        } else {
            setForm(formObj);
        }
    };

    return (
        <main className='fade-in'>
            <div className='contact-container'>
                <div className='contact'>
                    <div className='h-font'>Are we missing a Prop Firm?</div>
                    <div className='s-font'>Contact us for promotions and feature content</div>
                    <div className='contact-form-info'>
                        <form ref={contactForm}>
                            <div className='font-2' style={{ fontSize: '20px' }}>Send us a message</div>
                            <div className='inputs'>
                                <div className='form-field'>
                                    <input className={error?.name && 'error-input'} placeholder="Name" name="name" maxLength="200" onChange={e => fillForm('name', e.target.value)} />
                                    <div className="error s-font">{error?.name && error.name}</div>
                                </div>
                                <div className='form-field'>
                                    <input className={error?.email && 'error-input'} type="email" placeholder="Email" name="email" maxLength="320" onChange={e => fillForm('email', e.target.value)} />
                                    <div className="error s-font">{error?.email && error.email}</div>
                                </div>
                                <div className='form-field'>
                                    <label htmlFor='message'>Message</label> <br />
                                    <textarea className={error?.message && 'error-input'} id='message' name='message' maxLength="1000" onChange={e => fillForm('message', e.target.value)}></textarea>
                                    <div className="error s-font">{error?.message && error.message}</div>
                                </div>
                            </div>
                            <button className="pfj-btn dark" onClick={send} disabled={loading}>{loading ? 'Sending...' : 'Send Message'}</button>
                            <div></div>
                            {
                                showSuccess
                                    ? <div className='font-1' style={{
                                        padding: '10px',
                                        backgroundColor: 'var(--main-light)',
                                        borderRadius: '5px',
                                        boxShadow: 'rgba(0, 0, 0, 0.2) 0 0 10px',
                                        fontSize: '16px',
                                        display: 'flex',
                                        gap: '5px',
                                        justifyContent: 'center'
                                    }}>
                                        <Icon name={'check'} style={{ width: '20px' }} />
                                        Your message has been sent!
                                    </div>
                                    : <div style={{ height: '40px' }}></div>
                            }

                        </form>
                        <div className='info'>
                            <div className='font-2' style={{ fontSize: '20px' }}>Contact Information</div>
                            <div className='info-item'>
                                <div className='font-2'>Email</div>
                                <div className='font-1'><a href='mailto:support@propfirmjournal.com'>support@propfirmjournal.com</a></div>
                            </div>
                            <div className='info-item'>
                                <div className='font-2'>Address</div>
                                <div className='font-1'><a href='http://maps.google.com/?q=Miami FL United States' target="_blank" rel='noreferrer'>Miami, FL, United States</a></div>
                            </div>
                            <div className='info-item'>
                                <div className='font-2'>Phone</div>
                                <div className='font-1'><a href='tel:754-247-2562'>754-247-2562</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
