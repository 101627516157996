import { observer } from "mobx-react-lite";
import ProgramsSection from "../HomePage/ProgramsSection/ProgramsSection"
import usePageInit from "../../hooks/usePageInit"
import "./ProgramsPage.css"

export const ProgramsPage = observer(() => {
    usePageInit('Programs - Prop Firm Journal');
    return (
        <main className="programs-page fade-in" style={{ backgroundImage: 'url(assets/bull.png)' }}>
            <ProgramsSection gradients={true} />
        </main>
    )
});
