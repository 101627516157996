import ChallengePrograms from '../../../components/ChallengePrograms/ChallengePrograms';
import './ProgramsSection.css';

export default function ProgramsSection({ gradients }) {
    return (
        <div className={'programs-section ' + (gradients || 'bottom-space')} style={{
            backgroundColor: gradients && 'transparent',
            backgroundImage: gradients || 'url(assets/bull.png)',
            paddingTop: gradients && '70px',
            paddingBottom: gradients && '0'
        }}>
            {gradients || <div className='zig-zag' style={{ transform: 'rotate(180deg)' }}></div>}
            <div className='programs-container'>
                <div className='programs'>
                    <div className='programs-sub'>
                        <ChallengePrograms gradients={gradients} />
                    </div>
                </div>
            </div>
        </div>
    )
}