import { useEffect } from 'react';
import { useState } from 'react';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Icon from '../../../../components/Icon/Icon';
import './PriceCompareTable.css';
import thousands from "../../../../hooks/thousands";
import thousandsShort from "../../../../hooks/thousandsShort";

export default function PriceCompareTable({ exploreData, cellWidth, mobile }) {
    const [tableOpened, setTableOpened] = useState(true);
    const [firstPriceList, setFirstPriceList] = useState(null);
    const [secondPriceList, setSecondPriceList] = useState(null);
    const [thirdPriceList, setThirdPriceList] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState({
        0: null,
        1: null,
        2: null
    });

    useEffect(() => {
        setFirstPriceList(null);
        setSecondPriceList(null);
        setThirdPriceList(null);
        setTimeout(() => {
            [...Array(mobile ? 1 : 3).keys()].forEach(i => {
                if (exploreData && exploreData[i]) {
                    const prices = exploreData[i].prices.map(it => {
                        const size = '$' + thousandsShort(it.accountSize);
                        const price = '$' + thousands(String(it.accountPrice));
                        const pricePerDrawdown = '$' + (it.accountPrice / (it.accountSize * (parseFloat(exploreData[i].maxLossPer === null ? 0 : exploreData[i].maxLossPer) / 100))).toFixed(3);

                        return {
                            _id: it._id,
                            programId: it.programId,
                            size,
                            price,
                            pricePerDrawdown
                        }
                    });

                    i === 0 && setFirstPriceList(prices);
                    i === 1 && setSecondPriceList(prices);
                    i === 2 && setThirdPriceList(prices);
                }
            });
        })

    }, [exploreData, mobile]);

    const onSelect = (e, i) => {
        let list = null;
        i === 0 && (list = firstPriceList);
        i === 1 && (list = secondPriceList);
        i === 2 && (list = thirdPriceList);

        const price = list?.filter(it => it._id === e.value)[0];
        setSelectedPrice({ ...selectedPrice, [i]: price });
    };

    return (
        <div className='dg'>
            <div
                className={'dg-header-fill ' + (!tableOpened ? 'close' : '')}
                style={{ 'cursor': 'pointer' }}
                onClick={() => setTableOpened(!tableOpened)}>
                <div className='font-2 title-text'>Prices</div>
                <div
                    className={'dg-collapse-icon ' + (tableOpened ? 'open' : 'close')}
                    style={{ 'marginLeft': 'auto', 'cursor': 'pointer' }}>
                    <Icon name={'forward'} style={{ width: '20px', fill: 'white', stroke: 'white', strokeWidth: '2' }} />
                </div>
            </div>
            {
                tableOpened && exploreData &&
                <div className='dg-subsets'>
                    <div className='dg-subset'>
                        <table className='dg-table price-compare'>
                            <tbody>
                                <tr>
                                    <td className='font-1' style={{ 'fontFamily': 'Articulat Medium' }}>Account Size</td>
                                    <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                        <div className={'select ' + (firstPriceList || 'na')}>
                                            {
                                                firstPriceList ? <Dropdown
                                                    transform={['_id', 'size']}
                                                    promise={new Promise(resolve => resolve(firstPriceList))}
                                                    onChange={e => onSelect(e, 0)} /> : 'N/A'
                                            }
                                        </div>
                                    </td>
                                    {
                                        mobile ||
                                        <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                            <div className={'select ' + (secondPriceList || 'na')}>
                                                {
                                                    secondPriceList ? <Dropdown
                                                        transform={['_id', 'size']}
                                                        promise={new Promise(resolve => resolve(secondPriceList))}
                                                        onChange={e => onSelect(e, 1)} /> : 'N/A'
                                                }
                                            </div>
                                        </td>
                                    }

                                    {
                                        mobile ||
                                        <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                            <div className={'select ' + (thirdPriceList || 'na')}>
                                                {
                                                    thirdPriceList ? <Dropdown
                                                        transform={['_id', 'size']}
                                                        promise={new Promise(resolve => resolve(thirdPriceList))}
                                                        onChange={e => onSelect(e, 2)} /> : 'N/A'
                                                }
                                            </div>
                                        </td>
                                    }

                                </tr>
                                <tr className='rows-hoverable'>
                                    <td className='font-1' style={{ 'fontFamily': 'Articulat Medium' }}>Price</td>
                                    <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                        {firstPriceList && selectedPrice[0]?.price ? selectedPrice[0].price : 'N/A'}
                                    </td>
                                    {
                                        mobile ||
                                        <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                            {secondPriceList && selectedPrice[1]?.price ? selectedPrice[1].price : 'N/A'}
                                        </td>
                                    }
                                    {
                                        mobile ||
                                        <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                            {thirdPriceList && selectedPrice[2]?.price ? selectedPrice[2].price : 'N/A'}
                                        </td>
                                    }


                                </tr>
                                <tr className='rows-hoverable'>
                                    <td className='font-1' style={{ 'fontFamily': 'Articulat Medium' }}>Price Per Drawdown</td>
                                    <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                        {firstPriceList && selectedPrice[0]?.pricePerDrawdown ? selectedPrice[0].pricePerDrawdown : 'N/A'}
                                    </td>
                                    {
                                        mobile ||
                                        <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                            {secondPriceList && selectedPrice[1]?.pricePerDrawdown ? selectedPrice[1].pricePerDrawdown : 'N/A'}
                                        </td>
                                    }
                                    {
                                        mobile ||
                                        <td className='font-1' style={{ 'borderLeft': 'var(--main-light-darker) 2px solid', 'width': cellWidth, 'justifyContent': 'center', 'textAlign': 'center' }}>
                                            {thirdPriceList && selectedPrice[2]?.pricePerDrawdown ? selectedPrice[2].pricePerDrawdown : 'N/A'}
                                        </td>
                                    }

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}
