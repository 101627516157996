import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import key from '../../hooks/key';
import Icon from '../Icon/Icon';
import './DataGrid.css';

export const DataGrid = observer(({ titleSettings, tableSettings, dataSets, onSort }) => {
    const [tableOpened, setTableOpened] = useState(true);
    const navigate = useNavigate();

    const sort = (by, dir) => {
        if (dir === null || dir === undefined || dir === 'none') {
            onSort({ by, dir: 'asc' });
        }
        else if (dir === 'asc') {
            onSort({ by, dir: 'desc' });
        }
        else if (dir === 'desc') {
            onSort({ by, dir: 'none' });
        }
    };

    return (
        <div className='dg'>
            {
                (!titleSettings?.mode || titleSettings?.mode === 'fill') &&
                <div
                    className={'dg-header-fill ' + (tableSettings?.collapsible && !tableOpened ? 'close' : '')}
                    style={{ 'cursor': (tableSettings?.collapsible ? 'pointer' : 'default') }}
                    onClick={() => tableSettings?.collapsible && setTableOpened(!tableOpened)}>
                    <div className='font-2 title-text'>
                        {!titleSettings?.text ? 'Table title' : titleSettings.text}
                    </div>
                    {
                        tableSettings?.collapsible &&
                        <div
                            className={'dg-collapse-icon ' + (tableOpened ? 'open' : 'close')}
                            style={{ 'marginLeft': 'auto', 'cursor': 'pointer' }}>
                            <Icon name={'forward'} style={{ width: '20px', fill: 'white', stroke: 'white', strokeWidth: '2' }} />
                        </div>
                    }
                </div>
            }
            {
                (titleSettings?.mode === 'hr') &&
                <div className='dg-header-hr'>
                    <div className='title-text'>
                        {!titleSettings?.text ? 'Table title' : titleSettings.text}
                    </div>
                </div>
            }
            {
                dataSets && dataSets.length > 0 && (!tableSettings?.collapsible || (tableSettings.collapsible && tableOpened)) &&
                <div className='dg-subsets'>
                    {
                        dataSets.map(subset => {
                            return (
                                <div key={key()} className='dg-subset' style={{ 'overflowX': subset.horScrolling === true ? 'auto' : 'unset' }}>
                                    {
                                        subset.rows && subset.rows.length === 0 &&
                                        <div className='font-1 dg-no-data'>{subset.noDataText ?? 'No Data'}</div>
                                    }
                                    {
                                        subset.pairs && subset.pairs.length > 0 &&
                                        <div className='dg-pairs'>
                                            {
                                                subset.pairs.map(pair => {
                                                    return (
                                                        <div key={key()} className='dg-pair'>
                                                            <div className='name'>{pair.name}:</div>
                                                            <div className='value'>{pair.value}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        subset.icons && subset.icons.length > 0 &&
                                        <div className='dg-icons'>
                                            {
                                                subset.icons.map(icon => {
                                                    return (
                                                        <a key={key()} href={icon.link} target='_blank' rel="noreferrer">
                                                            <Icon name={icon.name} style={{ width: '32px', fill: 'black' }} />
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        subset.rows && subset.rows.length > 0 &&
                                        <table className='dg-table'>
                                            {
                                                subset.columnHeaders && subset.columnHeaders.length > 0 &&
                                                <thead>
                                                    <tr>
                                                        {
                                                            subset.columnHeaders.map(header => {
                                                                return (
                                                                    <th key={key()}
                                                                        onClick={() => header.sorting && sort(header.key, header.dir)}
                                                                        style={{ cursor: header.sorting && 'pointer' }}>
                                                                        {
                                                                            header.sorting ?
                                                                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                                                                    <div style={{ textDecoration: 'underline' }}>{header.name}</div>
                                                                                    {
                                                                                        header.dir &&
                                                                                        <div style={{ alignSelf: 'center' }}>
                                                                                            <Icon name={header.dir} style={{ width: '20px', fill: 'black' }} />
                                                                                        </div>
                                                                                    }

                                                                                </div> : <>{header.name}</>
                                                                        }

                                                                    </th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                            }
                                            {
                                                <tbody>
                                                    {
                                                        subset.rows.map(row => {
                                                            return (
                                                                <tr key={key()} className={tableSettings?.rowsHoverable ? 'rows-hoverable' : ''}>
                                                                    {
                                                                        row.cells.map((cell, i) => {
                                                                            return (
                                                                                <td
                                                                                    key={key()}
                                                                                    className='font-1'
                                                                                    rowSpan={subset.columnHeaders?.length > 0 ? (subset.columnHeaders[i].rowspan === undefined ? 1 : subset.columnHeaders[i].rowspan) : 1}
                                                                                    colSpan={cell.colspan === undefined ? 1 : cell.colspan}
                                                                                    style={{
                                                                                        'fontFamily': i === 0 && 'Articulat Medium',
                                                                                        'borderLeft': tableSettings?.columnSeparator && i > 0 && 'var(--main-light-darker) 2px solid',
                                                                                        'width': tableSettings?.commonCellWidth && i > 0 && tableSettings.commonCellWidth,
                                                                                        'borderTop': cell.borderTop ? 'var(--main-light-darker) 2px solid' : ''
                                                                                    }}>

                                                                                    <div className='dg-cell' style={{
                                                                                        'justifyContent': i > 0 && 'center',
                                                                                        'textAlign': i > 0 && 'center'
                                                                                    }}>
                                                                                        {
                                                                                            (!cell.type || cell.type === 'string') &&
                                                                                            <div className='dg-string-value'>
                                                                                                {
                                                                                                    cell.link &&
                                                                                                    <a href={cell.link} target='_blank' rel='noreferrer'>
                                                                                                        {cell.value}
                                                                                                    </a>
                                                                                                }
                                                                                                {
                                                                                                    cell.link 
                                                                                                        ? '' 
                                                                                                        : cell.btnLink 
                                                                                                            ? <button 
                                                                                                                className='pfj-btn dark' style={{ padding: '3px 7px', width: 'auto' }}
                                                                                                                onClick={() => window.open(cell.btnLink, '_blank')}>
                                                                                                                    <Icon name={cell.icon} style={{ width: cell.size, fill: 'white' }} />
                                                                                                                </button>
                                                                                                            : !cell.stateLink ? cell.value : ''
                                                                                                }
                                                                                                {
                                                                                                    cell.stateLink &&
                                                                                                    <div>
                                                                                                        {
                                                                                                            cell.stateLink.info
                                                                                                                ? <div onClick={() => navigate(cell.stateLink.link, {state: cell.stateLink.state })}>
                                                                                                                    <Icon name={'info'} style={{width: '24px', fill: 'black'}} />
                                                                                                                  </div>
                                                                                                                : <div
                                                                                                                    onClick={() => navigate(cell.stateLink.link, {state: cell.stateLink.state })}
                                                                                                                    style={{'textDecoration': 'underline', 'cursor': 'pointer' }}>
                                                                                                                        {cell.value}
                                                                                                                  </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    cell.value2 &&
                                                                                                    <div>{cell.value2}</div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            cell.type === 'list' &&
                                                                                            <div className='dg-string-value'>
                                                                                                {
                                                                                                    cell.value !== 'N/A'
                                                                                                        ? cell.value.split('[[break]]').map((r, i) => {
                                                                                                            return (
                                                                                                                <div key={i}>{ r }</div>
                                                                                                            )
                                                                                                        }) : 'N/A'
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            cell.type === 'boolean' &&
                                                                                            <div className={'dg-boolean-value ' + (!cell.value || cell.value === 'N/A' ? 'no' : '')}>
                                                                                                {
                                                                                                    cell.value === true &&
                                                                                                    <Icon name={'check'} style={{ width: '16px', fill: 'white' }} />
                                                                                                }
                                                                                                {
                                                                                                    (!cell.value || cell.value === 'N/A') &&
                                                                                                    <Icon name={'cross'} style={{ width: '15px', fill: 'white' }} />
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            cell.tooltip &&
                                                                                            <div className='tooltip'>
                                                                                                <Icon name={'eye'} style={{ width: '21px' }} />
                                                                                                <div className='tooltip-text'>{cell.tooltip}</div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            cell.type === 'picture' &&
                                                                                            <div className='dg-picture-value' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                <div
                                                                                                    className='ranking-company-logo-container'
                                                                                                    style={{ 'backgroundColor': cell.background ?? 'transparent' }}>
                                                                                                    <div
                                                                                                        className='ranking-company-logo'
                                                                                                        style={{ 'backgroundImage': `url("${cell.picture}")` }}>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    cell.value &&
                                                                                                    <div className='dg-string-value' style={{ textAlign: 'center' }}>
                                                                                                        {cell.value}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            }
                                        </table>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
});