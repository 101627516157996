import { BASE_URL } from '../../../constants/constants';
import './SponsoredSection.css';
import { sanitize } from 'dompurify';

export default function SponsoredSection({ sponsoredChallenge }) {
    return (
        <div className='sp-container'>
            <div className='text-side'>
                <div className='text-label' style={{ width: '80px' }}>Our Goal</div>
                <div className='h-font'>{sponsoredChallenge.title}</div>
                <div dangerouslySetInnerHTML={{__html: sanitize(sponsoredChallenge.descriptionHtml ?? sponsoredChallenge.description)}}></div>
            </div>
            <div
                className={'img-side ' + (sponsoredChallenge.imgLink ? '' : 'without-image')}
                style={{ backgroundImage: sponsoredChallenge.imgLink ? `url("${BASE_URL}image?url=${sponsoredChallenge.imgLink}")` : '' }}
                onClick={() => sponsoredChallenge.affiliateLink && window.open(sponsoredChallenge.affiliateLink, '_blank')}
            ></div>
        </div>
    )
}