import { useEffect } from "react";
import { useState } from "react";
import { DataGrid } from "../../../../components/DataGrid/DataGrid";

export default function TraderSupportTable({ companyData }) {
    const [table, setTable] = useState(null);
    useEffect(() => {

        const data = [{
            pairs: [
                { name: 'Website Live chat Days', value: companyData.customerSupportDays ?? 'N/A' },
                { name: 'Website Live chat Hours', value: companyData.customerSupportHours ?? 'N/A' },
                { name: 'Avarage response time', value: companyData.avgResponseTime ?? 'N/A' }
            ]
        }];

        setTable(data);

    }, [companyData])

    return (
        <div className="trader-support-table">
            <DataGrid
                titleSettings={{ text: 'Support Information', mode: 'hr' }}
                tableSettings={{ columnSeparator: true }}
                dataSets={table} />
        </div>
    )
}