import './HomePage.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import HeadSection from './HeadSection/HeadSection';
import useRedrawOnResize from '../../hooks/useRedrawOnResize';
import ImportantSection from './ImportantSection/ImportantSection';
import AboutSection from './AboutSection/AboutSection';
import ProgramsSection from './ProgramsSection/ProgramsSection';
import SelectionSection from './SelectionSection/SelectionSection';
import MetricsSection from './MetricsSection/MetricsSection';
import SponsoredSection from './SponsoredSection/SponsoredSection';
import NewsSection from './NewsSection/NewsSection';
import LoaderMask from './../../components/LoaderMask/LoaderMask';
import { LayoutCacheI } from "../../caches/LayoutCache";
import usePageInit from '../../hooks/usePageInit';

export default function HomePage() {
    const navigate = useNavigate();
    const [homeData, setHomeData] = useState(null);
    const [selection, setSelection] = useState(null);
    const [news, setNews] = useState(null);
    const [homeDataLoaded, setHomeDataLoaded] = useState(false);
    const [newsLoaded, setNewsLoaded] = useState(false);

    usePageInit('Prop Firm Journal');
    useEffect(() => {
        LayoutCacheI.getHome()
            .then(data => {
                data && setHomeData(data);
                setTimeout(() => {
                    data && data.selectedChallenges
                        && setSelection(data.selectedChallenges.map((it, i) => {
                            return {
                                key: i,
                                url: 'dashboard/explore',
                                state: {
                                    companyId: it.companyId,
                                    programId: it._id
                                },
                                image: it.companyLogoUrl,
                                title: it.programName,
                                details: it.companyName,
                                color1: it.color1,
                                color2: it.color2,
                                textColor: it.textColor
                            }
                        }));
                })
                setHomeDataLoaded(true);
            })
            .catch(e => {
                setHomeDataLoaded(true);
                console.error('Error: ', e);
            });

        LayoutCacheI.getHomeNews()
            .then(data => {
                data && setNews(data);
                setNewsLoaded(true);
            })
            .catch(e => {
                setNewsLoaded(true);
                console.error('Error: ', e);
            });
    }, []);

    useRedrawOnResize(selection, setSelection);

    return (
        <>
            {(newsLoaded && homeDataLoaded) || <LoaderMask />}
            {
                <main className='fade-in'>
                    <HeadSection />
                    <ImportantSection />
                    <AboutSection />
                    <ProgramsSection />
                    <SelectionSection selection={selection} />
                    <MetricsSection metrics={homeData?.metrics} />
                    {homeData?.sponsoredChallenge && <SponsoredSection sponsoredChallenge={homeData.sponsoredChallenge} />}
                    <NewsSection news={news} />
                    {news && news.length > 0 && <button className='news-btn pfj-btn dark' onClick={() => navigate('news')}>More News</button>}
                </main>
            }
        </>
    )
}