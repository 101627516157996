import usePageInit from '../../../hooks/usePageInit';
import useWindowSize from '../../../hooks/useWindowSize';
import Rankings1Table from './Rankings1Table/Rankings1Table';
import Rankings2Table from './Rankings2Table/Rankings2Table';
import './RankingsPage.css';

export default function RankingsPage() {
    usePageInit('Rankings - Prop Firm Journal');
    const [width] = useWindowSize();
    return (
        <>
            <div className='compare-container fade-in'>
                <div className='ranking-tables'>
                    <Rankings1Table />
                    <Rankings2Table />
                    <div style={{textAlign: 'center', marginTop: '-80px'}}>
                        <div style={{'fontFamily': 'Articulat Medium', fontSize: '14px', lineHeight: '22px'}}>Leaderboard Disclaimer</div>
                        <div style={{'fontFamily': 'Articulat Light', fontSize: '14px', lineHeight: '22px', padding: width < 900 ? '0 3%' : '0', textAlign: width < 900 ? 'justify' : 'center'}}>
                        PFJ (Prop Firm Journal) utilizes data that is publicly available on the websites of various firms for the purpose of creating our leaderboard. 
                        This information is gathered through web scraping techniques. 
                        If you are a representative of one of the firms included in our leaderboard and would prefer not to be featured, or if you believe there is any inaccuracy in the data presented, please contact us at <a style={{color: 'black'}} href = "mailto:admin@propfirmjournal.com">admin@propfirmjournal.com</a>. 
                        We will promptly address your concerns and take necessary actions, which may include the removal or correction of the relevant information. 
                        If you represent a company that is not currently listed on the leaderboard and you would like to be included, please do not hesitate to email us.
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </>
    )
}