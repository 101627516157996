import { getHome } from "../requests/home.requests";
import { getHomeNews } from "../requests/news.requests";
import { CacheBase } from "./CacheBase";

class LayoutCache {
    homeCache = new CacheBase(() => getHome());
    news = new CacheBase(() => getHomeNews());

    getHome = () => this.homeCache.cache();
    getHomeNews = () => this.news.cache();
}

export const LayoutCacheI = new LayoutCache();