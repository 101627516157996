import { SubscriptionFrom } from '../../components/SubscriptionForm/SubscriptionFrom';
import usePageInit from '../../hooks/usePageInit';
import './SoonPage.css';

export default function SoonPage() {
    usePageInit('Coming Soon - Prop Firm Journal');
    return (
        <main className='soon-page fade-in' style={{ backgroundImage: 'url(assets/bull.png)' }}>
            <div className='soon-container'>
                <div className='soon'>
                    <div className='text text-label dark' style={{ width: '260px' }}>Earn as a professional trader</div>
                    <div className='text font-2 h-font'>Our Challenge Programs Are Coming Soon</div>
                    <div className='sub-form'>
                        <SubscriptionFrom />
                    </div>
                </div>
            </div>
        </main>
    )
}