import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants/constants';
import useNewsDate from '../../hooks/useNewsDate';
import './NewsCard.css';

export default function NewsCard({ img, date, title, link }) {
    const navigate = useNavigate();
    const dateStr = useNewsDate(date);
    return (
        <div className={'news-card ' + (title === undefined ? 'empty-card' : '')}>
            <div
                className={'news-card-image ' + (img ? '' : 'no-image')}
                style={{ backgroundImage: img ? `url("${BASE_URL}image?url=${img}")` : '' }}></div>
            <div className='news-card-text-panel'>
                <div className='news-card-date font-1'>
                    <div>{dateStr[0]}</div>
                    <div>{dateStr[1]}</div>
                </div>
                <div className='font-2'>{title}</div>
                {title === undefined || <button className='read-more-btn font-1' onClick={() => navigate(link)}>Read More</button>}
            </div>
            <div>
            </div>
        </div>
    )
}
